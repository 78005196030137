import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import Web3 from 'web3';
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import config from "lib/config";
import $ from "jquery"
import { v1_TransferToken, v1_TransferTokenBulk } from "actions/v1/token";
import { useTransferReceipt, useTransferReceiptTrade, usePurchaseTicketsTransfer, usePurchaseTicketsTransferBulk, useTranferMintReceipt } from "hooks/useReceipts";
import { UpdateNFTOwnersBulk, imageImger, imageImgerBulkTransfer, UpdateNFTOwnersBulkOwner } from 'actions/controller/tokenControl';
import { useNFTBalCheck, useNFTBalCheckTrade, useBulBalnceCheck } from "hooks/useMethods";
import { v1_ConNFTBalCheck, LMSignCall, LMBalanceOf, ContactMint } from "actions/v1/token";
import { network } from "../../views/network"
import { connectWallet, getServiceFees } from '../../hooks/useWallet';
import { Account_Connect } from "actions/redux/action";
import randomInteger from 'random-int';
import Web3Utils from 'web3-utils'
// import chargesheets from "../assets/images/SampleExcelSheet (13).xlsx";
import chargesheets from "../../assets/images/SampleExcelSheet (13).xlsx"
// import chargesheets from '../assets/images/lazymintingnew/Untitled_spreadsheet.xlsx';

export const TransferNFT = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [BurnState, setBurnState] = useState(false);
  const [BurnState1, setBurnState1] = useState(false);
  const [DisAbleBtn1, setDisAbleBtn1] = useState(false);
  const [NoofToken, setNoofToken] = useState(0)
  const [NFTImage, setNFTImage] = useState("");
  const [NFTDet, setNFTDet] = useState({});
  const [NFT, setNFT] = useState({ CurrentOwner: {} });
  const [WallAddress, setWallAddress] = useState("")
  const [SignTxt, SetSignTxt] = useState("Start");
  const [RandomName, setRandomName] = useState('')
  const [NonceHash, setNonceHash] = useState(0);
  const [SignatureHash, setSignatureHash] = useState('');
  const [Unlockable, setUnlockable] = useState(false);
  const [NFTFile, setNFTFile] = useState(null);
  const [NFTFilename, setNFTFilename] = useState(null);
  const [TokenIdss, setTokenIdss] = useState(null)
  const history = useHistory()
  useImperativeHandle(ref, () => ({
    async TransferNFTClick(ClickedNFT, NFTimage, NFT) {
      var chainid = NFT.SelectedNetwork
      const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
      var networkConfiguration = {}
      if (Number(chainid) === network.MATIC.Chainid) {
        networkConfiguration = network.MATIC
      }
      else if (Number(chainid) === network.ETH.Chainid) {
        networkConfiguration = network.ETH
      }
      else if (Number(chainid) === network.CAM.Chainid) {
        networkConfiguration = network.CAM
      }
      var accountDetailsss = ''
      Serfee(chainid);
      Wallet_Details.networkConfiguration = networkConfiguration
      if (Number(chainId) == Number(NFT.SelectedNetwork)) {
        accountDetailsss = true
      }
      else {
        var accountDetailsss = await switchNetwork(chainid, networkConfiguration)
      }
      setNFTImage(NFTimage);
      setNFTDet(NFT);
      if (Number(chainid) === Number(chainId)) {
        if (Wallet_Details.UserAccountAddr) {
          window.$('#Transfer_modal').modal('show');
          setNFT(ClickedNFT);
        }
      }
    },
  }));

  async function switchNetwork(configdata) {
    var type = ""
    var networkConfiguration = {}
    const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
    if (localStorage.walletConnectType !== "Torus") {
      if (Number(configdata) !== Number(chainId)) {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
        if (configdata) {
          if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {
            type = "MetaMask"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
            type = "WalletConnect"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Torus' && localStorage.walletConnectType != null) {
            type = "Torus"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'TorusWallet' && localStorage.walletConnectType != null) {
            type = "TorusWallet"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'LMWallet' && localStorage.walletConnectType != null) {
            console.log("adasd comming on torus");
            type = "LMWallet"
            localStorage.setItem("ChainId", NFTDet.SelectedNetwork)
          }
          var accountDetails = await connectWallet(type, Number(configdata), localStorage.walletConnectType == "LMWallet" ? "register" : "Create", Wallet_Details, "Tor")
          const chainId = await accountDetails?.web3?.eth?.getChainId();
          if (Number(configdata) === network.MATIC.Chainid) {
            networkConfiguration = network.MATIC
          }
          else if (Number(configdata) === network.ETH.Chainid) {
            networkConfiguration = network.ETH
          }
          else if (Number(configdata) === network.CAM.Chainid) {
            networkConfiguration = network.CAM
          }
          if (accountDetails != '') {
            dispatch({
              type: Account_Connect,
              Account_Detail: {
                UserAccountAddr: accountDetails.accountAddress,
                UserAccountBal: accountDetails.coinBalance,
                WalletConnected: "true",
                Wen_Bln: accountDetails.tokenBalance,
                Accounts: accountDetails.accountAddress,
                Web3Pro: accountDetails?.web3,
                providerss: accountDetails?.web3?._provider,
                networkConfiguration: networkConfiguration
              }
            })
            Serfee(networkConfiguration.Chainid);
          }
          if (networkConfiguration.currencySymbol) {
            toast.update(id, {
              render: "Network switched Succesfully",
              type: "success",
              isLoading: false,
              autoClose: 2500,
              closeButton: true,
              theme: "dark"
            });
          }
        }
        return accountDetails
      } else {
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            UserAccountAddr: Wallet_Details?.UserAccountAddr,
            UserAccountBal: Wallet_Details?.UserAccountBal,
            WalletConnected: "true",
            Wen_Bln: Wallet_Details?.Wen_Bln,
            Accounts: Wallet_Details?.Accounts,
            Web3Pro: Wallet_Details?.Web3Pro,
            providerss: Wallet_Details?.providerss,
            networkConfiguration: networkConfiguration
          }
        })
        Serfee(networkConfiguration.Chainid);
        return Wallet_Details.Web3Pro
      }
    } else {
      try {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
        var accountDetails = await connectWallet(localStorage.walletConnectType, Number(configdata), "Create", Wallet_Details, "Torus")
        const chainId = await accountDetails?.web3?.eth?.getChainId();
        if (Number(configdata) === network.MATIC.Chainid) {
          networkConfiguration = network.MATIC
        }
        else if (Number(configdata) === network.ETH.Chainid) {
          networkConfiguration = network.ETH
        }
        else if (Number(configdata) === network.CAM.Chainid) {
          networkConfiguration = network.CAM
        }
        if (accountDetails != '') {
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              UserAccountAddr: accountDetails?.accountAddress,
              UserAccountBal: accountDetails?.coinBalance,
              WalletConnected: "true",
              Wen_Bln: accountDetails?.tokenBalance,
              Accounts: accountDetails?.accountAddress,
              Web3Pro: accountDetails?.web3,
              providerss: accountDetails?.web3?._provider,
              networkConfiguration: networkConfiguration
            }
          })
        }
        var functiobn = await NefunctionTriger(Wallet_Details)
        if (networkConfiguration.currencySymbol) {
          toast.update(id, {
            render: "Network switched Succesfully",
            type: "success",
            isLoading: false,
            autoClose: 2500,
            closeButton: true,
            theme: "dark"
          });
        }
      } catch (e) {
        console.log("Switch Network Catch Console", e);
      }
    }
  }
  const NefunctionTriger = async (Wallet_Details1) => {
    const chainIdSwitched = await Wallet_Details1?.Web3Pro?.eth?.getChainId();
    var balance = await Wallet_Details1?.Web3Pro?.eth.getBalance(Wallet_Details1.UserAccountAddr);
    return chainIdSwitched
  }

  const Serfee = async (Address) => {
    var Fee = await getServiceFees(Address)
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        Service_Fee: Fee?.Serfee,
        Service_Fee_Fraction:Fee?.SerfeeFraction
      }
    })
  }


  // newly added to check if the token is minted before burning
  const mintHashcheck = async () => {
    if (NFTDet.hashValue == "not yet minted") {
      $('#Transfer_closs').trigger("click");
      return toast.error("cannot Transfer before minting the token")
    }
    else
      BalCheck();
  }

  const BalCheck = async () => {
    var Arg = {
      NFTConAddress: NFT.contractAddress,
      NFTId: NFT.tokenID,
      NFTOwner: NFT.tokenOwner,
      Status: NFT.LazyStatus,
      BulK: NFTDet.Bulkmint
    }
    var Type = NFT.type
    if (NFTDet.Bulkmint !== "Bulk") {
      if (localStorage.walletConnectType !== "LMWallet") {
        var ContractNFTBal = await NFTHookFun(Arg, Type, Wallet_Details)
      } else {
        var key = null
        var Argument = Type == 721 ? [Arg.NFTId] : [Arg.NFTOwner, Arg.NFTId]
        var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, Type == 721 ? "Single" : "Multiple", Arg.NFTConAddress, Type == 721 ? "ownerOf" : "balanceOf", Argument, key, Arg.NFTOwner)
      }
    } else {
      if (localStorage.walletConnectType !== "LMWallet") {
        var ContractNFTBal = await NFTHookFun1(Arg, Type, Wallet_Details)
      } else {
        var key = null
        var Argument = Type == 721 ? [Arg.NFTId] : [Arg.NFTOwner, Arg.NFTId]
        var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, "Bulk", Arg.NFTConAddress, Type == 721 ? "ownerOf" : "balanceOf", Argument, key, Arg.NFTOwner)
      }
    }
    if (Number(ContractNFTBal) !== NFT.balance) {
      Arg.balance = Number(ContractNFTBal)
      var Updated = await v1_ConNFTBalCheck(Arg)
      if (Updated.data && Updated.data.Success) {
        $('#Transfer_closs').trigger("click");
        toast.error("Owners NFT Balance Changed. Try Later", { autoClose: 4000, closeButton: true })
        setTimeout(() => {
          history.push('/explore/All')
        }, 1500);
      }
      else {
        $('#Transfer_closs').trigger("click");
        toast.error("Error Occured. Try Later", { autoClose: 3000, closeButton: true })
        setTimeout(() => {
          history.push('/explore/All')
        }, 1500);
      }
    }
    else {
      if (NFT.LazyStatus !== "pending") {
        var TransferAddress = WallAddress.toLowerCase();
        var web3 = new Web3()
        var validation = web3.utils.isAddress(TransferAddress)
        if (validation == true) {
          if (NFTDet.Bulkmint !== "Bulk") {
            TransferCall()
          } else {
            ImageMergeProcess()
          }
        } else {
          toast.error("Please Enter Valid User Address(Metamask Address)")
        }

      } else {
        var TransferAddress = WallAddress.toLowerCase();
        var web3 = new Web3()
        var validation = web3.utils.isAddress(TransferAddress)
        if (validation == true) {
          if (NFTDet.Bulkmint !== "Bulk") {
            TransferCall()
          } else {
            ImageMergeProcess()
          }
        } else {
          toast.error("Please Enter Valid User Address(Metamask Address)")
        }
      }
    }
  }

  const NFTHookFun = async (Arg, Type, Wallet_Details) => {
    var ContractNFTBal = await useNFTBalCheck(Arg, Type, Wallet_Details)
    return ContractNFTBal
  }

  const NFTHookFun1 = async (Arg, Type, Wallet_Details) => {
    var ContractNFTBal = await useBulBalnceCheck(Arg, Type, Wallet_Details)
    return ContractNFTBal
  }

  const TransferCall = async (Biddet) => {
    setBurnState(true)

    const id = toast.loading("Tranfer Token On Progress", { closeButton: true });
    var Arg = {
      NFTOwner: NFT.tokenOwner,
      NFTId: NFT.tokenID,
      NoofNFTs: NoofToken,
      ToAddress: WallAddress.toLowerCase(),
      LazyStatus: NFTDet.LazyStatus
    };

    var Type = NFT.type;
    var Provider = Wallet_Details;
    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHookTranfer(Arg, Type, Provider)
    } else {
      var Key = null
      var Argument = Type == 721 ? [Arg.NFTOwner, Arg.ToAddress, Arg.NFTId] : [Arg.NFTOwner, Arg.ToAddress, Arg.NFTId, Arg.NoofNFTs, []]
      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, NFTDet, NFT, Type == 721 ? "Single" : "Multiple", NFT.contractAddress, NFT.contractAddress, Argument, "safeTransferFrom", Key, false, 0)
    }
    if (Receipt) {
      var Burn = { Type: NFT.type, Creater: NFT.tokenOwner, Owner: WallAddress.toLowerCase(), TokeninitialCreator: NFT.tokenCreator, id: NFT.tokenID, ConAddr: NFT.contractAddress, NoOfToken: NoofToken, Status: 'true', Hash: Receipt.transactionHash, Network: NFTDet.SelectedNetwork, QR_tokenID: NFT.QR_tokenID }
      var Resp = await v1_TransferToken(Burn)
      if (Resp.data) {
        toast.update(id, { render: "Token Transfer Successfully", type: "success", isLoading: false, autoClose: 2500, });
        $('#Transfer_closs').trigger("click");
        setTimeout(() => {
          history.push('/my-items')
        }, 1000);
      } else {
        setBurnState(false)
        toast.update(id, {
          render: "Token Tranfer Failed",
          type: "error",
          isLoading: false,
          autoClose: 2500,
        });
      }
    } else {
      toast.update(id, { render: "Try it Again Later.Please Check Your Funds", type: "error", isLoading: false, autoClose: 2500, });
    }
  };

  const ImageMergeProcess = async () => {
    setBurnState(true)

    const id = toast.loading("Image Generating", { closeButton: true, theme: "dark" });
    var timestamp = Date.now()
    var updateData = {
      hashValue: NFTDet.hashValue,
      tokenID: timestamp,
      status: NFT?.status,
      BulkMint: NFTDet.BulkMint == 'false' ? 'true' : 'true',
      LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",
    }
    NFT.ScannerTokenID = timestamp
    NFTDet.ScannerTokenID = timestamp
    var NewOwnerDetail = { Status: NFT?.status, Hash: NFT.hashValue, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFTDet, Action: "Purchase", NewOwner: WallAddress.toLowerCase(), OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: NFTDet.tokenName, Collection: NFTDet.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: NFTDet.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, Bulkmint: NFTDet.Bulkmint, NFTTokens: NFTDet, ScannerTokenID: timestamp }
    var Resp11 = await imageImger(NewOwnerDetail)
    if (Resp11.Success == "true" && Resp11.data.NFTDetails.type) {
      toast.update(id, {
        render: "Image Generated Succesfully",
        type: "success",
        isLoading: false,
        autoClose: 2500,
        closeButton: true,
        theme: "dark"
      });
      BulkMintLazyCall(Resp11.data.NFTDetails, timestamp)
    } else {
      setBurnState(false)
      toast.update(id, {
        render: "Error in Image Generating. Please tryAgain",
        type: "error",
        isLoading: false,
        autoClose: 2500,
        closeButton: true,
        theme: "dark"
      });
    }
  }

  const BulkMintLazyCall = async (Datas, timestamp) => {
    console.log("abahsdksajndas");
    setBurnState(true)
    var chainid = NFTDet?.SelectedNetwork
    var networkConfiguration = {}
    if (Number(chainid) === network.MATIC.Chainid) {
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      networkConfiguration = network.CAM
    }
    NFT.ScannerTokenID = timestamp
    NFTDet.ScannerTokenID = timestamp
    var Royaltity = Web3.utils.toWei(String(NFTDet?.tokenRoyality))
    var tokendbPrice = NFTDet?.tokenPrice == 0 ? 0 : Web3.utils.toWei(String(NFTDet?.tokenPrice))
    var nounceArg = { RandomName: RandomName, NonceHash: NonceHash, SignatureHash: SignatureHash }
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: 0, PriceWithSerfee: 0, NoofNFTs: NoofToken, isToken: false, TotalToken: NFT.quantity, Royaltity: Royaltity, tokendbPrice: tokendbPrice, nounceArg: nounceArg, Datas: Datas, TransferAddress: WallAddress.toLowerCase() }
    console.log("qweqweqweqweqw", Arg, nounceArg);
    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHook(Arg, NFT.type, Wallet_Details, NFTDet, NFT)
    } else {
      var nounce1 = NFTDet.Bulkmint == "Bulk" ? Arg?.nounceArg?.NonceHash : NFTDet.NonceHash;
      var RandomName1 = NFTDet.Bulkmint == "Bulk" ? Arg?.nounceArg?.RandomName : NFTDet.RandomName;
      var SignatureHash1 = NFTDet.Bulkmint == "Bulk" ? Arg?.nounceArg?.SignatureHash : NFTDet.SignatureHash
      var NewTokenIds1 = NFTDet.tokenID == NFTDet.QR_tokenID ? Number(0) : Number(NFTDet.tokenID)
      var Key = null
      console.log("ashdasjhdjkaslkdsadasd", [[Arg.Datas.ipfsmeta, "Status", "lazy", RandomName1, "transfer"], [Arg.NFTOwner, Arg.TransferAddress, Arg.NFTOwner], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce1, "0", NewTokenIds1], "Coin", SignatureHash1]);
      var Argument = [[Arg.Datas.ipfsmeta, "Status", "lazy", RandomName1, "transfer",""], [Arg.NFTOwner, Arg.TransferAddress, Arg.NFTOwner], [Arg.NoofNFTs, "1155", Arg.Royaltity, 0, Arg.TotalToken, nounce1, "0", NewTokenIds1,""], "Coin", SignatureHash1]
      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, NFTDet, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "ticketMinting", Key, true, 0)
    }
    if (Receipt.status) {
      if (NFTDet.SelectedNetwork == config.MATIC_ID) {
        var tokenid = NFTDet.type == 721 ? Receipt?.logs[4]?.topics[3] : Receipt?.logs[3]?.topics[3];
      } else if (NFTDet.SelectedNetwork == config.ETH_ID) {
        var tokenid = NFTDet.type == 721 ? Receipt?.logs[3]?.topics[3] : Receipt?.logs[3]?.topics[3];
      }else if (NFTDet.SelectedNetwork == config.CAM_ID) {
        var tokenid = NFTDet.type == 721 ? Receipt?.logs[2]?.topics[2] : Receipt?.logs[2]?.topics[2];
      }

      const tokenID = Web3Utils.hexToNumber(Number(tokenid));
      var updateData = {
        hashValue: Receipt.transactionHash,
        tokenID: String(tokenID),
        status: Receipt?.status,
        BulkMint: NFTDet.BulkMint == 'false' ? 'true' : 'true',
        LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",

      }
      var NewOwnerDetail1 = { Status: Receipt?.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: WallAddress.toLowerCase(), OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: NFTDet.tokenName, Collection: NFTDet.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: NFTDet.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, Bulkmint: NFTDet.Bulkmint, NFTTokens: Datas, ScannerTokenID: Datas.ScannerTokenID }
      var Resp = await UpdateNFTOwnersBulk(NewOwnerDetail1)

      if (Resp) {
        $('#Transfer_closs').trigger("click");
        toast.success("Transferred Sucessfully")
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
        }, 1000);
      }
      // }
    } else {
      toast.error("Try it Again Later.And Please Check Your Funds")
    }
    setBurnState(false)



  }

  async function signcall() {
    try {
      var generator = require('generate-password');
      var randomNum = randomInteger(10000000, 100000000);
      var password = generator.generate({
        length: 10,
        numbers: true
      });
      var web3RpcPro = new Web3(Wallet_Details.networkConfiguration.RPC_URL)
      var chain = NFTDet.SelectedNetwork
      const to = Wallet_Details.UserAccountAddr
      const _amount = 0;
      console.log("asdsadasdasdas", _amount);
      const _nonce = Date.now();
      // setNonceHash(_nonce);
      setRandomName(password)
      var tot = _nonce + Number(randomNum);
      setNonceHash(tot);
      if (localStorage.walletConnectType !== "LMWallet") {
        if (Wallet_Details.Web3Pro) {
          var web3Rpc = new Web3(Wallet_Details.providerss)
          if (web3Rpc) {
            SetSignTxt('On progress')

            var web3RpcPro = new Web3(Wallet_Details.providerss);
            const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
            const signhash = await Wallet_Details.Web3Pro.eth.personal.sign(result, to);
            if (signhash) {
              SetSignTxt('done')
              setSignatureHash(signhash);
              toast.success("Verified Successfully")
            } else {
              toast.error("Signature Failed")
            }
          }
        }
      }
      else {
        var Arg = {
          to: to,
          _amount: _amount,
          password: password,
          tot: tot,
          Type: String(NFTDet.type),
          Chain: String(chain),
          Mail: localStorage.loggedinaddress,
          LoginAddress: localStorage.userAddress,
          Key: null
        }
        var receipt = await LMSignCall(Arg)
        if (receipt.data.Status) {
          SetSignTxt('done')
          setSignatureHash(receipt.data.signedTx);
          toast.success("Verified Successfully")
        } else {
          toast.error("Signature Failed")
        }
      }
    } catch (e) {
      console.log("Catch Console", e);
      SetSignTxt('Try again')
      toast.error("Signature Failed")
    }
  }

  async function signcall1() {
    try {
      if (NFTFile) {

        var generator = require('generate-password');
        var randomNum = randomInteger(10000000, 100000000);
        var password = generator.generate({
          length: 10,
          numbers: true
        });
        var web3RpcPro = new Web3(Wallet_Details.networkConfiguration.RPC_URL)
        var chain = NFTDet.SelectedNetwork
        const to = Wallet_Details.UserAccountAddr
        const _amount = 0;
        console.log("asdsadasdasdas", _amount);
        const _nonce = Date.now();
        // setNonceHash(_nonce);
        setRandomName(password)
        var tot = _nonce + Number(randomNum);
        setNonceHash(tot);
        if (localStorage.walletConnectType !== "LMWallet") {
          if (Wallet_Details.Web3Pro) {
            var web3Rpc = new Web3(Wallet_Details.providerss)
            if (web3Rpc) {
              SetSignTxt('On progress')

              var web3RpcPro = new Web3(Wallet_Details.providerss);
              const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
              const signhash = await Wallet_Details.Web3Pro.eth.personal.sign(result, to);
              if (signhash) {
                SetSignTxt('done')
                setSignatureHash(signhash);
                toast.success("Verified Successfully")
              } else {
                toast.error("Signature Failed")
              }
            }
          }
        }
        else {
          var Arg = {
            to: to,
            _amount: _amount,
            password: password,
            tot: tot,
            Type: String(NFTDet.type),
            Chain: String(chain),
            Mail: localStorage.loggedinaddress,
            LoginAddress: localStorage.userAddress,
            Key: null
          }
          var receipt = await LMSignCall(Arg)
          if (receipt.data.Status) {
            SetSignTxt('done')
            setSignatureHash(receipt.data.signedTx);
            toast.success("Verified Successfully")
          } else {
            toast.error("Signature Failed")
          }
        }
      } else {
        toast.error("Please Upload Excel Sheet")
      }
    } catch (e) {
      console.log("Catch Console", e);
      SetSignTxt('Try again')
      toast.error("Signature Failed")
    }
  }



  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  const ContactHook = async (Arg, Type, Wallet_Details, NFTDet, NFT) => {
    var Receipt = await usePurchaseTicketsTransfer(Arg, Type, Wallet_Details, NFTDet, NFT);
    return Receipt
  }

  const ContactHookMint = async (Arg, Type, Wallet_Details, NFTDet, NFT) => {
    var Receipt = await useTranferMintReceipt(Arg, Type, Wallet_Details, NFTDet, NFT);
    return Receipt
  }

  const ContactHookTranfer = async (Arg, Type, Provider) => {
    var Receipt = await useTransferReceipt(Arg, Type, Provider)
    return Receipt
  }

  const LMWalletCall = async (Arg, type, Wallet_Details, tokDetails, NFT, NameUse, IntractContact, Contract, data, method, Key, Status, fee) => {
    var receipt = null
    var Arg = {
      Method: method,
      Data: data,
      Mail: localStorage.loggedinaddress,
      LoginAddress: localStorage.userAddress,
      ContactAddress: Contract,
      Type: String(type),
      Chain: String(NFTDet.SelectedNetwork),
      IntractContract: IntractContact,
      ContactType: NameUse,
      value: fee,
      Key: Key,
      Status: Status
    }
    console.log("asdasdasdasdasda", Arg);
    var Receiptfun = await ContactMint(Arg)
    return Receiptfun.data.receipt
  }

  const LMBalanceCheck = async (Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key, tokenOwner) => {
    var Arg = {
      Method: method,
      Data: data,
      Mail: localStorage.loggedinaddress,
      LoginAddress: localStorage.userAddress,
      ContactAddress: Contract,
      Type: String(Type),
      Chain: String(NFTDet.SelectedNetwork),
      IntractContract: IntractContact,
      ContactType: NameUse,
      value: 0,
      Key: Key,
      tokenOwner: tokenOwner
    }
    var Balance = await LMBalanceOf(Arg)
    return Balance.data.receipt
  }

  const BulkAdminTransfer = async () => {
    console.log("BulkAdminTransfer", NFT, NFTDet, NFTFile);
    setBurnState(true)
    if (NFTFile) {
      const id = toast.loading("Image Generating", { closeButton: true, theme: "dark" });
      var timestamp = Date.now()
      var updateData = {
        hashValue: NFTDet.hashValue,
        tokenID: timestamp,
        status: NFT?.status,
        BulkMint: NFTDet.BulkMint == 'false' ? 'true' : 'true',
        LazyStatus: NFT.balance == NoofToken ? "completed" : "pending",
      }
      NFT.ScannerTokenID = timestamp
      NFTDet.ScannerTokenID = timestamp
      const ExcelFile = []
      ExcelFile.push(NFTFile)
      console.log("wqeqweqweqweqw", ExcelFile);
      var NewOwnerDetail = { Status: NFT?.status, Hash: NFT.hashValue, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFTDet, Action: "Purchase", NewOwner: WallAddress.toLowerCase(), OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: NFTDet.tokenName, Collection: NFTDet.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: NFTDet.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, Bulkmint: NFTDet.Bulkmint, NFTTokens: NFTDet, ScannerTokenID: timestamp, ExcelFile: NFTFile, Balance: NFT.balance }
      var Resp11 = await imageImgerBulkTransfer(NewOwnerDetail)
      if (Resp11.Message == "Success" && Resp11.data[0].NFTDetails.type) {
        toast.update(id, {
          render: "Image Generated Succesfully",
          type: "success",
          isLoading: false,
          autoClose: 2500,
          closeButton: true,
          theme: "dark"
        });
        BulkMintLazyCallTransfer(Resp11.data, timestamp)
      } else {
        setBurnState(false)
        toast.update(id, {
          render: Resp11.data,
          type: "error",
          isLoading: false,
          autoClose: 2500,
          closeButton: true,
          theme: "dark"
        });
      }
    } else {
      toast.error("Please Upload Excel Sheet")
    }
  }

  const BulkMintLazyCallTransfer = async (Datas, timestamp) => {
    setBurnState(true)
    var chainid = NFTDet?.SelectedNetwork
    var networkConfiguration = {}
    if (Number(chainid) === network.MATIC.Chainid) {
      networkConfiguration = network.MATIC
    }
    else if (Number(chainid) === network.ETH.Chainid) {
      networkConfiguration = network.ETH
    }
    else if (Number(chainid) === network.CAM.Chainid) {
      networkConfiguration = network.CAM
    }
    // NFT.ScannerTokenID = timestamp
    // NFTDet.ScannerTokenID = timestamp
    var Royaltity = Web3.utils.toWei(String(NFTDet?.tokenRoyality))
    console.log("adadwasdasdasdas", Datas);
    var Adddreee = []
    var TransferAddress = []
    var Quan = []
    var IPDSDAta = await Datas.map((ipfs) => {
      console.log("iqiooqoaoioi", ipfs);
      Adddreee.push(ipfs.address)
      TransferAddress.push(ipfs.ipfsmeta)
      Quan.push(ipfs.Quantity)
    })
    console.log("asdasdasdasdasd", Adddreee, TransferAddress, Quan);
    var CreatorSplitAmt = Quan.reduce((partialSum, a) => Number(partialSum) + Number(a), 0)
    console.log("CreatorSplitAmt", CreatorSplitAmt);

    var tokendbPrice = NFTDet?.tokenPrice == 0 ? 0 : Web3.utils.toWei(String(NFTDet?.tokenPrice))
    var nounceArg = { RandomName: RandomName, NonceHash: NonceHash, SignatureHash: SignatureHash }
    Wallet_Details.networkConfiguration = networkConfiguration
    var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: 0, PriceWithSerfee: 0, NoofNFTs: NoofToken, isToken: false, TotalToken: NFT.quantity, Royaltity: Royaltity, tokendbPrice: tokendbPrice, nounceArg: nounceArg, Datas: Datas, TransferAddress: Adddreee, IPFS: TransferAddress, Quani: Quan, Creatorr: NFT.tokenCreator, TotalSend: CreatorSplitAmt, TokenIdss: TokenIdss == null ? NFTDet.tokenID : TokenIdss }
    console.log("qweqweqweqweqw", Arg, nounceArg);
    if (localStorage.walletConnectType !== "LMWallet") {
      var Receipt = await ContactHookTransferBulk(Arg, NFTDet.type, Wallet_Details, NFTDet, NFT)
    } else {
      var Key = null
      var Argument = [Arg.IPFS, Arg.TransferAddress, [Arg.TotalSend, Arg.Royaltity, TokenIdss == null ? NFTDet.tokenID : TokenIdss], Arg.Quani, Arg.Creatorr]
      var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, NFTDet, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "bulkTicketTransfer", Key, true, 0)
    }
    console.log("yerwuiryiwuyrweqw", Receipt);
    if (Receipt.status) {
      var tokenIDept = []
      for (let i = 1; i < Receipt.logs.length; i += 2) {
        // amount =  web3.utils.toBN(data.logs[0].data).toString()
        var route = String(Receipt.logs[i].data)
        var sliceee = route.slice(2)
        console.log("qwewqeqweqweqweqw", sliceee);
        var lengthuh = sliceee.length / 2

        var TokenID = Web3Utils.hexToNumber("0x" + sliceee.slice(0, lengthuh))

        console.log("qweqweqweqweqwe", TokenID);
        var tokendata = {
          tokenID: TokenID,
          Hassh: Receipt.transactionHash,
          OldID: TokenIdss == null ? NFTDet.tokenID : TokenIdss,
          TotallSennnd: Arg.TotalSend
        };
        // Datas[i] = { ...Datas[i], ...tokendata }
        tokenIDept.push(tokendata);
      }
      // if (NFTDet.SelectedNetwork == config.MATIC_ID) {
      //   var tokenid = NFTDet.type == 721 ? Receipt?.logs[4]?.topics[3] : Receipt?.logs[3]?.topics[3];
      // } else {
      //   var tokenid = NFTDet.type == 721 ? Receipt?.logs[3]?.topics[3] : Receipt?.logs[3]?.topics[3];
      // }
      var dbdatafinal = Datas.map((obj, index) => Object.assign({}, obj, tokenIDept[index]))
      console.log("wadwqeqwewqeqwewqe", dbdatafinal);
      var Resp = await v1_TransferTokenBulk(dbdatafinal)
      console.log("qweqwsadsaRespResp", Resp);
      if (Resp) {
        $('#Transfer_closs').trigger("click");
        toast.success("Transferred Sucessfully")
        setTimeout(() => {
          history.push(`/my-items/${Wallet_Details.UserAccountAddr}`)
        }, 1000);
      }
      // }
    } else {
      toast.error("Try it Again Later.Please Check Your Funds")
    }
    setBurnState(false)



  }

  const BulkAdminMint = async (Datas, timestamp) => {
    try {
      setBurnState1(true)

      var chainid = NFTDet?.SelectedNetwork
      var networkConfiguration = {}
      if (Number(chainid) === network.MATIC.Chainid) {
        networkConfiguration = network.MATIC
      }
      else if (Number(chainid) === network.ETH.Chainid) {
        networkConfiguration = network.ETH
      }
      else if (Number(chainid) === network.CAM.Chainid) {
        networkConfiguration = network.CAM
      }
      NFT.ScannerTokenID = timestamp
      NFTDet.ScannerTokenID = timestamp
      var Royaltity = Web3.utils.toWei(String(NFTDet?.tokenRoyality))
      var tokendbPrice = NFTDet?.tokenPrice == 0 ? 0 : Web3.utils.toWei(String(NFTDet?.tokenPrice))
      var nounceArg = { RandomName: RandomName, NonceHash: NonceHash, SignatureHash: SignatureHash }
      Wallet_Details.networkConfiguration = networkConfiguration
      var Arg = { NFTOwner: NFT.tokenOwner, NFTId: NFT.tokenID, PriceWithoutSerfee: 0, PriceWithSerfee: 0, NoofNFTs: NoofToken, isToken: false, TotalToken: NFT.quantity, Royaltity: Royaltity, tokendbPrice: tokendbPrice, nounceArg: nounceArg, Datas: NFTDet, TransferAddress: WallAddress.toLowerCase() }
      console.log("qweqweqweqweqw", Arg, nounceArg);
      if (localStorage.walletConnectType !== "LMWallet") {
        var Receipt = await ContactHookMint(Arg, NFTDet.type, Wallet_Details, NFTDet, NFT)
      } else {
        var nounce1 = NFTDet.Bulkmint == "Bulk" ? Arg?.nounceArg?.NonceHash : NFTDet.NonceHash;
        var RandomName1 = NFTDet.Bulkmint == "Bulk" ? Arg?.nounceArg?.RandomName : NFTDet.RandomName;
        var SignatureHash1 = NFTDet.Bulkmint == "Bulk" ? Arg?.nounceArg?.SignatureHash : NFTDet.SignatureHash
        var NewTokenIds1 = NFTDet.tokenID == NFTDet.QR_tokenID ? Number(0) : Number(NFTDet.tokenID)
        var Key = null
        //wooww
        var Argument = [[Arg.Datas.ipfsmeta, "Status", "lazy", RandomName1, "claim",""], [Wallet_Details.UserAccountAddr, Wallet_Details.UserAccountAddr, Wallet_Details.UserAccountAddr], [Arg.TotalToken, "1155", Arg.Royaltity, 0, Arg.TotalToken, nounce1, "0", NewTokenIds1,"1000000000000000000"], "Coin", SignatureHash1]
        var Receipt = await LMWalletCall(Arg, NFT.type, Wallet_Details, NFTDet, NFT, "Trade", Wallet_Details.networkConfiguration.TradeContract, Wallet_Details.networkConfiguration.TradeContract, Argument, "ticketMinting", Key, false, 0)
      }
      if (Receipt.status) {
        if (NFTDet.SelectedNetwork == config.MATIC_ID) {
          var tokenid = NFTDet.type == 721 ? Receipt?.logs[4]?.topics[3] : Receipt?.logs[3]?.topics[3];
        }else if (NFTDet.SelectedNetwork == config.ETH_ID) {
          var tokenid = NFTDet.type == 721 ? Receipt?.logs[3]?.topics[3] : Receipt?.logs[3]?.topics[3];
        } else if (NFTDet.SelectedNetwork == config.CAM_ID) {
          var tokenid = NFTDet.type == 721 ? Receipt?.logs[2]?.topics[2] : Receipt?.logs[2]?.topics[2];
        }

        const tokenID = Web3Utils.hexToNumber(Number(tokenid));
        setTokenIdss(tokenID)
        var updateData = {
          hashValue: Receipt.transactionHash,
          tokenID: String(tokenID),
          status: Receipt?.status,
          BulkMint: NFTDet.BulkMint == 'false' ? 'true' : 'true',
          LazyStatus: "completed",
        }
        var NewOwnerDetail1 = { Status: Receipt?.status, Hash: Receipt.transactionHash, ConAddr: NFT.contractAddress, Owns: NoofToken, NFTDetail: NFT, Action: "Purchase", NewOwner: WallAddress.toLowerCase(), OldOwner: NFT.tokenOwner, coinname: NFT.coinname, tokenName: NFTDet.tokenName, Collection: NFTDet.Collection, updateData: updateData, LazyStatus: "completed", SelectedNetwork: NFTDet.SelectedNetwork, QR_tokenID: NFT.QR_tokenID, Bulkmint: NFTDet.Bulkmint, NFTTokens: NFTDet, ScannerTokenID: NFTDet.ScannerTokenID }
        var Resp = await UpdateNFTOwnersBulkOwner(NewOwnerDetail1)
        console.log("Respppppppp", Resp);
        if (Resp) {
          // $('#Transfer_closs').trigger("click");
          toast.success("Minted Sucessfully")
          setDisAbleBtn1(true)
        }
        // }
      } else {
        toast.error("Try it Again Later.Please Check Your Funds")
      }
      setBurnState1(false)

    } catch (e) {
      console.log("qweqweqeqsdfad", e);
    }

  }


  const ContactHookTransferBulk = async (Arg, Type, Wallet_Details, NFTDet, NFT) => {
    var Receipt = await usePurchaseTicketsTransferBulk(Arg, Type, Wallet_Details, NFTDet, NFT);
    return Receipt
  }

  const Handlefile = (e) => {
    if (e.target && e.target.files) {
      console.log("dnjcsdanjkda", e.target.files[0]);
      var file = e.target.files[0];
      var fileName = file.name;
      setNFTFilename(fileName);
      // Formdata.ImageName = fileName;
      setNFTFile(e.target.files[0]);
    }
  };

  return (
    <div
      className="modal fade primary_modal"
      id="Transfer_modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="cancel_order_modalCenteredLabel"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title text-danger" id="cancel_order_modalLabel">
              Transfer Token
            </h5>
            {!NFTDet ? (
              <>
                <TailSpin
                  wrapperClass="reactloader mt-0 mb-3"
                  color="#00BFFF"
                  height={100}
                  width={70}
                />
              </>
            ) : (
              <>
                <div>
                  <div className="change_price_img_div">
                    {NFTDet.image &&
                      NFTDet.image.split(".").pop() == "mp4" ? (
                      <video
                        id="my-video"
                        class="img-fluid"
                        autoPlay
                        playsInline
                        loop
                        muted
                        preload="auto"
                      >
                        <source src={NFTImage && NFTImage} type="video/mp4" />
                      </video>
                    ) : (
                      <img
                        src={
                          NFTImage
                            ? NFTImage
                            : require("../../assets/images/masonary_04.png")
                        }
                        alt="Collections"
                        className="img-fluid"
                      />
                    )}
                  </div>
                  <p className="text-gray font_we_600 font_14">
                    You are about to <span className="text-danger">Transfer</span> the NFT{" "}
                    <span className="text-danger">{NFTDet?.tokenName?.slice(0, 10)}</span>
                  </p>
                  <p className="text-gray font_we_600 font_14">
                    You only own <span className="text-danger">{NFT.balance}</span> Quantity
                  </p>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="Transfer_closs"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  {/* {config.AdminAddress.toLowerCase() == Wallet_Details.UserAccountAddr.toLowerCase() && */}
                  {NFTDet.Bulkmint == 'Bulk' &&
                    <Button className="gf fileindees pb-0"><p className="donwloadfiles">You can download your sample excel sheet format here</p>
                      <a href={chargesheets} download="SampleExcelSheet"><i class="fas fa-download"></i> </a></Button>}
                  {/* } */}
                  <div className="form-row modaltoggles">
                    <div className="form-group col-md-12">
                      {/* {config.AdminAddress.toLowerCase() == Wallet_Details.UserAccountAddr.toLowerCase() && */}
                      <div className="d-flex justify-content-between align-items-center grid_toggle mt-3">
                        {/* <div className="text-left">
                          <label className="primary_label mb-0" htmlFor="inputEmail4">
                            Bulk Transfer
                          </label>
                          <p className="form_note">
                            Upload Your Bulk Transfer Excel Sheet
                          </p>
                        </div>
                        <label className="switch toggle_custom mb-4">
                          <input
                            type="checkbox"
                            onChange={() =>
                              setUnlockable((prevState) => !prevState)
                            }
                          />
                          <span className="slider"></span>
                        </label> */}
                      </div>
                      {/* } */}
                      {NFTDet.Bulkmint == 'Bulk' && (
                        <div className="form-group col-md-12 px-0 mt-3">
                          <div className="d-flex justify-content-between align-items-start">
                            <div className="text-left">
                              {/* <p className="form_note">
                            Upload Your Bulk Transfer Excel Sheet
                          </p>  */}
                              <label className="primary_label" htmlFor="inputEmail4">
                                Upload file
                              </label>
                              <p className="form_note">
                                Only xlsx format
                              </p>

                            </div>
                            <div className="file_btn btn ">
                              {NFTFile == null ? "Upload" : "Uploaded"}
                              <input
                                className="inp_file"
                                type="file"
                                name="file"
                                autoComplete="off"
                                onChange={(e) => setNFTFile(e.target.files[0])}
                              />
                            </div>

                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="modal-body px-0 pt-0">
                    <form className="px-4 bid_form">
                      {NFTDet.Bulkmint !== 'Bulk' &&
                        <>
                          <label htmlFor="bid" className="text-center">
                            <input
                              type="number"
                              id="bid"
                              className="form-control text-center modal_inp_white"
                              placeholder="Enter Quantity to Transfer"
                              aria-label="bid"
                              aria-describedby="basic-addon2"
                              onChange={(e) => setNoofToken(e.target.value)}
                              onKeyDown={handleKeyDown}
                              onWheel={(e) => e.target.blur()}
                            />
                          </label>
                          {((NoofToken > NFT.balance) || (NoofToken < 1)) &&
                            <p className="text-gray font_we_600 font_14">
                              Please Enter <span className="text-danger"> Valid Quantity (Max : {NFT.balance})</span>
                            </p>
                          }

                          <label htmlFor="bid" className="text-center">
                            <input
                              type="text"
                              id="bid"
                              className="form-control text-center modal_inp_white"
                              placeholder="Enter Wallet Address"
                              aria-label="bid"
                              aria-describedby="basic-addon2"
                              onChange={(e) => setWallAddress(e.target.value)}
                            />
                          </label>
                          {/* {((NoofToken > NFT.balance) || (NoofToken < 1)) &&
                        <p className="text-gray font_we_600 font_14">
                          Please Enter <span className="text-danger"> Valid Quantity (Max : {NFT.balance})</span>
                        </p>
                      } */}
                        </>
                      }
                      {NFTDet.Bulkmint !== 'Bulk' ?
                        <>
                          {NFTDet.Bulkmint == 'Bulk' &&
                            <Button className="create_btn btn-block" disabled={SignTxt == "progress" || SignTxt == "done" ? true : false} onClick={() => { signcall() }}>


                              {SignTxt == "progress" && (
                                <i
                                  class="fa fa-spinner mr-3 spinner_icon"
                                  aria-hidden="true"
                                ></i>
                              )}{(SignTxt == "done") ? "done" : "sign"}
                            </Button>
                          }

                          <div className="text-center mt-3">
                            <Button
                              className="create_btn btn-block"
                              disabled={!NoofToken || (NoofToken < 1) || (NoofToken > NFT.balance) || BurnState}
                              onClick={() => {
                                // BalCheck();
                                mintHashcheck();    // added for lazyminter
                              }}
                            >
                              {BurnState && (
                                <i
                                  class="fa fa-spinner mr-3 spinner_icon"
                                  aria-hidden="true"
                                ></i>
                              )}
                              Transfer Token
                            </Button>
                            {/* <Button
                          className="btn_outline_red btn-block"
                          data-dismiss="modal"
                          aria-label="Close"
                          disabled={BurnState}
                        >
                          Cancel
                        </Button> */}
                          </div>
                        </>
                        :
                        <>
                          {NFTDet.Bulkmint == 'Bulk' &&
                            <Button className="create_btn btn-block" disabled={SignTxt == "progress" || SignTxt == "done" ? true : false} onClick={() => { signcall1() }}>


                              {SignTxt == "progress" && (
                                <i
                                  class="fa fa-spinner mr-3 spinner_icon"
                                  aria-hidden="true"
                                ></i>
                              )}{(SignTxt == "done") ? "done" : "sign"}
                            </Button>
                          }
                          {NFTDet.hashValue == "not yet minted" &&
                            <div className="text-center mt-3">
                              <Button
                                className="create_btn btn-block"
                                disabled={SignTxt == "Start" || BurnState}
                                onClick={() => {
                                  // BalCheck();
                                  BulkAdminMint();    // added for lazyminter
                                }}
                              >
                                {BurnState1 && (
                                  <i
                                    class="fa fa-spinner mr-3 spinner_icon"
                                    aria-hidden="true"
                                  ></i>
                                )}
                                Mint Token
                              </Button>
                            </div>}

                          <div className="text-center mt-3">
                            <Button
                              className="create_btn btn-block"
                              disabled={SignTxt == "Start" || BurnState1 == true || BurnState}
                              onClick={() => {
                                // BalCheck();
                                BulkAdminTransfer();    // added for lazyminter
                              }}
                            >
                              {BurnState && (
                                <i
                                  class="fa fa-spinner mr-3 spinner_icon"
                                  aria-hidden="true"
                                ></i>
                              )}
                              Transfer Token
                            </Button>
                          </div>
                        </>
                      }
                    </form>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
import web3 from "web3";
import React, { useEffect, useRef } from "react";
import { useWeb3 } from "./useWeb3";
import config from '../lib/config'
import { useContract, useNFTContract, useTokenContract, useContractBurn, useContractTranfer, useTradeTranfer, SoulBoundContract, useContractBulk, useContactClaim, useContractFraction, useTokenContractFraction,useDeployer,useContractTranferFraction,useContractBurnFraction ,useDeployer1} from "./useContract";


export const useMint = async (Arg, Type, Provider) => {
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type)
    var TokenPrice = (Number(Arg.TokenPrice) > 0) ? web3.utils.toWei(String(Number(Arg.TokenPrice).toFixed(4))) : '0'
    try {
        if (Type === 721) {
            await Contract
                .methods
                .mint(Arg.Ipfs, TokenPrice, Arg.quantity, Arg.royalties)
                .send({ from: Wallet_Details.UserAccountAddr })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        }
        else {
            await Contract
                .methods
                .mint(TokenPrice, Arg.royalties, Arg.quantity, Arg.Ipfs)
                .send({ from: Wallet_Details.UserAccountAddr })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        }
        return Hash
    }
    catch (error) {
        return false
    }
}

export const useTokenApprove = async (Arg, Type, Provider) => {
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useTokenContract(Type, Arg.Coinname, Provider)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    if (chain == config.MATIC_ID) {
        if (Type === 721) {
            var encoded = await Contract.methods.approve(Wallet_Details.networkConfiguration.TradeContract, Arg.PriceWithSerfee)
            //.encodeABI();
        } else {
            var encoded = await Contract.methods.approve(Wallet_Details.networkConfiguration.TradeContract, Arg.PriceWithSerfee)
            //.encodeABI();
        }
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Wallet_Details.networkConfiguration.tokenAddr.CAKE,
            // data: encoded,
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }

    try {
        if (Type === 721) {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .approve(Wallet_Details.networkConfiguration.TradeContract, Arg.PriceWithSerfee)
                    .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .approve(Wallet_Details.networkConfiguration.TradeContract, Arg.PriceWithSerfee)
                    .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        else {

            if (chain == config.MATIC_ID) {
                await
                    Contract
                        .methods
                        .approve(Wallet_Details.networkConfiguration.TradeContract, Arg.PriceWithSerfee)
                        .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })
            } else {
                await Contract
                    .methods
                    .approve(Wallet_Details.networkConfiguration.TradeContract, Arg.PriceWithSerfee)
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        return Hash
    }
    catch (error) {
        return false
    }
}

export const usePurchase = async (Arg, Type, Provider, isToken) => {
    try {
        console.log("Comming On UsePurchase");
        const Wallet_Details = Provider
        var Hash = null
        var Contract = await useContract(Type, Provider)
        let chain = await Provider?.Web3Pro?.eth?.getChainId();
        if (chain == config.MATIC_ID) {
            if (Type === 721) {
                var encoded = await Contract.methods.saleToken("Coin", Arg.NFTOwner, [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Type], Arg.ContractAddress)
                //.encodeABI();
            } else {
                var encoded = await Contract.methods.saleToken("Coin", Arg.NFTOwner, [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Type], Arg.ContractAddress)
                //.encodeABI();
            }
            const Transcation = {
                from: Wallet_Details?.UserAccountAddr,
                // to: Arg.ContractAddress,
                // data: encoded,
                value: Arg.PriceWithSerfee
            };
            var gasprice = await Provider.Web3Pro.eth.getGasPrice();
            var gaslimit = await encoded.estimateGas(Transcation)
            var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
            var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
        }

        try {
            if (Type === 721) {
                if (isToken) {
                    await Contract
                        .methods
                        .salewithToken(Arg.Coinname, Arg.NFTOwner, Arg.NFTId, Arg.PriceWithoutSerfee)
                        .send({ from: Wallet_Details.UserAccountAddr })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })
                }
                else {
                    if (chain == config.MATIC_ID) {
                        await Contract.methods.saleToken("Coin", Arg.NFTOwner, [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Type], Arg.ContractAddress)
                            .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee, gasLimit: gasLimit, gasPrice: gasPrice })
                            .on('transactionHash', async (transactionHash) => {
                                Hash = transactionHash
                            })
                    } else {
                        await Contract
                            .methods
                            .saleToken("Coin", Arg.NFTOwner, [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Type], Arg.ContractAddress)
                            .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                            .on('transactionHash', async (transactionHash) => {
                                Hash = transactionHash
                            })
                    }
                }
            }
            else {
                if (isToken) {
                    await Contract
                        .methods
                        .saleWithToken(Arg.NFTOwner, Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Arg.Coinname)
                        .send({ from: Wallet_Details.UserAccountAddr })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })
                }
                else {

                    if (chain == config.MATIC_ID) {
                        await Contract
                            .methods
                            .saleToken("Coin", Arg.NFTOwner, [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Type], Arg.ContractAddress)
                            .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee, gasLimit: gasLimit, gasPrice: gasPrice })
                            .on('transactionHash', async (transactionHash) => {
                                Hash = transactionHash
                            })
                    } else {
                        await Contract
                            .methods
                            .saleToken("Coin", Arg.NFTOwner, [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Type], Arg.ContractAddress)
                            .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                            .on('transactionHash', async (transactionHash) => {
                                Hash = transactionHash
                            })
                    }

                }
            }
            return Hash
        }
        catch (error) {
            console.log("error in usePruchaseee", error);
            return false
        }
    } catch (e) {
        console.log("error in usePruchaseee", error);
        return false
    }
}

export const useAcceptBid = async (Arg, Type, Provider) => {
    const Wallet_Details = Provider
    var Hash = null

    var Contract = await useContract(Type, Provider)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    if (chain == config.MATIC_ID) {
        if (Type === 721) {
            var encoded = await Contract.methods.acceptBId(Arg.Coinname, Arg.BidderAddress, [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Type], Provider.networkConfiguration.singleContract)
            //.encodeABI();
        } else {
            var encoded = await Contract.methods.acceptBId(Arg.Coinname, Arg.BidderAddress, [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Type], Provider.networkConfiguration.multipleContract)
            //.encodeABI();
        }
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Type === 721 ? Provider.networkConfiguration.singleContract : Provider.networkConfiguration.multipleContract,
            // data: encoded,
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }

    try {
        if (Type === 721) {

            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .acceptBId(Arg.Coinname, Arg.BidderAddress, [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Type], Provider.networkConfiguration.singleContract)
                    .send({
                        from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                    })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .acceptBId(Arg.Coinname, Arg.BidderAddress, [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Type], Provider.networkConfiguration.singleContract)
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        else {

            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .acceptBId(Arg.Coinname, Arg.BidderAddress, [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Type], Provider.networkConfiguration.multipleContract)
                    .send({
                        from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                    })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .acceptBId(Arg.Coinname, Arg.BidderAddress, [Arg.NFTId, Arg.PriceWithoutSerfee, Arg.NoofNFTs, Type], Provider.networkConfiguration.multipleContract)
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        return Hash
    }
    catch (error) {
        return false
    }
}

export const useTranfer = async (Arg, Type, Provider) => {
    const Wallet_Details = Provider
    var Hash = null

    var Contract = await useContractTranfer(Type, Provider)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    if (chain == config.MATIC_ID) {
        if (Type === 721) {
            var encoded = await Contract.methods.safeTransferFrom(Arg.NFTOwner, Arg.ToAddress, Arg.NFTId)
            //.encodeABI();
        } else {
            var encoded = await Contract.methods.safeTransferFrom(Arg.NFTOwner, Arg.ToAddress, Arg.NFTId, Arg.NoofNFTs, [])
            //.encodeABI();
        }
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Type === 721 ? Provider.networkConfiguration.singleContract : Provider.networkConfiguration.multipleContract,
            // data: encoded,
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }

    try {

        if (Type === 721) {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .safeTransferFrom(Arg.NFTOwner, Arg.ToAddress, Arg.NFTId)
                    .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .safeTransferFrom(Arg.NFTOwner, Arg.ToAddress, Arg.NFTId)
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        else {

            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .safeTransferFrom(Arg.NFTOwner, Arg.ToAddress, Arg.NFTId, Arg.NoofNFTs, [])
                    .send({
                        from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                    })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .safeTransferFrom(Arg.NFTOwner, Arg.ToAddress, Arg.NFTId, Arg.NoofNFTs, [])
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }

        return Hash
    }
    catch (error) {
        return false
    }
}

export const useTranferFraction = async (Arg, Type, Provider) => {
    console.log("Arg, Type, ProviderArg, Type, Provider",Arg, Type, Provider);
    const Wallet_Details = Provider
    var Hash = null
    try {
    var Contract = await useContractTranfer(Type, Provider)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    if (chain == config.MATIC_ID) {
        if (Type === 721) {
            var encoded = await Contract.methods.safeTransferFrom(Arg.NFTOwner, Arg.ToAddress, Arg.NFTId)
            //.encodeABI();
        } else {
            var encoded = await Contract.methods.safeTransferFrom(Arg.NFTOwner, Arg.ToAddress, Arg.NFTId, Arg.NoofNFTs, [])
            //.encodeABI();
        }
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Type === 721 ? Provider.networkConfiguration.singleContract : Provider.networkConfiguration.multipleContract,
            // data: encoded,
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }

   

        if (Type === 721) {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .safeTransferFrom(Arg.NFTOwner, Arg.ToAddress, Arg.NFTId)
                    .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .safeTransferFrom(Arg.NFTOwner, Arg.ToAddress, Arg.NFTId)
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        else {

            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .safeTransferFrom(Arg.NFTOwner, Arg.ToAddress, Arg.NFTId, Arg.NoofNFTs, [])
                    .send({
                        from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                    })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .safeTransferFrom(Arg.NFTOwner, Arg.ToAddress, Arg.NFTId, Arg.NoofNFTs, [])
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }

        return Hash
    }
    catch (error) {
        console.log("znxbcznxbmZBxmcZ",error);
        return false
    }
}


export const useTranferTrade = async (Arg, Type, Provider, NFT, tokDetails) => {
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useTradeTranfer(Type, Provider)
    console.log("dfaddsasdasdsdsadas", Arg, Type, NFT, tokDetails);
    console.log("Trade Contract Intraction", Contract);
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    console.log("asdasdasdasd", NFT.RandomName, [Arg.NFTOwner, Arg.ToAddress], [Arg.NFTId, Arg.NoofNFTs, Type, NFT.NonceHash, Arg.tokendbPrice], NFT.contractAddress, NFT.SignatureHash);
    if (chain == config.MATIC_ID) {
        if (Type === 721) {
            var encoded = await Contract.methods.nftTransfer(NFT.RandomName, [Arg.NFTOwner, Arg.ToAddress], [Arg.NFTId, Arg.NoofNFTs, Type, NFT.NonceHash, Arg.tokendbPrice], NFT.contractAddress, NFT.SignatureHash)
            //.encodeABI();
        } else {
            var encoded = await Contract.methods.nftTransfer(NFT.RandomName, [Arg.NFTOwner, Arg.ToAddress], [Arg.NFTId, Arg.NoofNFTs, Type, NFT.NonceHash, Arg.tokendbPrice], NFT.contractAddress, NFT.SignatureHash)
            //.encodeABI();
        }

        console.log("sdckjsdn cksd", encoded);
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Provider.networkConfiguration.TradeContract,
            // data: encoded,
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }
    try {
        if (Type === 721) {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .nftTransfer(NFT.RandomName, [Arg.NFTOwner, Arg.ToAddress], [Arg.NFTId, Arg.NoofNFTs, Type, NFT.NonceHash, Arg.tokendbPrice], NFT.contractAddress, NFT.SignatureHash)
                    .send({
                        from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                    })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .nftTransfer(NFT.RandomName, [Arg.NFTOwner, Arg.ToAddress], [Arg.NFTId, Arg.NoofNFTs, Type, NFT.NonceHash, Arg.tokendbPrice], NFT.contractAddress, NFT.SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        else {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .nftTransfer(NFT.RandomName, [Arg.NFTOwner, Arg.ToAddress], [Arg.NFTId, Arg.NoofNFTs, Type, NFT.NonceHash, Arg.tokendbPrice], NFT.contractAddress, NFT.SignatureHash)
                    .send({
                        from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                    })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .nftTransfer(NFT.RandomName, [Arg.NFTOwner, Arg.ToAddress], [Arg.NFTId, Arg.NoofNFTs, Type, NFT.NonceHash, Arg.tokendbPrice], NFT.contractAddress, NFT.SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        return Hash
    }
    catch (error) {
        return false
    }
}

export const useBurn = async (Arg, Type, Provider) => {
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContractBurn(Type, Provider)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    if (chain == config.MATIC_ID) {
        if (Type === 721) {
            var encoded = await Contract.methods.burnNFT(Arg.NFTId)
            //.encodeABI();
        } else {
            var encoded = await Contract.methods.burnNFT(Arg.NFTId, Arg.NoofNFTs)
            //.encodeABI();
        }
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Type === 721 ? Provider.networkConfiguration.singleContract : Provider.networkConfiguration.multipleContract,
            // data: encoded,
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }

    try {
        if (Type === 721) {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .burnNFT(Arg.NFTId)
                    .send({
                        from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                    })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .burnNFT(Arg.NFTId)
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        else {


            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .burnNFT(Arg.NFTId, Arg.NoofNFTs)
                    .send({
                        from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                    })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .burnNFT(Arg.NFTId, Arg.NoofNFTs)
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        return Hash
    }
    catch (error) {
        return false
    }
}

export const useBurnFraction = async (Arg, Type, Provider) => {
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContractBurnFraction(Type, Provider)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    console.log("Arg, Type, Provider",Arg, Type, Provider);
    if (chain == config.MATIC_ID) {
        if (Type === 721) {
            var encoded = await Contract.methods.burnNFT(Arg.NFTId)
            //.encodeABI();
        } else {
            var encoded = await Contract.methods.burnNFT(Arg.NFTId, Arg.NoofNFTs)
            //.encodeABI();
        }
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Type === 721 ? Provider.networkConfiguration.singleContract : Provider.networkConfiguration.multipleContract,
            // data: encoded,
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }

    try {
        if (Type === 721) {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .burnNFT(Arg.NFTId)
                    .send({
                        from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                    })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .burnNFT(Arg.NFTId)
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        else {


            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .burnNFT(Arg.NFTId, Arg.NoofNFTs)
                    .send({
                        from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                    })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .burnNFT(Arg.NFTId, Arg.NoofNFTs)
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        return Hash
    }
    catch (error) {
        return false
    }
}

export const useTokenBurnFrac = async (Arg, Type, Provider) => {
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useDeployer1(Type, Arg.Contact)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    console.log("ndwdlkmsaldmaskld",Arg, Type, Provider);
    if (chain == config.MATIC_ID) {
        if (Type === 721) {
            var encoded = await Contract.methods.burnTotalSupply()
            //.encodeABI();
        } else {
            var encoded = await Contract.methods.burnTotalSupply()
            //.encodeABI();
        }
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Type === 721 ? Provider.networkConfiguration.singleContract : Provider.networkConfiguration.multipleContract,
            // data: encoded,
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }

    try {
        if (Type === 721) {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .burnTotalSupply()
                    .send({
                        from: Wallet_Details.UserAccountAddr
                        ,  gasLimit: gasLimit, gasPrice: gasPrice
                    })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .burnTotalSupply()
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        else {


            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .burnTotalSupply()
                    .send({
                        from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                    })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .burnTotalSupply()
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        return Hash
    }
    catch (error) {
        return false
    }
}


export const useBurnBulk = async (Arg, Type, Provider) => {
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContractBulk(Type, Arg.Contact, Provider)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    if (chain == config.MATIC_ID) {
        if (Type === 721) {
            var encoded = await Contract.methods.burnNFT(Arg.NFTId)
            //.encodeABI();
        } else {
            var encoded = await Contract.methods.burnNFT(Arg.NFTId, Arg.NoofNFTs)
            //.encodeABI();
        }
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Arg.Contact,
            // data: encoded,
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }

    try {
        if (Type === 721) {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .burnNFT(Arg.NFTId)
                    .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .burnNFT(Arg.NFTId)
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        else {

            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .burnNFT(Arg.NFTId, Arg.NoofNFTs)
                    .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .burnNFT(Arg.NFTId, Arg.NoofNFTs)
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        return Hash
    }
    catch (error) {
        return false
    }
}

export const usePutonSale = async (Arg, Type, Provider) => {
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type, Provider)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    if (chain == config.MATIC_ID) {
        let encoded = await Contract.methods.orderPlace(Arg.NFTId, Arg.PriceWithoutSerfee, Arg.ContractADD, Arg.NFTOwner, Type, "order")
        //.encodeABI();
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Provider.networkConfiguration.TradeContract,
            // data: encoded,
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }

    try {
        if (chain == config.MATIC_ID) {
            await Contract
                .methods
                .orderPlace(Arg.NFTId, Arg.PriceWithoutSerfee, Arg.ContractADD, Arg.NFTOwner, Type, "order")
                .send({
                    from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        } else {
            await Contract
                .methods
                .orderPlace(Arg.NFTId, Arg.PriceWithoutSerfee, Arg.ContractADD, Arg.NFTOwner, Type, "order")
                .send({ from: Wallet_Details.UserAccountAddr })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        }
        return Hash
    }
    catch (error) {
        return false
    }
}

export const useCancelOrder = async (Arg, Type, Provider) => {
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type, Provider)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    if (chain == config.MATIC_ID) {
        let encoded = await Contract.methods.cancelOrder(Arg.NFTId)
        //.encodeABI();
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Provider.networkConfiguration.TradeContract,
            // data: encoded,
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }

    try {
        if (chain == config.MATIC_ID) {
            await Contract
                .methods
                .cancelOrder(Arg.NFTId)
                .send({
                    from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        } else {
            await Contract
                .methods
                .cancelOrder(Arg.NFTId)
                .send({ from: Wallet_Details.UserAccountAddr })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        }
        return Hash
    }
    catch (error) {
        return false
    }
}

export const useAllowance = async (Arg, Type, Provider) => {
    const Wallet_Details = Provider
    var Allowance = null
    var Contract = await useTokenContract(Type, Arg.Coinname)
    try {
        if (Type === 721) {
            Allowance = await Contract.methods.allowance(Wallet_Details.UserAccountAddr, config.singleContract).call()
        }
        else {
            Allowance = await Contract.methods.allowance(Wallet_Details.UserAccountAddr, config.singleContract).call();
        }
        return Allowance
    }
    catch (error) {
        return false
    }
}

export const useNFTBalCheckTrade = async (Arg, Type, Provider) => {
    var balance = null
    var Contract = await useNFTContract(Type, Arg.NFTConAddress, Provider)
    try {
        if (Type === 721) {
            const Owner = await Contract.methods.ownerOf(Arg.NFTId).call()
            if ((Owner).toLowerCase() === Arg.NFTOwner)
                balance = 1
            else
                balance = 0
        }
        else {
            if (Arg.Status == "completed") {
                balance = await Contract.methods.balanceOf(Arg.NFTOwner, Arg.NFTId).call();
            }
            else {
                balance = await Contract.methods.balanceOf(Provider.networkConfiguration.TradeContract, Arg.NFTId).call();
            }
        }
        return balance
    }
    catch (error) {
        return false
    }
}

export const useBulBalnceCheck = async (Arg, Type, Provider) => {
    var balance = null
    var Contract = await useContractBulk(Type, Arg.NFTConAddress, Provider)
    try {
        if (Type === 721) {
            const Owner = await Contract.methods.ownerOf(Arg.NFTId).call()
            if ((Owner).toLowerCase() === Arg.NFTOwner)
                balance = 1
            else
                balance = 0
        }
        else {
            if (Arg.Status == "completed") {
                balance = await Contract.methods.balanceOf(Arg.NFTOwner, Arg.NFTId).call();
            }
            else {
                balance = await Contract.methods.balanceOf(Provider.networkConfiguration.TradeContract, Arg.NFTId).call();
            }
        }
        return balance
    }
    catch (error) {
        return false
    }

}

export const useNFTBalCheck = async (Arg, Type, Provider) => {
    var balance = null
    var Contract = await useNFTContract(Type, Arg.NFTConAddress, Provider)
    try {
        if (Type === 721) {
            const Owner = await Contract.methods.ownerOf(Arg.NFTId).call()
            if ((Owner).toLowerCase() === Arg.NFTOwner)
                balance = 1
            else
                balance = 0
        }
        else {
            if (Arg.NFTId) {
                balance = await Contract.methods.balanceOf(Arg.NFTOwner, Arg.NFTId).call();
            }
            else {
                balance = await Contract.methods.balanceOf(Provider.networkConfiguration.TradeContract, Arg.NFTId).call();
            }
        }
        return balance
    }
    catch (error) {
        return false
    }
}

export const useTokenDecimal = async (Arg, Type, Provider) => {
    var Wallet_Details = Provider
    const TokenContract = await useTokenContract(Type, Arg.Coin, Provider)
    try {
        const Decimal = await TokenContract
            .methods
            .decimals()
            .call()
        return Decimal
    }
    catch (error) {
        return false
    }
}

export const useUserTokenBalance = async (Arg, Type, Provider) => {
    var Wallet_Details = Provider
    const TokenContract = await useTokenContract(Type, Arg.Coin, Provider)
    try {
        const Balance = await TokenContract
            .methods
            .balanceOf(Wallet_Details.UserAccountAddr)
            .call()
        return Balance
    }
    catch (error) {
        return false
    }
}



export const useSignCall = async (Arg, UserAccountAddr, Provider) => {
    var Wallet_Details = Provider
    const web3 = await useWeb3(Wallet_Details.Web3Pro)
    try {
        const Confirm = await web3.eth.personal.sign(Arg, UserAccountAddr)
        return true
    }
    catch (error) {
        return false
    }
}

export const useSignLock = async (UserAccountAddr, Provider) => {
    var Wallet_Details = Provider
    const web3 = await useWeb3(Wallet_Details)
    try {
        const Confirm = await web3.eth.personal.sign("Sign Lock Order", UserAccountAddr)
        return true
    }
    catch (error) {
        return false
    }
}

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}


///Lazy Mint functionality

export const useMintPurchase = async (Arg, Type, Provider, data, currentToken) => {
    // data -- nft from tokentable , currentToken -- clicked info token
    console.log("asdkmsadklmasl", Arg, Type, Provider, data, currentToken);
    try {
        const Wallet_Details = Provider
        var Hash = null
        var Contract = await useContract(Type, Provider)
        var nounce = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.NonceHash : data.NonceHash;
        var RandomName = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.RandomName : data.RandomName;
        var SignatureHash = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.SignatureHash : data.SignatureHash
        let chain = await Provider?.Web3Pro?.eth?.getChainId();
        // if (chain == config.MATIC_ID) {
        //     if (Type === 721) {
        //         var encoded = await Contract.methods.lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [data.tokenCreator, Wallet_Details.UserAccountAddr], [currentToken.quantity, "721", Arg.Royaltity, Arg.PriceWithoutSerfee, currentToken.quantity, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
        //         //.encodeABI();
        //     } else {
        //         var encoded = await Contract.methods.lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [data.tokenCreator, Wallet_Details.UserAccountAddr], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
        //         //.encodeABI();
        //     }
        //     const Transcation = {
        //         from: Wallet_Details.UserAccountAddr,
        //         to: Provider.networkConfiguration.TradeContract,
        //         // data: encoded,
        //         value: Arg.PriceWithSerfee
        //     };
        //     console.log("dnfusdjlkmaslkdsad", Transcation);
        //     var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        //     var gaslimit = await encoded.estimateGas(Transcation)
        //     var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        //     var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
        // }
        try {
            if (Type === 721) {
                if (Arg.isToken) {
                    await Contract
                        .methods
                        .lazyMint(String(data.ipfsmeta), data.tokenRoyality, data.tokenCreator, Arg.PriceWithoutSerfee, String(currentToken.coinname), "Buy")
                        .send({ from: Wallet_Details.UserAccountAddr })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })

                } else {
                    if (chain == config.MATIC_ID) {
                        await Contract
                            .methods
                            .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [data.tokenCreator, Wallet_Details.UserAccountAddr], [currentToken.quantity, "721", Arg.Royaltity, Arg.PriceWithoutSerfee, currentToken.quantity, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                            .send({
                                from: Wallet_Details.UserAccountAddr, to: Provider.networkConfiguration.TradeContract, value: Arg.PriceWithSerfee
                                // , gasLimit: gasLimit, gasPrice: gasPrice
                            })
                            .on('transactionHash', async (transactionHash) => {
                                Hash = transactionHash
                            })
                    } else {
                        await Contract
                            .methods
                            .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [data.tokenCreator, Wallet_Details.UserAccountAddr], [currentToken.quantity, "721", Arg.Royaltity, Arg.PriceWithoutSerfee, currentToken.quantity, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                            .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                            .on('transactionHash', async (transactionHash) => {
                                Hash = transactionHash
                            })
                    }
                }
            }
            else {
                if (Arg.isToken) {
                    await Contract
                        .methods
                        .lazyMint(data.tokenRoyality, Arg.NoofNFTs, Arg.TotalToken, String(data.ipfsimage), data.tokenCreator, Arg.PriceWithoutSerfee, String(currentToken.coinname), "Buy")
                        .send({ from: Wallet_Details.UserAccountAddr })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })

                } else {
                    if (chain == config.MATIC_ID) {
                        await Contract
                            .methods
                            .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [data.tokenCreator, Wallet_Details.UserAccountAddr], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                            .send({
                                from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee
                                // , gasLimit: gasLimit, gasPrice: gasPrice
                            })
                            .on('transactionHash', async (transactionHash) => {
                                Hash = transactionHash
                            })
                    } else {

                        await Contract
                            .methods
                            .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [data.tokenCreator, Wallet_Details.UserAccountAddr], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                            .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                            .on('transactionHash', async (transactionHash) => {
                                Hash = transactionHash
                            })
                    }
                }
            }
            return Hash
        }
        catch (error) {
            console.log("sdasdasdasdasdasdasd", error);
            return false
        }
    } catch (error) {
        console.log("kasjdoijosiadas", error);
        return false
    }


}

export const useAcceptBidMint = async (Arg, Type, Provider, data) => {
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type, Provider)
    var nounce = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.NonceHash : data.NonceHash;
    var RandomName = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.RandomName : data.RandomName;
    var SignatureHash = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.RandomName : data.SignatureHash
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    if (chain == config.MATIC_ID) {
        if (Type === 721) {
            var encoded = await Contract.methods.lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [Wallet_Details.UserAccountAddr, Arg.BidderAddress], [Arg.NoofNFTs, '721', data.tokenRoyality, Arg.PriceWithoutSerfee, Arg.NoofNFTs, nounce, "0"], Arg.Coinname, SignatureHash)
            //.encodeABI();
        } else {
            var encoded = await Contract.methods.lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [Wallet_Details.UserAccountAddr, Arg.BidderAddress], [Arg.NoofNFTs, '721', data.tokenRoyality, Arg.PriceWithoutSerfee, Arg.NoofNFTs, nounce, "0"], Arg.Coinname, SignatureHash)
            //.encodeABI();
        }
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Provider.networkConfiguration.TradeContract,
            // data: encoded,
            value: Arg.PriceWithSerfee
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }
    try {
        if (Type === 721) {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [Wallet_Details.UserAccountAddr, Arg.BidderAddress], [Arg.NoofNFTs, '721', data.tokenRoyality, Arg.PriceWithoutSerfee, Arg.NoofNFTs, nounce, "0"], Arg.Coinname, SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [Wallet_Details.UserAccountAddr, Arg.BidderAddress], [Arg.NoofNFTs, '721', data.tokenRoyality, Arg.PriceWithoutSerfee, Arg.NoofNFTs, nounce, "0"], Arg.Coinname, SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        else {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [Wallet_Details.UserAccountAddr, Arg.BidderAddress], [Arg.NoofNFTs, '1155', data.tokenRoyality, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, "0"], Arg.Coinname, SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [Wallet_Details.UserAccountAddr, Arg.BidderAddress], [Arg.NoofNFTs, '1155', data.tokenRoyality, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, "0"], Arg.Coinname, SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        return Hash
    }
    catch (error) {
        console.log("errrorororororroro",error);
        return false
    }
}

export const useAcceptBidTransfer = async (Arg, Type, Provider, data) => {
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type, Provider)
    try {
        if (Type === 721) {
            await Contract
                .methods
                .transferNFT([Wallet_Details.UserAccountAddr, Arg.BidderAddress], Arg.NFTId, Arg.NoofNFTs, Arg.PriceWithoutSerfee, Arg.Coinname)
                .send({ from: Wallet_Details.UserAccountAddr })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        }
        else {
            await Contract
                .methods
                ._transferNFT([Wallet_Details.UserAccountAddr, Arg.BidderAddress], Arg.NFTId, Arg.NoofNFTs, Arg.PriceWithoutSerfee, Arg.Coinname)
                .send({ from: Wallet_Details.UserAccountAddr })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        }
        return Hash
    }
    catch (error) {
        return false
    }
}

export const usePruchaseTransfer = async (Arg, Type, Provider, data) => {
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type, Provider)
    try {
        if (Type === 721) {
            await Contract
                .methods
                .transferNFT([Wallet_Details.UserAccountAddr, Arg.BidderAddress], Arg.NFTId, Arg.NoofNFTs, Arg.PriceWithoutSerfee, Arg.Coinname)
                .send({ from: Wallet_Details.UserAccountAddr })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        }
        else {

            await Contract
                .methods
                ._transferNFT([Arg.NFTOwner, Wallet_Details.UserAccountAddr], Arg.NFTId, Arg.NoofNFTs, Arg.PriceWithoutSerfee, "Coin")
                .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        }
        return Hash
    }
    catch (error) {
        return false
    }
}

export const useTicketsMint = async (Arg, Type, Provider, data, currentToken) => {
    // data -- nft from tokentable , currentToken -- clicked info token
    try {
        console.log("sdfasqweqweqwe", Arg, Type, data, currentToken);
        const Wallet_Details = Provider
        var Hash = null
        var Contract = await useContract(Type, Provider)

        var nounce = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.NonceHash : data.NonceHash;
        var RandomName = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.RandomName : data.RandomName;
        var SignatureHash = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.SignatureHash : data.SignatureHash
        var NewTokenIds = data.tokenID == data.QR_tokenID ? Number(0) : Number(data.tokenID)

        let chain = await Provider?.Web3Pro?.eth?.getChainId();
        if (chain == config.MATIC_ID) {
            let encoded = await Contract.methods.ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, Arg.ContractStatus,""], [data.tokenOwner, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, Arg.tokendbPrice, NewTokenIds,0], "Coin", SignatureHash)
            console.log("Arg.PriceWithSerfee",Arg.PriceWithSerfee);
            //.encodeABI();
            const Transcation = {
                from: Wallet_Details.UserAccountAddr,
                // to: Provider.networkConfiguration.TradeContract,
                // data: encoded,
                value: Arg.PriceWithSerfee
            };
            var gasprice = await Provider.Web3Pro.eth.getGasPrice();
            var gaslimit = await encoded.estimateGas(Transcation)
            var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
            var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
        }
        try {
            if (Type === 721) {
                if (Arg.isToken) {
                    await Contract
                        .methods
                        .lazyMint(String(data.ipfsmeta), data.tokenRoyality, data.tokenCreator, Arg.PriceWithoutSerfee, String(currentToken.coinname), "Buy")
                        .send({ from: Wallet_Details.UserAccountAddr })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })

                } else {
                    await Contract
                        .methods
                        .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, Arg.ContractStatus], [data.tokenCreator, Wallet_Details.UserAccountAddr], [currentToken.quantity, "721", Arg.Royaltity, Arg.PriceWithoutSerfee, currentToken.quantity, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                        .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })
                }
            }
            else {
                if (Arg.isToken) {
                    await Contract
                        .methods
                        .lazyMint(data.tokenRoyality, Arg.NoofNFTs, Arg.TotalToken, String(data.ipfsimage), data.tokenCreator, Arg.PriceWithoutSerfee, String(currentToken.coinname), "Buy")
                        .send({ from: Wallet_Details.UserAccountAddr })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })

                } else {

                    if (chain == config.MATIC_ID) {
                        await Contract
                            .methods
                            .ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, Arg.ContractStatus,""], [data.tokenOwner, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, Arg.tokendbPrice, NewTokenIds,0], "Coin", SignatureHash)
                            .send({
                                from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee, gasLimit: gasLimit, gasPrice: gasPrice
                            })
                            .on('transactionHash', async (transactionHash) => {
                                Hash = transactionHash
                            })
                    } else {
                        console.log("dcsdfusnfrej");
                        await Contract
                            .methods
                            .ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, Arg.ContractStatus,""], [data.tokenOwner, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, Arg.tokendbPrice, NewTokenIds,0], "Coin", SignatureHash)
                            .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                            .on('transactionHash', async (transactionHash) => {
                                Hash = transactionHash
                            })
                    }
                }
            }
            return Hash
        }
        catch (error) {
            console.log("adknjidaksadsa",error);
            return false
        }

    } catch (e) {
        console.log("errirororoiror",e);
        return false
    }
}

export const useTicketsMintBid = async (Arg, Type, Provider, data, currentToken) => {
    // data -- nft from tokentable , currentToken -- clicked info token
    console.log("Arg, Type, Provider, data, currentToken",Arg, Type, Provider, data, currentToken);
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type, Provider)
    var nounce = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.NonceHash : data.NonceHash;
    var RandomName = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.RandomName : data.RandomName;
    var SignatureHash = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.SignatureHash : data.SignatureHash
    var NewTokenIds = data.tokenID == data.QR_tokenID ? Number(0) : Number(data.tokenID)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    if (chain == config.MATIC_ID) {
        let encoded = await Contract.methods.ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, "tickets",""], [Wallet_Details.UserAccountAddr, Arg.BidderAddress, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, "0", NewTokenIds,""], Arg.Coinname, SignatureHash)
        //.encodeABI();
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Provider.networkConfiguration.TradeContract,
            // data: encoded,
            value: Arg.PriceWithSerfee
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }
    console.log("adasmdasjmdlasd",[Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, "tickets"], [Wallet_Details.UserAccountAddr, Arg.BidderAddress, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, "0", NewTokenIds], Arg.Coinname, SignatureHash);
    try {
        if (Type === 721) {
            console.log("Comminggg in Type Identity");
            if (Arg.isToken) {
                await Contract
                    .methods
                    .lazyMint(String(data.ipfsmeta), data.tokenRoyality, data.tokenCreator, Arg.PriceWithoutSerfee, String(currentToken.coinname), "Buy")
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "tickets", ""], [data.tokenCreator, Wallet_Details.UserAccountAddr], [currentToken.quantity, "721", Arg.Royaltity, Arg.PriceWithoutSerfee, currentToken.quantity, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        else {
            if (Arg.isToken) {
                await Contract
                    .methods
                    .lazyMint(data.tokenRoyality, Arg.NoofNFTs, Arg.TotalToken, String(data.ipfsimage), data.tokenCreator, Arg.PriceWithoutSerfee, String(currentToken.coinname), "Buy")
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                //, value: Arg.PriceWithSerfee
                if (chain == config.MATIC_ID) {
                    await Contract
                        .methods
                        .ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, "tickets",""], [Wallet_Details.UserAccountAddr, Arg.BidderAddress, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, "0", NewTokenIds,""], Arg.Coinname, SignatureHash)
                        .send({
                            from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                        })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })
                } else {
                    console.log("sdkjnfksdnfsfs");
                    //, value: Arg.PriceWithSerfee
                    await Contract
                        .methods
                        .ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, "tickets",""], [Wallet_Details.UserAccountAddr, Arg.BidderAddress, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, "0", NewTokenIds,""], Arg.Coinname, SignatureHash)
                        .send({ from: Wallet_Details.UserAccountAddr })
                        .on('transactionHash', async (transactionHash) => {
                            console.log("malsdmlasdas",transactionHash);
                            Hash = transactionHash
                        })
                }
            }


        }
        return Hash
    }
    catch (error) {
        console.log("jsdjfdajfkdklsa",error);
        return false
    }
}

export const useTicketsTansfer = async (Arg, Type, Provider, data, currentToken) => {
    // data -- nft from tokentable , currentToken -- clicked info token
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type, Provider)
    var nounce = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.NonceHash : data.NonceHash;
    var RandomName = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.RandomName : data.RandomName;
    var SignatureHash = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.SignatureHash : data.SignatureHash
    var NewTokenIds = data.tokenID == data.QR_tokenID ? Number(0) : Number(data.tokenID)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    if (chain == config.MATIC_ID) {
        let encoded = await Contract.methods.ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, "transfer"], [Arg.NFTOwner, Arg.TransferAddress, Arg.NFTOwner], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, "0", NewTokenIds], "Coin", SignatureHash)
        //.encodeABI();
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Provider.networkConfiguration.TradeContract,
            // data: encoded,
            value: Arg.PriceWithSerfee
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }
    try {
        if (Type === 721) {
            if (Arg.isToken) {
                await Contract
                    .methods
                    .lazyMint(String(data.ipfsmeta), data.tokenRoyality, data.tokenCreator, Arg.PriceWithoutSerfee, String(currentToken.coinname), "Buy")
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "transfer", ""], [data.tokenCreator, Wallet_Details.UserAccountAddr], [currentToken.quantity, "721", Arg.Royaltity, Arg.PriceWithoutSerfee, currentToken.quantity, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        else {
            if (Arg.isToken) {
                await Contract
                    .methods
                    .lazyMint(data.tokenRoyality, Arg.NoofNFTs, Arg.TotalToken, String(data.ipfsimage), data.tokenCreator, Arg.PriceWithoutSerfee, String(currentToken.coinname), "Buy")
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                if (chain == config.MATIC_ID) {
                    await Contract
                        .methods
                        .ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, "transfer",""], [Arg.NFTOwner, Arg.TransferAddress, Arg.NFTOwner], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, "0", NewTokenIds,""], "Coin", SignatureHash)
                        .send({
                            from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee, gasLimit: gasLimit, gasPrice: gasPrice
                        })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })
                } else {
                    await Contract
                        .methods
                        .ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, "transfer",""], [Arg.NFTOwner, Arg.TransferAddress, Arg.NFTOwner], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, "0", NewTokenIds,""], "Coin", SignatureHash)
                        .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })
                }
            }
        }
        return Hash
    }
    catch (error) {
        return false
    }
}

export const useTicketsTansferBulk = async (Arg, Type, Provider, data, currentToken) => {
    // data -- nft from tokentable , currentToken -- clicked info token
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type, Provider)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    if (chain == config.MATIC_ID) {
        let encoded = await Contract.methods.bulkTicketTransfer(Arg.IPFS, Arg.TransferAddress, [Arg.TotalSend, Arg.Royaltity], Arg.Quani, Arg.Creatorr)
        //.encodeABI();
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Provider.networkConfiguration.TradeContract,
            // data: encoded,
            value: Arg.PriceWithSerfee
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }
    try {
        if (Type === 721) {
            if (Arg.isToken) {
                await Contract
                    .methods
                    .bulkTicketTransfer(Arg.IPFS, Arg.TransferAddress, [Arg.TotalSend, Arg.Royaltity], Arg.Quani, Arg.Creatorr)
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .bulkTicketTransfer(Arg.IPFS, Arg.TransferAddress, [Arg.TotalSend, Arg.Royaltity], Arg.Quani, Arg.Creatorr)
                    .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        else {
            if (Arg.isToken) {
                await Contract
                    .methods
                    .bulkTicketTransfer(Arg.IPFS, Arg.TransferAddress, [Arg.TotalSend, Arg.Royaltity], Arg.Quani, Arg.Creatorr)
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })

            } else {

                if (chain == config.MATIC_ID) {
                    await Contract
                        .methods
                        .bulkTicketTransfer(Arg.IPFS, Arg.TransferAddress, [Arg.TotalSend, Arg.Royaltity], Arg.Quani, Arg.Creatorr)
                        .send({
                            from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee, gasLimit: gasLimit, gasPrice: gasPrice
                        })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })
                } else {
                    await Contract
                        .methods
                        .bulkTicketTransfer(Arg.IPFS, Arg.TransferAddress, [Arg.TotalSend, Arg.Royaltity], Arg.Quani, Arg.Creatorr)
                        .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })
                }
            }
        }
        return Hash
    }
    catch (error) {
        return false
    }
}

const GetGasFees = async (encoded, contractaddress, value, accountAddress, web3Pro) => {
    try {
        var gasPrice = await web3Pro.eth.getGasPrice();
        var gasdata;
        if (value) {
            gasdata = await web3Pro.eth.estimateGas({
                from: accountAddress,
                to: contractaddress,
                value: value,
                data: encoded,
            });
        }
        else {
            gasdata = await web3Pro.eth.estimateGas({
                from: accountAddress,
                to: contractaddress,
                data: encoded,
            });
        }
        return ({ gasdata: gasdata, gasPrice: gasPrice });
    }
    catch (err) {
        return undefined;
    }
}

export const useSoulTransFerMint = async (IPFS, ToAddress, Provider) => {
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await SoulBoundContract(Provider)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    if (chain == config.MATIC_ID) {
        let encoded = await Contract.methods.mint(IPFS, ToAddress)
        //.encodeABI();
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Provider.networkConfiguration.SoulBoundContract,
            // data: encoded,
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
    }
    try {
        if (Contract) {
            if (chain == config.MATIC_ID) {
                await Contract.methods.mint(IPFS, ToAddress).send({
                    from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                }).on('transactionHash', async (transactionHash) => {

                    Hash = transactionHash
                })
            } else {
                console.log("reqiwdmfwe");
                await Contract.methods.mint(IPFS, ToAddress).send({ from: Wallet_Details.UserAccountAddr }).on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
            }
        }
        return Hash
    }
    catch (error) {
        return false
    }
}

export const useClaimNfts = async (Arg, Type, Provider, data, currentToken) => {
    // data -- nft from tokentable , currentToken -- clicked info token
    try {
        console.log("Provider ClaimNFTs", Provider, Provider.networkConfiguration.BNBPROVIDER, Provider.networkConfiguration.TradeContract);
        const Wallet_Details = Provider
        var Hash = null
        const web33 = await UseWeb3(Wallet_Details.networkConfiguration.BNBPROVIDER);
        var Contract = await useContactClaim(Type, Provider, web33)
        var nounce = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.NonceHash : data.NonceHash;
        var RandomName = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.RandomName : data.RandomName;
        var SignatureHash = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.SignatureHash : data.SignatureHash
        var NewTokenIds = data.tokenID == data.QR_tokenID ? Number(0) : Number(data.tokenID)

        console.log("Nounce Randomname SignHash claim", nounce, RandomName, SignatureHash);

        console.log("sdnfcjhndssdkfsd", [Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, "claim"], [data.tokenOwner, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, "0", "0", NewTokenIds], "Coin", SignatureHash);

        let encoded = await Contract.methods.ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, "claim",Arg.LM], [data.tokenOwner, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, "0", "0", NewTokenIds,"1000000000000000000"], "Coin", SignatureHash)
        //.encodeABI();
        let encoded1 = await Contract.methods.ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, "claim",Arg.LM], [data.tokenOwner, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, "0", "0", NewTokenIds,"1000000000000000000"], "Coin", SignatureHash).encodeABI();
        const Transcation = {
            from: config.AdminAddress,
            to: Wallet_Details.networkConfiguration.TradeContract,
            data: encoded1,
            value: Arg.PriceWithSerfee
        };
        console.log("config.AdminAddress",config.AdminAddress,Arg.PriceWithSerfee,config.PrivateKey);
        const Transcation1 = {
            from: config.AdminAddress,
            //to: Wallet_Details.networkConfiguration.TradeContract,
           // data: encoded1,
            value: Arg.PriceWithSerfee
        };
        // Transcation["data"] = encoded1;
         console.log("xcasdasdasdas",Transcation,Transcation1);
        // var gasLimit = await web33.eth.estimateGas(Transcation);
        // console.log("Gassss", gasLimit);
        // Transcation["gas"] = gasLimit;
        // var Gas = gasLimit
    
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        console.log("gaspricegasprice",gasprice);
        var gaslimit = await encoded.estimateGas(Transcation1)
        Transcation["gas"] = Provider.Web3Pro.utils.toHex(gaslimit);
        Transcation["gasPrice"] = Provider.Web3Pro.utils.toHex(gasprice);
        console.log("Intracttttt", gasprice, gaslimit, config.PrivateKey)
        try {
            // console.log("Comming on Polygon", Transcation);
            // var sended = await web33?.eth?.sendTransaction(Transcation).on('transactionHash', async (transactionHash) => {
            //     console.log("transactionhash after UseClaimNftsss", transactionHash)
            //     Hash = transactionHash
            //     return transactionHash
            // })

            const signedTx = await web33.eth.accounts.signTransaction(Transcation, config.PrivateKey);
            console.log("SignTranscations", signedTx);
            var hasssh = await web33.eth.sendSignedTransaction(signedTx.rawTransaction)
            console.log("Hasssh", hasssh);

            // Contract.methods.ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, "transfer"], [data.tokenOwner, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, "0", NewTokenIds], "Coin", SignatureHash)
            //     .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
            //     .on('transactionHash', async (transactionHash) => {
            //         Hash = transactionHash
            //     })


            return hasssh
        }
        catch (error) {
            console.log("Claim catch", error)
            return false
        }
    }
    catch (error) {
        console.log("UseMethod Claim", error)
        return false
    }

}

export const UseWeb3 = async (tokenType) => {
    const httpProvider = new web3.providers.HttpProvider(tokenType);
    const web33 = new web3(httpProvider);
    console.log("Dasdrcp", web33)
    return web33;
};

export const usePassport = async (Arg, Type, Provider, data, currentToken) => {
    try {
        console.log("Arg, Type, Provider, data, currentToken", Arg, Type, Provider, data, currentToken);
        const Wallet_Details = Provider
        var Hash = null
        var Contract = await useContract(Type, Provider)
        console.log("Contract", Contract);
        var nounce = Arg.Hash.NonceHash;
        var RandomName = Arg.Hash.RandomName;
        var SignatureHash = Arg.Hash.SignatureHash
        console.log("trtyrytryt", [data.ipfsmeta, "Status", "lazy", RandomName, "other", "claim721"], [data.tokenCreator, Wallet_Details.UserAccountAddr, config.AdminAddress], [Arg.TotalToken, Type, 0, 0, Arg.NoofNFTs, nounce, Arg.tokendbPrice], "Coin", SignatureHash);
        var NewTokenIds = data.tokenID == data.QR_tokenID ? Number(0) : Number(data.tokenID)
        let chain = await Provider?.Web3Pro?.eth?.getChainId();
        if (chain == config.MATIC_ID) {
            let encoded = await Contract.methods.lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", "claim721"], [data.tokenCreator, Wallet_Details.UserAccountAddr, config.AdminAddress], [Arg.NoofNFTs, Type, 0, 0, Arg.TotalToken, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
            //.encodeABI();
            const Transcation = {
                from: Wallet_Details.UserAccountAddr,
                // to: Provider.networkConfiguration.TradeContract,
                // data: encoded,
            };
            // var gasPrice = await Provider.Web3Pro.eth.getGasPrice();
            // var gasLimit = await Provider.Web3Pro.eth.estimateGas(Transcation);
            // Transcation["gas"] = gasLimit;
            var gasprice = await Provider.Web3Pro.eth.getGasPrice();
            var gaslimit = await encoded.estimateGas(Transcation)
            var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
            var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
            console.log("Intracttttt", gasPrice, gasLimit)
        }

        try {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", "claim721"], [data.tokenCreator, Wallet_Details.UserAccountAddr, config.AdminAddress], [Arg.NoofNFTs, Type, 0, 0, Arg.TotalToken, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
                // Provider?.Web3Pro?.eth?.sendTransaction(Transcation).on('transactionHash', async (transactionHash) => {
                //     console.log("transactionhash after useSoulBoundMintTransfer", transactionHash)
                //     Hash = transactionHash
                // })
            } else {
                await Contract
                    .methods
                    .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", "claim721"], [data.tokenCreator, Wallet_Details.UserAccountAddr, config.AdminAddress], [Arg.NoofNFTs, Type, 0, 0, Arg.TotalToken, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }

            return Hash
        }
        catch (error) {
            console.log("Claim using &21", error)
            return false
        }
    } catch (error) {
        console.log("Claim using &21 Last Error", error)
        return false
    }
}

export const useFiatBuy = async (Arg, Type, Provider, data, currentToken) => {
    try {
        console.log("Using Fiat And buy", Arg, Type, Provider, data, currentToken);
        const Wallet_Details = Provider
        var Hash = null
        var Contract = await useContract(Type, Provider)
        console.log("Contract", Contract);
        var nounce = Arg.Hash.NonceHash;
        var RandomName = Arg.Hash.RandomName;
        var SignatureHash = Arg.Hash.SignatureHash
        console.log("ndkjqwnkjdnqwk", [data.ipfsmeta, "Status", "lazy", RandomName, "other", "fiat"], [data.tokenCreator, Wallet_Details.UserAccountAddr], [Arg.NoofNFTs, Type, Arg.Royaltity, 0, Arg.TotalToken, nounce, Arg.tokendbPrice], "Coin", SignatureHash);
        var NewTokenIds = data.tokenID == data.QR_tokenID ? Number(0) : Number(data.tokenID)
        let chain = await Provider?.Web3Pro?.eth?.getChainId();
        if (chain == config.MATIC_ID) {
            let encoded = await Contract.methods.lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", "fiat"], [data.tokenCreator, Wallet_Details.UserAccountAddr], [Arg.NoofNFTs, Type, Arg.Royaltity, 0, Arg.TotalToken, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
            //.encodeABI();
            const Transcation = {
                from: Wallet_Details.UserAccountAddr,
                // to: Provider.networkConfiguration.TradeContract,
                // data: encoded,
            };
            // var gasPrice = await Provider.Web3Pro.eth.getGasPrice();
            // var gasLimit = await Provider.Web3Pro.eth.estimateGas(Transcation);
            // Transcation["gas"] = gasLimit;
            var gasprice = await Provider.Web3Pro.eth.getGasPrice();
            var gaslimit = await encoded.estimateGas(Transcation)
            var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
            var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
            console.log("Intracttttt", gasPrice, gasLimit)
        }

        try {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", "fiat"], [data.tokenCreator, Wallet_Details.UserAccountAddr], [Arg.NoofNFTs, Type, Arg.Royaltity, 0, Arg.TotalToken, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
                // Provider?.Web3Pro?.eth?.sendTransaction(Transcation).on('transactionHash', async (transactionHash) => {
                //     console.log("transactionhash after useSoulBoundMintTransfer", transactionHash)
                //     Hash = transactionHash
                // })
            } else {
                await Contract
                    .methods
                    .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", "claim721"], [data.tokenCreator, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.NoofNFTs, Type, Arg.Royaltity, 0, Arg.TotalToken, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }

            return Hash
        }
        catch (error) {
            console.log("Claim using &21", error)
            return false
        }
    } catch (error) {
        console.log("Claim using &21 Last Error", error)
        return false
    }
}

export const useTicketsMintFiat = async (Arg, Type, Provider, data, currentToken) => {
    // data -- nft from tokentable , currentToken -- clicked info token
    try {
        const Wallet_Details = Provider
        var Hash = null
        var Contract = await useContract(Type, Provider)

        var nounce = currentToken.NonceHash;
        var RandomName = currentToken.RandomName;
        var SignatureHash = currentToken.SignatureHash
        var NewTokenIds = data.tokenID == data.QR_tokenID ? Number(0) : Number(data.tokenID)
        // alert("mintpurchase")        let chain = await Provider?.Web3Pro?.eth?.getChainId();
        if (chain == config.MATIC_ID) {
            console.log("qweqweqweqweq", [Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, 'fiat'], [data.tokenOwner, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, 0, Arg.TotalToken, nounce, Arg.tokendbPrice, NewTokenIds], "Coin", SignatureHash);
            let encoded = await Contract.methods.ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, Arg.ContractStatus,""], [data.tokenOwner, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, '0', Arg.TotalToken, nounce, Arg.tokendbPrice, NewTokenIds,""], "Coin", SignatureHash)
            //.encodeABI();
            const Transcation = {
                from: Wallet_Details.UserAccountAddr,
                // to: Provider.networkConfiguration.TradeContract,
                // data: encoded
            };
            // var gasLimit = await Provider.Web3Pro.eth.estimateGas(Transcation);
            // var gasPrice = await Provider.Web3Pro.eth.getGasPrice();
            // Transcation["gas"] = gasLimit;
            var gasprice = await Provider.Web3Pro.eth.getGasPrice();
            var gaslimit = await encoded.estimateGas(Transcation)
            var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
            var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
            console.log("Intracttttt", gasPrice, gasLimit)
        }

        try {
            if (Type === 721) {
                console.log("Comminggg in Type Identity");
                if (Arg.isToken) {
                    await Contract
                        .methods
                        .lazyMint(String(data.ipfsmeta), data.tokenRoyality, data.tokenCreator, Arg.PriceWithoutSerfee, String(currentToken.coinname), "Buy")
                        .send({ from: Wallet_Details.UserAccountAddr })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })
                } else {
                    await Contract
                        .methods
                        .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, Arg.ContractStatus], [data.tokenCreator, Wallet_Details.UserAccountAddr], [currentToken.quantity, "721", Arg.Royaltity, '0', currentToken.quantity, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                        .send({ from: Wallet_Details.UserAccountAddr })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })
                }

            }
            else {
                if (Arg.isToken) {
                    await Contract
                        .methods
                        .lazyMint(data.tokenRoyality, Arg.NoofNFTs, Arg.TotalToken, String(data.ipfsimage), data.tokenCreator, Arg.PriceWithoutSerfee, String(currentToken.coinname), "Buy")
                        .send({ from: Wallet_Details.UserAccountAddr })
                        .on('transactionHash', async (transactionHash) => {
                            Hash = transactionHash
                        })

                } else {

                    if (chain == config.MATIC_ID) {
                        console.log("dknfkjsfadad", [Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, Arg.ContractStatus], [data.tokenOwner, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, '0', Arg.TotalToken, nounce, Arg.tokendbPrice, NewTokenIds], "Coin", SignatureHash);
                        await Contract
                            .methods
                            .ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, Arg.ContractStatus,""], [data.tokenOwner, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, '0', Arg.TotalToken, nounce, Arg.tokendbPrice, NewTokenIds,""], "Coin", SignatureHash)
                            .send({
                                from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice
                            })
                            .on('transactionHash', async (transactionHash) => {
                                Hash = transactionHash
                            })
                    } else {
                        console.log("dcsdfusnfrej");
                        await Contract
                            .methods
                            .ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, Arg.ContractStatus,""], [data.tokenOwner, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.NoofNFTs, "1155", Arg.Royaltity, '0', Arg.TotalToken, nounce, Arg.tokendbPrice, NewTokenIds,""], "Coin", SignatureHash)
                            .send({ from: Wallet_Details.UserAccountAddr })
                            .on('transactionHash', async (transactionHash) => {
                                Hash = transactionHash
                            })
                    }
                }


            }
            return Hash

        }
        catch (error) {
            console.log("useMint-HookerrorTicketsss", error)
            return false
        }

    } catch (e) {
        console.log("sdbchdscfjsdnhcujsd", e);
    }
}


export const useTransferMintCall = async (Arg, Type, Provider, data, currentToken) => {
    try {
        console.log("Provider ClaimNFTs", Arg, Type, Provider, data, currentToken);
        const Wallet_Details = Provider
        var Hash = null
        var Contract = await useContract(Type, Provider)
        var nounce = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.NonceHash : data.NonceHash;
        var RandomName = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.RandomName : data.RandomName;
        var SignatureHash = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.SignatureHash : data.SignatureHash
        var NewTokenIds = data.tokenID == data.QR_tokenID ? Number(0) : Number(data.tokenID)
        console.log("Nounce Randomname SignHash claim", nounce, RandomName, SignatureHash);
        console.log("sdnfcjhndssdkfsd", [Arg.Datas.ipfsmeta, "Status", "lazy", RandomName, "claim"], [data.tokenOwner, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.TotalToken, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, Arg?.nounceArg?.NonceHash, "0", NewTokenIds], "Coin", SignatureHash);

        let encoded = await Contract.methods.ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", Arg?.nounceArg?.RandomName, "claim","NoTransfer"], [Wallet_Details.UserAccountAddr, Wallet_Details.UserAccountAddr, Wallet_Details.UserAccountAddr], [Arg.TotalToken, "1155", Arg.Royaltity, 0, Arg.TotalToken, Arg?.nounceArg?.NonceHash, "0", NewTokenIds,"1000000000000000000"], "Coin", Arg?.nounceArg?.SignatureHash)
        const Transcation = {
            from: config.AdminAddress,
            value: 0
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
        console.log("Intracttttt", gasPrice, gasLimit)
        let chain = await Provider?.Web3Pro?.eth?.getChainId();
        try {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", Arg?.nounceArg?.RandomName, "claim","NoTransfer"], [data.tokenOwner, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.TotalToken, "1155", Arg.Royaltity, 0, Arg.TotalToken, Arg?.nounceArg?.NonceHash, "0", NewTokenIds,"1000000000000000000"], "Coin", Arg?.nounceArg?.SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
                // Provider?.Web3Pro?.eth?.sendTransaction(Transcation).on('transactionHash', async (transactionHash) => {
                //     console.log("transactionhash after useSoulBoundMintTransfer", transactionHash)
                //     Hash = transactionHash
                // })
            } else {
                await Contract
                    .methods
                    .ticketMinting([Arg.Datas.ipfsmeta, "Status", "lazy", Arg?.nounceArg?.RandomName, "claim","NoTransfer"], [data.tokenOwner, Wallet_Details.UserAccountAddr, data.tokenCreator], [Arg.TotalToken, "1155", Arg.Royaltity, 0, Arg.TotalToken, Arg?.nounceArg?.NonceHash, "0", NewTokenIds,"1000000000000000000"], "Coin", Arg?.nounceArg?.SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }

            return Hash
        }
        catch (error) {
            console.log("Claim using &21", error)
            return false
        }
    } catch (error) {
        console.log("Claim using &21 Last Error", error)
        return false
    }
}


export const useTicketsTansferBulkAdmin = async (Arg, Type, Provider, data, currentToken) => {
    try {
        //console.log("Provider ClaimNFTs", Arg, Type, Provider, data, currentToken);
        const Wallet_Details = Provider
        var Hash = null
        // console.log("Provider.networkConfiguration.TradeContract", Provider.networkConfiguration.TradeContract);
        var Contract = await useContract(Type, Provider)
        // console.log("Arg.IPFS", Arg.IPFS, Arg.TransferAddress, [Arg.TotalSend, Arg.Royaltity, Arg.TokenIdss], Arg.Quani, Arg.Creatorr);
        // console.log("Wallet_Details.UserAccountAddr", Wallet_Details.UserAccountAddr);
        let encoded = await Contract.methods.bulkTicketTransfer(Arg.IPFS, Arg.TransferAddress, [Arg.TotalSend, Arg.Royaltity, Arg.TokenIdss], Arg.Quani, Arg.Creatorr)
        //.encodeABI();
        console.log("encoded", encoded);
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Wallet_Details.networkConfiguration.TradeContract,
            // data: encoded
        };

        // var gasLimit = await Provider.Web3Pro.eth.estimateGas(Transcation);
        // var gasPrice = await Provider.Web3Pro.eth.getGasPrice();
        // Transcation["gas"] = gasLimit;
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
        console.log("Intracttttt", gasPrice, gasLimit)
        let chain = await Provider?.Web3Pro?.eth?.getChainId();
        try {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .bulkTicketTransfer(Arg.IPFS, Arg.TransferAddress, [Arg.TotalSend, Arg.Royaltity, Arg.TokenIdss], Arg.Quani, Arg.Creatorr)
                    .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
                // Provider?.Web3Pro?.eth?.sendTransaction(Transcation).on('transactionHash', async (transactionHash) => {
                //     console.log("transactionhash after useSoulBoundMintTransfer", transactionHash)
                //     Hash = transactionHash
                // })
            } else {
                await Contract
                    .methods
                    .bulkTicketTransfer(Arg.IPFS, Arg.TransferAddress, [Arg.TotalSend, Arg.Royaltity, Arg.TokenIdss], Arg.Quani, Arg.Creatorr)
                    .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }

            return Hash
        }
        catch (error) {
            console.log("Claimmmmmm", error)
            return false
        }
    } catch (error) {
        console.log("Errorr on BulkTranferrrrrrr", error)
        return false
    }
}


export const useMintPurchaseCharity = async (Arg, Type, Provider, data, currentToken, createrList, OwnerList, CreatorPer, OwnerPer) => {
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useContract(Type, Provider)
    var nounce = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.NonceHash : data.NonceHash;
    var RandomName = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.RandomName : data.RandomName;
    var SignatureHash = data.Bulkmint == "Bulk" ? Arg?.nounceArg?.SignatureHash : data.SignatureHash
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    if (chain == config.MATIC_ID) {
        if (Type === 721) {
            var encoded = await Contract.methods.lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [data.tokenCreator, Wallet_Details.UserAccountAddr], createrList, OwnerList, CreatorPer, OwnerPer, [currentToken.quantity, "721", Arg.Royaltity, Arg.PriceWithoutSerfee, currentToken.quantity, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
            //.encodeABI();
        } else {
            var encoded = await Contract.methods.lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [data.tokenCreator, Wallet_Details.UserAccountAddr], createrList, OwnerList, CreatorPer, OwnerPer, [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
            //.encodeABI();
        }

        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to: Provider.networkConfiguration.TradeContract,
            // data: encoded,
            value: Arg.PriceWithSerfee
        };
        // var gasPrice = await Provider.Web3Pro.eth.getGasPrice();
        // var gasLimit = await Provider.Web3Pro.eth.estimateGas(Transcation);
        // Transcation["gas"] = gasLimit;
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
        console.log("Intracttttt", gasPrice, gasLimit)
    }
    try {
        if (Type === 721) {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [data.tokenCreator, Wallet_Details.UserAccountAddr], createrList, OwnerList, CreatorPer, OwnerPer, [currentToken.quantity, "721", Arg.Royaltity, Arg.PriceWithoutSerfee, currentToken.quantity, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                    .send({
                        from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee, gasLimit: gasLimit, gasPrice: gasPrice
                    })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {
                await Contract
                    .methods
                    .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [data.tokenCreator, Wallet_Details.UserAccountAddr], createrList, OwnerList, CreatorPer, OwnerPer, [currentToken.quantity, "721", Arg.Royaltity, Arg.PriceWithoutSerfee, currentToken.quantity, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        else {
            if (chain == config.MATIC_ID) {
                await Contract
                    .methods
                    .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [data.tokenCreator, Wallet_Details.UserAccountAddr], createrList, OwnerList, CreatorPer, OwnerPer, [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                    .send({
                        from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee, gasLimit: gasLimit, gasPrice: gasPrice
                    })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            } else {

                await Contract
                    .methods
                    .lazyMint([data.ipfsmeta, "Status", "lazy", RandomName, "other", ""], [data.tokenCreator, Wallet_Details.UserAccountAddr], createrList, OwnerList, CreatorPer, OwnerPer, [Arg.NoofNFTs, "1155", Arg.Royaltity, Arg.PriceWithoutSerfee, Arg.TotalToken, nounce, Arg.tokendbPrice], "Coin", SignatureHash)
                    .send({ from: Wallet_Details.UserAccountAddr, value: Arg.PriceWithSerfee })
                    .on('transactionHash', async (transactionHash) => {
                        Hash = transactionHash
                    })
            }
        }
        return Hash
    }
    catch (error) {
        console.log("useMint-Hookerror", error)
        return false
    }
}

// export const useCommonContractFunctions = async (Data, Provider, Method, sendData, PriceWithSerfee) => {
//     try {
//         const Wallet_Details = Provider
//         var Hash = null
//         var Contract = await useContractFraction(Provider)
//         console.log("ajksndjknsakjd", Contract, Data, Method);
//         let chain = await Provider?.Web3Pro?.eth?.getChainId();
//         var encoded = await Contract.methods[Method](...Data)
//         const Transcation = {
//             from: Wallet_Details.UserAccountAddr,
//             to: Provider.networkConfiguration.FractionTradeContract,
//             value: PriceWithSerfee ? PriceWithSerfee : 0
//         };
//         console.log("TranscationTranscationTranscation", Transcation);
//         var gasprice = await Provider.Web3Pro.eth.getGasPrice();
//         var gaslimit = await encoded.estimateGas(Transcation)
//         var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
//         var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
//         console.log("Intracttttt", gasPrice, gasLimit)
//         try {
//             if (chain == config.MATIC_ID) {
//                 await Contract.methods[Method](...Data).send({ from: Wallet_Details.UserAccountAddr, to: Provider.networkConfiguration.FractionTradeContract, gasLimit: gasLimit, gasPrice: gasPrice, value: PriceWithSerfee ? PriceWithSerfee : 0 })
//                     .on('transactionHash', async (transactionHash) => {
//                         Hash = transactionHash
//                     })
//             } else {
//                 await Contract.methods[Method](...Data).send({ from: Wallet_Details.UserAccountAddr, to: Provider.networkConfiguration.FractionTradeContract })
//                     .on('transactionHash', async (transactionHash) => {
//                         Hash = transactionHash
//                     })
//             }
//             return Hash
//         }
//         catch (error) {
//             console.log("Error On Common Contract Functions", error)
//             return false
//         }
//     } catch (e) {
//         console.log("eeeeeeeeeeeeeeeee", e);
//     }
// }

export const useCommonContractFunctions = async (Data, Provider, Method, sendData, PriceWithSerfee) => {
    try {
        const Wallet_Details = Provider
        var Hash = null
        var Contract = await useContractFraction(Provider)
        let chain = await Provider?.Web3Pro?.eth?.getChainId();
        console.log("chainchain", chain, Data, Provider, Method, sendData, PriceWithSerfee);
        var encoded = await Contract.methods[Method](...Data);
        console.log("encoded", encoded);
        if (sendData) {
            var Transcation = {
                from: Wallet_Details.UserAccountAddr,
                to: Provider.networkConfiguration.FractionTradeContract,
                value: PriceWithSerfee
            };
        } else {
            var Transcation = {
                from: Wallet_Details.UserAccountAddr,
                to: Provider.networkConfiguration.FractionTradeContract,
            };
        }
        if (chain == config.MATIC_ID) {
        console.log("TranscationTranscation", Transcation);
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        console.log("gasprice", gasprice);
        var gaslimit = await encoded.estimateGas(Transcation)
        console.log("gaslimit", gaslimit);
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
        console.log("Intracttttt", gasPrice, gasLimit)
        if (sendData) {
            var sendValue = { from: Wallet_Details.UserAccountAddr, value: PriceWithSerfee, gasLimit: gasLimit, gasPrice: gasPrice }
        } else {
            var sendValue = { from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice }
        }
    }
        console.log("sendsendsendsendsend", sendValue);
        try {
            if (chain == config.MATIC_ID) {
                await Contract.methods[Method](...Data).send(sendValue).on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
            } else {
                await Contract.methods[Method](...Data).send(Transcation).on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
            }
            return Hash
        }
        catch (error) {
            console.log("Error On Common Contract Functions", error)
            return false
        }
    } catch (e) {
        console.log("eeeeeeeeeeeeeeeee", e);
    }
}

export const useTokenApproveFraction = async (Arg, Provider, Method, TokenAddress) => {
    const Wallet_Details = Provider
    var Hash = null
    console.log("Arg, Provider, Method, TokenAddress", Arg, Provider, Method, TokenAddress);
    var Contract = await useTokenContractFraction(TokenAddress, Provider)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    console.log("ldjfksadjkasd",chain);
    try {
    if (chain == config.MATIC_ID) {
        console.log("asdnasldkasd",Method);
        var encoded = await Contract.methods[Method](...Arg)
        const Transcation = {   
            from: Wallet_Details.UserAccountAddr,
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
        console.log("Intracttttt", gasPrice, gasLimit)
    }


  
        if (chain == config.MATIC_ID) {
            await Contract
                .methods[Method](...Arg)
                .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        } else {
            await Contract
                .methods[Method](...Arg)
                .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        }
        return Hash
    }
    catch (error) {
        console.log("useTokenApprove-Hookerror", error)
        return false
    }
}



export const useTokenFraction = async (Arg, Type, Provider) => {
    const Wallet_Details = Provider
    var Hash = null
    var Contract = await useTokenContract(Type, Arg.Coinname, Provider)
    let chain = await Provider?.Web3Pro?.eth?.getChainId();
    if (chain == config.MATIC_ID) {
        if (Type === 721) {
            var encoded = await Contract.methods.approve(Wallet_Details.networkConfiguration.FractionTradeContract, Arg.PriceWithSerfee)
        } else {
            var encoded = await Contract.methods.approve(Wallet_Details.networkConfiguration.FractionTradeContract, Arg.PriceWithSerfee)
        }
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
        };
        var gasprice = await Provider.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
        console.log("Intracttttt", gasPrice, gasLimit)
    }

    try {
        if (chain == config.MATIC_ID) {
            await Contract
                .methods
                .approve(Wallet_Details.networkConfiguration.FractionTradeContract, Arg.PriceWithSerfee)
                .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        } else {
            await Contract
                .methods
                .approve(Wallet_Details.networkConfiguration.FractionTradeContract, Arg.PriceWithSerfee)
                .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        }

        return Hash
    }
    catch (error) {
        console.log("useTokenApprove-Hookerror", error)
        return false
    }
}

export const useNFTBalCheckToken = async (Arg, Provider) => {

    console.log("Arg, Type, Provider", Arg, Provider);
    var balance = null
    var Contract = await useTokenContractFraction(Arg.NFTConAddress, Provider)
    console.log("Contract", Contract);
    try {
        balance = await Contract.methods.balanceOf(Arg.NFTOwner).call();
        console.log("useTokenContractFraction", balance);
        return balance
    }
    catch (error) {
        console.log("kamesh valga", error)
        return false
    }
}


export const useDeployerConToken = async (Arg) => {

    const Wallet_Details = Arg.Wallet_Details
    var Hash = null
    var Contract = await useDeployer(Arg.Wallet_Details,Arg.TokAddres)
    let chain = await Wallet_Details?.Web3Pro?.eth?.getChainId();
    console.log("sdmfkdsmadasdmalsk",Contract,chain);
    if (chain == config.MATIC_ID) {
    
            var encoded = await Contract.methods.fractionBulkReturn(...Arg.Arg)
   
        const Transcation = {
            from: Wallet_Details.UserAccountAddr,
            // to:Was
        };
        var gasprice = await Wallet_Details.Web3Pro.eth.getGasPrice();
        var gaslimit = await encoded.estimateGas(Transcation)
        var gasLimit = Wallet_Details.Web3Pro.utils.toHex(gaslimit);
        var gasPrice = Wallet_Details.Web3Pro.utils.toHex(gasprice);
        console.log("Intracttttt", gasPrice, gasLimit)
    }

    try {
        if (chain == config.MATIC_ID) {
            await Contract
                .methods
                .fractionBulkReturn(...Arg.Arg)
                .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        } else {
            await Contract
                .methods
                .fractionBulkReturn(...Arg.Arg)
                .send({ from: Wallet_Details.UserAccountAddr, gasLimit: gasLimit, gasPrice: gasPrice })
                .on('transactionHash', async (transactionHash) => {
                    Hash = transactionHash
                })
        }

        return Hash
    }
    catch (error) {
        console.log("useTokenApprove-Hookerror", error)
        return false
    }
}


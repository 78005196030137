import DETH_ABI from '../ABI/token.json';

export const networkLocal =
{
    MATIC: {

        currencySymbol: 'MATIC',
        tokenSymbol: 'CAKE',
        tokenABI: { CAKE: DETH_ABI },
        NativeTokenSymbol: 'LM',
        tokenAddr: { CAKE: "0x4e13Bf3393db5D55fb083e17B404D4Ce4AE0Bb49".toLowerCase() },
        NativeToken: "0x82Ed0D3D21321f839c7C2E9d4af7ea53aE30AAb3".toLowerCase(),
        singleContract: "0x41c9f17c585137025d9d62a25a9abbd317c47d56".toLowerCase(),
        multipleContract: "0xbd3254850f418aa596a6268577e1bc02847d804c".toLowerCase(),
        TradeContract: "0xf932fab54755bd14d34d89b83a458697e30f85cb".toLowerCase(),
        SoulBoundContract: "0xec7805ff99bbdd8cf1a8cc04a43f9da45df01676".toLowerCase(),
        BulKMintContract: "0x1e9b2e7f96c3dc797f324bc6666aa2039165e167".toLowerCase(),
        FractionTradeContract: "0x7b5E182160ac79AfBA0963FE7401422a1172D849".toLowerCase(),
        Fraction721Contract: "0xf0e06895ea28fa4e6853e913be5defdf2e876d5d".toLowerCase(),
        FractionDeployerContract: "0x58b96f0eb17143ccacca1726d6adb4915774e671".toLowerCase(),
        // old contract 09.01.2025
        // singleContract: "0x67eE1331fc74A4eD8e0FcC21d04571124BEDf1C1".toLowerCase(),
        // multipleContract: "0x4802036D8d9459a705b490D7980Eb3a61fDa06CE".toLowerCase(),
        // TradeContract: "0xa379e90DF3FbE6926cb986B961457e52db56D9c6".toLowerCase(),
        // SoulBoundContract: "0x9Fa15f87d5fb80a3Bf0EB67AAC5153742eC87C35".toLowerCase(),
        // BulKMintContract: "0x3d15f637c95582594Faf4672cBd5C0D09Cbb675f".toLowerCase(),
        // FractionTradeContract: "0x037f3cdcA193C203Bc58923C841323916C4f8b0C".toLowerCase(),
        // Fraction721Contract: "0x06b933041d79fCb156BffC28f5f965B76fC9bc6d".toLowerCase(),
        // FractionDeployerContract: "0x4a366bBf1666811bF36Bbe34Ebf8eF5Dd6059431".toLowerCase(),


        // FractionDeployerContract: "0x2F211C904Ba9E8388060440e8BaF3A2903a3aF2d".toLowerCase(),
        // 0xc9C7B7723961B43B73b8b2E39A6D17288eBAbCB8
        // singleContract: "0x0ae681d38211CAB559Cef6565597190e78A1b72f".toLowerCase(),
        // multipleContract: "0x62D5826F9D21b058cD08E5eFdbF055C86e16f916".toLowerCase(),
        // TradeContract: "0x8a1c49C2cbB757e71C524Cdb163DCf474B6E6C71".toLowerCase(),
        // SoulBoundContract: "0xC19E67a363e63f062Df4380CAD8f7bFa35db54D9".toLowerCase(),
        // BulKMintContract: "0x5b4Bb8a3452ad3e038B7a47Cb977C741A59C97C4".toLowerCase(),
        BNBPROVIDER: "https://rpc-amoy.polygon.technology/",
        Bscscan: "https://www.oklink.com/amoy",
        RPC_URL: "https://rpc-amoy.polygon.technology/",
        networkVersion: '80002',
        chainId: '80002',
        Chainid: 80002,
        Icon: require("../assets/images/Lmwallet/polygon.png"),
        chainName: "Polygon",
        tokenType: "MAT20"
        //trade: ("0x1081E292e0Bda2446a2773C0549d2d1D6bCecFdD").toLowerCase(),
        //multipleContract: "0x689B803Ae597A35CfDBe1f753E249857F99e05de".toLowerCase(),
    },
    ETH: {
        currencySymbol: 'ETH',
        tokenSymbol: 'CAKE',
        tokenAddr: { CAKE: "0x7CAB80ce0E55F46378E493B584eE61aD68878f11".toLowerCase() },
        tokenABI: { CAKE: DETH_ABI },
        NativeTokenSymbol: 'LM',
        NativeToken: "0x408F274995811b8e84749A88A8A13d50Af9b9F48".toLowerCase(),
        singleContract: "0x67eE1331fc74A4eD8e0FcC21d04571124BEDf1C1".toLowerCase(),
        singleContract: "0xb146C641600fB409DbF0Da87F9d301306f1ad429".toLowerCase(),
        multipleContract: "0x49adbBECA2DEcA481C8bA93E0cF15b7dBaebc019".toLowerCase(),
        TradeContract: "0x6621c25493112E20D1Bc0e433c6C2E0541ec1600".toLowerCase(),
        SoulBoundContract: "0xdf6728D13de572EfcCbDC422ae5f84115F95e6E3".toLowerCase(),
        BulKMintContract: "0x50C2aFE1429466f8d2CD789DD3D83547B8eF4F86".toLowerCase(),

        FractionTradeContract: "0x0F2b51c950eD407E7B1047713b8600CaF3209f6F".toLowerCase(),
        Fraction721Contract: "0x96b83d2Eb78048EF990F476C78b286584E50CAFf".toLowerCase(),
        FractionDeployerContract: "0x275EB229f28FBc907da9E9156179402A81fe8A26".toLowerCase(),
        BNBPROVIDER: "https://sepolia.infura.io/v3/af5c3ae692c543b3bb55e52810a639c9",
        Bscscan: "https://sepolia.etherscan.io/",
        RPC_URL: "https://sepolia.infura.io/v3/af5c3ae692c543b3bb55e52810a639c9",
        networkVersion: '11155111',
        chainId: '0xaa36a7',
        Chainid: 11155111,
        Icon: require("../assets/images/Lmwallet/eth.png"),
        chainName: "Sepolia",
        tokenType: "ERC20"
    },
    CAM: {
        currencySymbol: 'CAM',
        tokenSymbol: 'LM',
        tokenAddr: { CAKE: "0x652Ed69AA152219b962cd18bCc4A792894071c12".toLowerCase() },
        tokenABI: { CAKE: DETH_ABI },
        NativeTokenSymbol: 'LM',
        NativeToken: "0x652Ed69AA152219b962cd18bCc4A792894071c12".toLowerCase(),
        singleContract: "0x9AE9766ab45781173296F7c97D1F8617598198d9".toLowerCase(),
        multipleContract: "0xdeE1dA30A52200B8E807763470E0c18C28793FC2".toLowerCase(),
        TradeContract: "0x5D0aC57Df7593360Ae608FCf41bB2Ed88FEc5C4A".toLowerCase(),
        SoulBoundContract: "0xdf6728D13de572EfcCbDC422ae5f84115F95e6E3".toLowerCase(),
        BulKMintContract: "0x5940b6ECE3fe4ac617aD84AD2604fEA5967A9ef8".toLowerCase(),
        FractionTradeContract: "0x3B65F1Dc564007543a3627dc05427c64977322ef".toLowerCase(),
        Fraction721Contract: "0xf4A9eff51D5bfE5b3ba4cB728A62963f5D57b9F8".toLowerCase(),
        FractionDeployerContract: "0xa379e90DF3FbE6926cb986B961457e52db56D9c6".toLowerCase(),
        BNBPROVIDER: "https://columbus.camino.network/ext/bc/C/rpc",
        Bscscan: "https://columbus.caminoscan.com/",
        RPC_URL: "https://columbus.camino.network/ext/bc/C/rpc",
        networkVersion: '501',
        chainId: '0x1f5',
        Chainid: 501,
        Icon: require("../assets/images/Lmwallet/eth.png"),
        chainName: "Columbus Test Network",
        tokenType: "ERC20"
    },
}


export const network =
{
    MATIC: {

        currencySymbol: 'MATIC',
        tokenSymbol: 'USDT',
        tokenABI: { CAKE: DETH_ABI },
        NativeTokenSymbol: 'LM',
        tokenAddr: { CAKE: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F".toLowerCase() },
        NativeToken: "0xFF1ec771DEb0bEe57580B8982E4C69177Da43c83".toLowerCase(),//new
        singleContract: "0x661a9A8C68e301AC1B0C4d005A5c1c748fb57B5C".toLowerCase(), //0x67eE1331fc74A4eD8e0FcC21d04571124BEDf1C1
        multipleContract: "0xf15671815E027CB01FF81Fa4892F2f3Ad1701C42".toLowerCase(),//0x4802036D8d9459a705b490D7980Eb3a61fDa06CE
        TradeContract: "0x97CeC706481a2f53Ea6C607ECd4A1b4292b06272".toLowerCase(), //0xD87ddfE179fE3e2Aea84041118a7E6C7EC975fe3  0xff0ea9ccd1e8572defd352e54aa0887b3db960be  0x7AFa91dDafE314aa33b8d2fD2666cF2EB481a1ef
        SoulBoundContract: "0x0D576bf98381362B64EF5F7818be10ffC9747DF0".toLowerCase(),
        BulKMintContract: "0xAF0900d45e37C8a378009c8Fa8c9b271F3CCA5aF".toLowerCase(),
        FractionTradeContract: "0xD1F5f1E6Aa5B920c2383c30a4eDDF793De4433FF".toLowerCase(), //0x037f3cdcA193C203Bc58923C841323916C4f8b0C 0x52d0a3CF9B70FBaAc62746F3c4Ab0A69Ba20F71e ,0x44702fd4906F351F5223FB7D1CD430d20621F3aC
        Fraction721Contract: "0x87A8B63322AcaE46d84aa2Fe64ba2Ac5D2f3F25b".toLowerCase(),
        FractionDeployerContract: "0x430B1429024dc284ab06277ee9748f645A78275d".toLowerCase(),//0xbfb3c159D43Cb0d4E48C23b89C071220809Cce10 0xc0cE67ea976801c4b441fb7AFB7C8B891358Ef2b
        // FractionDeployerContract: "0x2F211C904Ba9E8388060440e8BaF3A2903a3aF2d".toLowerCase(),
        //0x26D21C2769e8c6077ac3d621476c7eE1D77dD449
        // 0xc9C7B7723961B43B73b8b2E39A6D17288eBAbCB8
        // singleContract: "0x0ae681d38211CAB559Cef6565597190e78A1b72f".toLowerCase(),
        // multipleContract: "0x62D5826F9D21b058cD08E5eFdbF055C86e16f916".toLowerCase(),
        // TradeContract: "0x8a1c49C2cbB757e71C524Cdb163DCf474B6E6C71".toLowerCase(),
        // SoulBoundContract: "0xC19E67a363e63f062Df4380CAD8f7bFa35db54D9".toLowerCase(),
        // BulKMintContract: "0x5b4Bb8a3452ad3e038B7a47Cb977C741A59C97C4".toLowerCase(),
        BNBPROVIDER: "https://polygon-mainnet.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c",
        //"https://rpc-amoy.polygon.technology/",
        Bscscan: "https://polygonscan.com/",
        RPC_URL: "https://polygon-mainnet.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c",
        //"https://rpc-amoy.polygon.technology/",
        networkVersion: '137',
        chainId: '137',
        Chainid: 137,
        Icon: require("../assets/images/Lmwallet/polygon.png"),
        chainName: "Polygon",
        tokenType: "MAT20"
        //trade: ("0x1081E292e0Bda2446a2773C0549d2d1D6bCecFdD").toLowerCase(),
        //multipleContract: "0x689B803Ae597A35CfDBe1f753E249857F99e05de".toLowerCase(),
    },
    ETH: {
        currencySymbol: 'ETH',
        tokenSymbol: 'USDT',
        tokenAddr: { CAKE: "0xdAC17F958D2ee523a2206206994597C13D831ec7".toLowerCase() },
        tokenABI: { CAKE: DETH_ABI },
        NativeTokenSymbol: 'LM',
        NativeToken: "0x87A8B63322AcaE46d84aa2Fe64ba2Ac5D2f3F25b".toLowerCase(),//new
        singleContract: "0x661a9A8C68e301AC1B0C4d005A5c1c748fb57B5C".toLowerCase(),
        multipleContract: "0x8A27A94819c20E41bd5D9Beb16B011F1c2A59FF2".toLowerCase(),
        TradeContract: "0x43fe0E2c52B55eF0B9A375FcD02E1681Fb0102B8".toLowerCase(),
        SoulBoundContract: "0x1C3C941533519DAA541173118C3299632a9DFD5f".toLowerCase(),
        BulKMintContract: "0xf15671815E027CB01FF81Fa4892F2f3Ad1701C42".toLowerCase(),

        FractionTradeContract: "0x2dB55028F8FcDF760828925C9773697c53B33658".toLowerCase(),
        Fraction721Contract: "0x77c4Bd0CE8806Ae7d9067df3b991Da42f32f99aF".toLowerCase(),
        FractionDeployerContract: "0xDc3F41d11901cE9ecaaD260eaE7dcc62Fd2E9100".toLowerCase(),
        //0x1DfB252251DC77B82d5261c00B4B41238723bB08
        BNBPROVIDER: "https://mainnet.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c",
        Bscscan: "https://etherscan.io/",
        RPC_URL: "https://mainnet.infura.io/v3/a6738b5a17514327ae8c9afc47b6179c",
        networkVersion: '1',
        chainId: '0x1',
        Chainid: 1,
        Icon: require("../assets/images/Lmwallet/eth.png"),
        chainName: "Ethereum",
        tokenType: "ERC20"
    },
    CAM: {
        currencySymbol: 'CAM',
        tokenSymbol: 'LM',
        tokenAddr: { CAKE: "0xADB000cFB0ce0e1e27e6882169AAC6d5132eF152".toLowerCase() }, //new
        tokenABI: { CAKE: DETH_ABI },
        NativeTokenSymbol: 'LM',
        NativeToken: "0xADB000cFB0ce0e1e27e6882169AAC6d5132eF152".toLowerCase(),//new
        singleContract: "0x099d05Bab09B157631b3A46B3838Bb10b8158D48".toLowerCase(),//new 0x46C34a2F6517af944C9B4aF3aAF4AA446f397Ad3
        multipleContract: "0xA134bD01a5e6378C684C3492DEf6D09Bd542fC43".toLowerCase(),//new
        //0xAbbA6D01d22d4FEb7fF4C60725782BcdFff68300
        TradeContract: "0x64c7cFB5956DeE2b56973fd2CFF2bDb947b778d1".toLowerCase(), //new not verified
        SoulBoundContract: "0xEe282E171ff59185bD8F32bDA8DAEa4A4AaEEF38".toLowerCase(),//new
        BulKMintContract: "0xF8e83A035B5295101aEd5f9bb52692abbB27D180".toLowerCase(),//new
        FractionTradeContract: "0xd39710d0660892E8C15cd5D0FCa4AF6306359c92".toLowerCase(),//new not verified
        Fraction721Contract: "0x384Ab92c435C657f58b9fb094c0c063ea68D6e21".toLowerCase(),//new
        FractionDeployerContract: "0x29977a86872260c3516dA70d1012F44cF613a232".toLowerCase(),// not verified
        //0x9e53C47A8D5369fD0890de49d0869E5235F33432
        BNBPROVIDER: "https://api.camino.network/ext/bc/C/rpc",
        Bscscan: "https://caminoscan.com/",
        RPC_URL: "https://api.camino.network/ext/bc/C/rpc",
        networkVersion: '500',
        chainId: '0x1f4',
        Chainid: 500,
        Icon: require("../assets/images/Camino.jpg"),
        chainName: "Camino C-Chain",
        tokenType: "ERC20"
    },
}



import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import { TransakConfig, Transak } from "@transak/transak-sdk";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { toast, Flip } from "react-toastify";
import { Dropdown, Tab, Tabs, Modal } from "react-bootstrap";
import addicon from "../assets/images/Lmwallet/addicon.svg";
import importicon from "../assets/images/Lmwallet/importicon.svg";
import scanicon from "../assets/images/Lmwallet/scanicon.svg";
import scan1icon from "../assets/images/Lmwallet/scan1.png";
import modalclosed from "../assets/images/Lmwallet/modalclose.svg";
import pencil from "../assets/images/Lmwallet/pencil.svg";
import copyicon from "../assets/images/Lmwallet/copy.svg";
import { useDispatch, useSelector } from "react-redux";
import { network } from "./network";
import { getBalanceForWallet } from "hooks/useWeb3";
import { LMWalletCreate, PrivateKeyimport } from "actions/v1/user";
import { useHTTPWeb3 } from "hooks/useWeb3";
import { Account_Connect } from "actions/redux/action";
import { SelectLMWallet } from "actions/v1/user";
import CopyToClipboard from "react-copy-to-clipboard";
import { GetNftMoralis } from "actions/v1/user";
import noImg from "../assets/images/Frame.svg";
import { LmWalletNameChange } from "actions/v1/user";
import {
  RouterChecker,
  SendCryptoLM,
  ImportTokenLM,
  GetTokenList,
  GetTranscationHistory,
} from "actions/v1/token";
import { sendNft } from "actions/v1/user";
import close from "../assets/images/close.svg";
import tick from "../assets/images/tick.svg";
import QrScanner from "./QrScanner";
import { IoMdRefresh } from "react-icons/io";
// import Transak from "@transak/transak-sdk";
import Config from "../lib/config";
import Web3 from "web3";
import TokenAbi from "../ABI/token.json";

import { MdArrowOutward } from "react-icons/md";
import Cookies from 'universal-cookie';
toast.configure();
const dashboardRoutes = [];
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function LMwallet(props) {
  const cookies = new Cookies();
  const [tabinner, setTabinner] = useState("crypto");
  const [impwallet, setImpwallet] = useState(false);
  const [SendCrypto, setSendCrypto] = useState(false);
  const [SendCryptoAddrr, setSendCryptoAddrr] = useState("");
  const [TokenAddrr, setTokenAddrr] = useState("");
  const [carddetail, setCarddetail] = useState(false);
  const [step, setStep] = useState(1);
  const [chainId, setChainId] = useState(
    Number(localStorage.getItem("ChainId"))
  );
  const [addractive, setAddractive] = useState();
  const [Routeee, SetRouteeee] = useState("Not Allowed");
  const [allWallets, setallWallet] = useState({});
  const [netwrokArr, setNetworkArr] = useState({});
  const [selectedWallet, setSelectedWallet] = useState({});
  const [privateKey, setPrivateKey] = useState("");
  const [cursor, setCursor] = useState("");
  const [NFTS, setNfts] = useState([]);
  const [privatekeyErr, setPrivatekeyErr] = useState(false);
  const [AddrErr, setAddrErr] = useState(false);
  const [TokenErr, setTokenErr] = useState(false);
  const [reName, setRename] = useState(false);
  const [selectedNft, setSelectedNft] = useState({});
  const [toAddress, setToAddress] = useState("");
  const [amount, setAmount] = useState("1");
  const [renameStr, setRenameStr] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [ShowScanner, SetShowScanner] = useState("Hide");
  const [AmountSend, SetAmountSend] = useState(0);
  const [TokenModel, setTokenModel] = useState(false);
  const [TokenList, setTokenList] = useState([]);
  const [SelectedToken, setSelectedToken] = useState({});
  const [SendTokenModel, setSendTokenModel] = useState(false);
  const [History, setHistory] = useState([]);

  console.log("netwrokArr", netwrokArr, chainId);
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  console.log("Wallet_Details..........", Wallet_Details);

  const dispatch = useDispatch();

  const { ...rest } = props;

  useEffect(() => {
    console.log("xdgzsegzsegze------------------.>>");
    var obj = {};
    Object.values(network).map((val) => {
      obj[val.Chainid] = val;
    });
    console.log("obgsegsefesfej", obj);
    setNetworkArr(obj ?? {});
  }, [network]);

  useEffect(() => {
    getDatasFunction();
  }, [chainId, Wallet_Details.UserAccountAddr]);

  useEffect(() => {
    getNftFunction();
  }, [netwrokArr[chainId], chainId]);

  console.log("network", Object.values(network));
  console.log("aalalalallalala", allWallets, selectedWallet);

  const getDatasFunction = async () => {
    try {
      var wallets = Wallet_Details?.LMWallet?.AllWalletDetails;
      if (wallets) {
        var getBalance = await Promise.all(
          wallets.map(async (val) => {
            val.balance = await getBalanceForWallet(chainId, val.walletaddress);
            if (
              val.walletaddress?.toLowerCase() ==
              localStorage.accountInfo?.toLowerCase()
            ) {
              setSelectedWallet(val);
              setRenameStr(val.WalletName);
            }
            return val;
          })
        );
        GetHistory();
        console.log("getBalance", getBalance);
        setallWallet({
          ...allWallets,
          [chainId]: getBalance,
        });
      }
    } catch (error) {
      console.log("errro ongetDatasFunction", error);
    }
  };

  const onCreateWallet = async () => {
    try {
      const id = toast.loading("Creating Wallet", {
        closeButton: true,
        theme: "dark",
      });
      var resp = await LMWalletCreate({
        email: localStorage.loggedinaddress,
        from: "register",
        newOne: true,
      });
      console.log("ressssss", resp);
      if (resp?.RetData) {
        var address =
          resp.RetData.LmResData.AllWalletDetails[
            resp.RetData.LmResData.AllWalletDetails.length - 1
          ].walletaddress;
        setCursor("");
        setNfts([]);
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            LMWallet: resp.RetData.LmResData,
            UserAccountAddr: address,
            UserAccountBal: 0,
            Accounts: address,
          },
        });
        localStorage.setItem("accountInfo", address);
        localStorage.setItem("user_token", resp.token);
        toast.update(id, {
          render: "Wallet Created Succesfully",
          type: "success",
          isLoading: false,
          autoClose: 2500,
          closeButton: true,
          theme: "dark",
        });
        GetHistory();
      } else {
        toast.update(id, {
          render: "Wallet Not Created",
          type: "success",
          isLoading: false,
          autoClose: 2500,
          closeButton: true,
          theme: "dark",
        });
      }
    } catch (error) {
      console.log("erroe n createt wallet", error);
    }
  };

  const onPrivKeyImport = async () => {
    try {
      if (privateKey == "") return setPrivatekeyErr(true);
      const id = toast.loading("Importing Wallet", {
        closeButton: true,
        theme: "dark",
      });

      var resp = await PrivateKeyimport({
        email: localStorage.loggedinaddress,
        privateKey: privateKey,
      });
      console.log("ressssss", resp);
      if (resp?.RetData) {
        var address = resp.RetData.LmResData.RecentActiveAddress.toLowerCase();

        setCursor("");
        setNfts([]);
        getNftFunction(true, address);
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            LMWallet: resp.RetData.LmResData,
            UserAccountAddr: address,
            UserAccountBal: 0,
            Accounts: address,
          },
        });
        localStorage.setItem("accountInfo", address);
        localStorage.setItem("user_token", resp.token);
        toast.update(id, {
          render: "Wallet Import successfully",
          type: "success",
          isLoading: false,
          autoClose: 2500,
          closeButton: true,
          theme: "dark",
        });
      } else if (resp?.error) {
        toast.update(id, {
          render: resp?.msg,
          type: "error",
          isLoading: false,
          autoClose: 2500,
          closeButton: true,
          theme: "dark",
        });
      }
      setPrivateKey("");
      setImpwallet(false);
    } catch (error) {
      console.log("errrro ononprivkey inporrt", error);
    }
  };

  const OnselectWallet = async (e) => {
    console.log("eeeeee", e);
    setStep(1);
    const params = {
      walletaddress: e.walletaddress,
      email: localStorage.loggedinaddress,
    };
    var respp = await SelectLMWallet(params);
    if (respp?.success) {
      getNftFunction(true, e.walletaddress);
      // localStorage.setItem("accountInfo", e.walletaddress)
      // localStorage.setItem("userAddress", e.walletaddress)
      document.getElementById("acc_close").click();
      GetTokens();
      GetHistory();
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          UserAccountAddr: e.walletaddress,
          Accounts: e.walletaddress,
        },
      });
    }
  };

  const getNftFunction = async (empty, address) => {
    try {
      if (netwrokArr[chainId]) {
        const params = {
          address: address ? address : localStorage.accountInfo,
          cursor: empty ? "" : cursor,
          network: netwrokArr[chainId]?.currencySymbol,
        };
        var resp = await GetNftMoralis(params);
        console.log("response get all nft functiom", resp, params);
        if (resp?.success) {
          empty ? setNfts(resp.data) : setNfts([...NFTS, ...resp.data]);
          setCursor(resp.cursor);
        }
      }
    } catch (error) {
      console.log("erreo on getnft fiunctiom", error);
    }
  };

  const ReFreashFun = async () => {
    const id = toast.loading("Refreashing MetaData", {
      closeButton: true,
      theme: "dark",
    });
    getNftFunction(true, localStorage.accountInfo);
    GetHistory();
    setTimeout(() => {
      toast.update(id, {
        render: "MetaData Refreashed",
        type: "success",
        isLoading: false,
        autoClose: 2500,
        closeButton: true,
        theme: "dark",
      });
    }, 5000);
  };

  const onNetworkChange = (val) => {
    console.log("asdasdas");
    setCursor("");
    setNfts([]);
    setChainId(val.Chainid);
    localStorage.setItem("ChainId", val.Chainid);
  };

  const onNameChange = async () => {
    try {
      const params = {
        walletaddress: localStorage.accountInfo,
        rename: renameStr,
      };
      var resp = await LmWalletNameChange(params);
      console.log("response ogh rename", resp);
      setSelectedWallet({ ...selectedWallet, WalletName: renameStr });
      toast.success(resp?.msg, {
        closeButton: true,
        autoClose: 1500,
        transition: Flip,
        theme: "dark",
      });

      setRename(false);
    } catch (error) {
      console.log("errer on on chane naem", error);
    }
  };

  const NftSentValidation = () => {
    if (toAddress == "") return "Recipient address cannot be empty.";
    if (amount == "") return "Quantity cannot be empty.";
    if (Number(amount) > Number(selectedNft?.amount))
      return "Quantity must be less than nft amount.";

    return true;
  };

  const onSendNft = async () => {
    try {
      const validate = await NftSentValidation();
      if (validate != true) {
        setDisabled(false);
        return toast.error(validate, {
          closeButton: true,
          autoClose: 1500,
          transition: Flip,
          theme: "dark",
        });
      }
      const id = toast.loading("NFT sending...", {
        closeButton: true,
        theme: "dark",
      });

      const params = {
        collectionAddress: selectedNft.colladdress,
        tokenType: netwrokArr[chainId].tokenType,
        toAddress: toAddress,
        tokenID: selectedNft.tokenCounts,
        amount: amount,
        type: selectedNft.type,
        fromAddress: selectedWallet.walletaddress,
        email: localStorage.loggedinaddress,
      };
      console.log("params", params);
      const resp = await sendNft(params);

      if (resp?.success) {
        toast.update(id, {
          render: resp?.msg,
          type: "success",
          isLoading: false,
          autoClose: 2500,
          closeButton: true,
          theme: "dark",
        });

        window.location.reload();
      } else if (resp?.error) {
        toast.update(id, {
          render: resp?.msg,
          type: "error",
          isLoading: false,
          autoClose: 2500,
          closeButton: true,
          theme: "dark",
        });
      }
      setDisabled(false);
    } catch (error) {
      setDisabled(false);
      console.log("erreo on send nft ", error);
    }
  };

  useEffect(() => {
    Route();
    cookies.set('ClaimerCookie', Wallet_Details.UserAccountAddr, { path: '/', domain: '.lazyminter.com' });
    cookies.set('LoginMail', Wallet_Details.LoginAddresss, { path: '/', domain: '.lazyminter.com' });
    // localStorage.setItem('user_token', Resp?.data?.token);
    cookies.set('LoginMail1', Wallet_Details.LoginAddresss);
  }, [Wallet_Details.UserAccountAddr]);

  const Route = async () => {
    if (Wallet_Details.UserAccountAddr) {
      var Addresss = {
        Address: Wallet_Details.UserAccountAddr,
      };
      console.log("Route Checkker Addresss", Addresss);
      var SubscriberList = await RouterChecker(Addresss);
      console.log("RouterrrChecccee", SubscriberList);
      if (SubscriberList?.data?.data?.Message == "Allowed") {
        SetRouteeee(SubscriberList?.data?.data?.UserBalance);
      }
    }
  };

  const settings = {
    apiKey: "ba0e37a8-f902-498f-917f-9078eab65be0", // Your API Key
    environment: "STAGING", // STAGING/PRODUCTION
    defaultCryptoCurrency: "ETH",
    themeColor: "000000", // App theme color
    hostURL: window.location.origin,
    widgetHeight: "700px",
    widgetWidth: "500px",
  };

  const openTransak = () => {
    const transak = new Transak(settings);

    transak.init();

    // To get all the events
    transak.on(transak.ALL_EVENTS, (data) => {
      console.log(data);
    });

    // This will trigger when the user closed the widget
    transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (eventData) => {
      console.log(eventData);
      transak.close();
    });

    // This will trigger when the user marks payment is made.
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
      console.log(orderData);
      window.alert("Payment Success");
      transak.close();
    });
  };

  const onSendCrypto = async (data) => {
    try {
      if (SendCryptoAddrr == "") return setAddrErr(true);
      const id = toast.loading("Tranction Initiated", {
        closeButton: true,
        theme: "dark",
      });
      var SendDataAxios = {
        email: localStorage.loggedinaddress,
        from: Wallet_Details.UserAccountAddr,
        toAddress: SendCryptoAddrr?.toLowerCase(),
        network: localStorage.getItem("ChainId"),
        amount: Web3.utils.toWei(String(AmountSend)),
        Orgamount: AmountSend,
        tokenType:
          localStorage.getItem("ChainId") == Config.MATIC_ID
            ? Config.Currency_MATIC
            :localStorage.getItem("ChainId") == Config.CAM_ID ? Config.Currency_CAM : Config.Currency_ETH,
        CryptoOrToken: data,
        token: data == "Token" ? SelectedToken : "",
      };
      var resp = await SendCryptoLM(SendDataAxios);
      console.log("ressssss", resp);
      if (resp?.data.status) {
        toast.update(id, {
          render: "Funds Transfered successfully",
          type: "success",
          isLoading: false,
          autoClose: 2500,
          closeButton: true,
          theme: "dark",
        });
        getDatasFunction();
        GetTokens();
        GetHistory();
      } else {
        toast.update(id, {
          render: resp?.data?.msg,
          type: "error",
          isLoading: false,
          autoClose: 2500,
          closeButton: true,
          theme: "dark",
        });
      }
      setSendCryptoAddrr("");
      setSendCrypto(false);
      setSendTokenModel(false);
    } catch (error) {
      console.log("errrro Send Crypto Addresss inporrt", error);
    }
  };

  const onImportToken = async () => {
    try {
      if (TokenAddrr == "") return setTokenErr(true);
      const id = toast.loading("Tranction Initiated", {
        closeButton: true,
        theme: "dark",
      });
      var SendDataAxios = {
        email: localStorage.loggedinaddress,
        from: Wallet_Details.UserAccountAddr,
        contractAddress: TokenAddrr?.toLowerCase(),
        network: localStorage.getItem("ChainId"),
        tokenType:
          localStorage.getItem("ChainId") == Config.MATIC_ID
            ? Config.Currency_MATIC
            : localStorage.getItem("ChainId") == Config.CAM_ID ? Config.Currency_CAM : Config.Currency_ETH,
        CryptoOrToken: "Token",
      };
      var resp = await ImportTokenLM(SendDataAxios);
      console.log("ressssss", resp);
      if (resp?.data.status) {
        toast.update(id, {
          render: "Token Imported Sucessfully",
          type: "success",
          isLoading: false,
          autoClose: 2500,
          closeButton: true,
          theme: "dark",
        });
      } else {
        toast.update(id, {
          render: resp?.data?.message,
          type: "error",
          isLoading: false,
          autoClose: 2500,
          closeButton: true,
          theme: "dark",
        });
      }
      setTokenAddrr("");
      setTokenModel(false);
    } catch (error) {
      console.log("errrro Token Addresss inport", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    GetTokens();
    GetHistory();
  }, [Wallet_Details.UserAccountAddr, chainId]);

  const GetTokens = async (e) => {
    if (Wallet_Details.UserAccountAddr) {
      const params = {
        email: localStorage.loggedinaddress,
        Address: Wallet_Details.UserAccountAddr,
        NetWork: localStorage.getItem("ChainId"),
      };
      var resp = await GetTokenList(params);
      console.log("resprespresprespresp", resp);
      if (resp?.data.data.length > 0) {
        console.log("adsda");
        setTokenList(resp?.data.data);
      }
    }
  };
  const GetHistory = async () => {
    if (Wallet_Details.UserAccountAddr) {
      const params = {
        email: localStorage.loggedinaddress,
        Address: Wallet_Details.UserAccountAddr,
        Network: localStorage.getItem("ChainId"),
      };
      var resp = await GetTranscationHistory(params);
      console.log("GetTranscationHistory", resp);
      if (resp?.data?.data?.length > 0) {
        console.log("adsda");
        setHistory(resp?.data?.data);
      }
    }
  };
  return (
    <div className="home_header lmwallet">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <div className="header_logo_align"></div>
          </Link>
        }
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className="walletpages">
        <div className="container container-fluid">
          <div className="row">
            <div className="col-lg-4 col-xl-3 leftsec main">
              <div className="row">
                <Dropdown className="walletdrop">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {netwrokArr && netwrokArr[chainId] && (
                      <img
                        src={netwrokArr[chainId]?.Icon}
                        alt="add"
                        className="img-fluid"
                        width={15}
                      />
                    )}{" "}
                    <span className="mx-2">
                      {netwrokArr[chainId]?.chainName}
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="sheet.walletdropmenu">
                    {/* {coinname.map((e) => ( */}
                    {netwrokArr &&
                      Object.values(netwrokArr).map((val) => (
                        <Dropdown.Item
                          className={val.Chainid == chainId ? "d-none" : ""}
                          onClick={() => onNetworkChange(val)}
                        >
                          {" "}
                          <img
                            src={val.Icon}
                            alt="add"
                            className="img-fluid mx-2"
                            width={15}
                          />
                          {val.chainName}
                        </Dropdown.Item>
                      ))}
                    {/* <Dropdown.Item className={netstate == "Polygon" ? "d-none" : ""} onClick={() => setNetstate("Polygon")}>  <img src={polyicon} alt="add" className="img-fluid mx-2" width={15} /> Polygon</Dropdown.Item>
                    <Dropdown.Item className={netstate == "Ethereum" ? "d-none" : ""} onClick={() => setNetstate("Ethereum")}> <img src={ethicon} alt="add" className="img-fluid mx-2" width={15} /> Ethereum</Dropdown.Item> */}
                    {/* ))} */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="address_sec my-3 ">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {" "}
                      <div className="addr_card active mb-3" id="acc_close">
                        <div className="row">
                          <div className="col-9 pr-3">
                            <p className="address my-3">
                              {selectedWallet?.walletaddress}
                            </p>
                            <p className="bal ">
                              {selectedWallet?.balance?.toFixed(6)}{" "}
                              {netwrokArr[chainId]?.currencySymbol}
                            </p>
                          </div>
                          <div className="col-3 text-right px-3">
                            <i
                              class="fa fa-angle-down mt-3"
                              aria-hidden="true"
                            ></i>
                            <i
                              class="fa fa-angle-up mt-3"
                              aria-hidden="true"
                            ></i>
                          </div>
                          {/* <div className="col-2 px-2">

                          <img src={copyicon} alt="copy" className="img-fluid" width={15} />
                        </div> */}
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {allWallets[chainId]?.map((e, i) => (
                        <div
                          className={
                            addractive == i
                              ? "addr_card mb-3 active"
                              : "addr_card mb-3"
                          }
                        >
                          <div className="row">
                            <div
                              className="col-10"
                              onClick={() => {
                                setAddractive(i);
                                OnselectWallet(e);
                              }}
                            >
                              <p className="address">{e.walletaddress}</p>
                              <p className="bal">
                                {e?.balance?.toFixed(6)}{" "}
                                {netwrokArr[chainId]?.currencySymbol}
                              </p>
                            </div>
                            <CopyToClipboard
                              text={e?.walletaddress}
                              onCopy={() =>
                                toast.success("Address Copied", {
                                  closeButton: true,
                                  autoClose: 1500,
                                  transition: Flip,
                                  theme: "dark",
                                })
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <div className="col-2 pr-0">
                                <img
                                  src={copyicon}
                                  alt="copy"
                                  className="img-fluid"
                                  width={15}
                                  style={{ marginTop: "20px" }}
                                />
                              </div>
                            </CopyToClipboard>
                          </div>
                        </div>
                      ))}
                      {/* {netstate == "Ethereum" ? <>
                        {addrcard.filter((item => item.name == "Ethereum")).map((e, i) => (
                          <div className="addr_card mb-3" onClick={() => setAddractive(i)}>
                            <div className="row">
                              <div className="col-10">
                                <p className="address">{e.address}</p>
                                <p className="bal">{e.balance}</p>


                              </div>
                              <div className="col-2">
                                <img src={copyicon} alt="copy" className="img-fluid" width={15} />
                              </div>
                            </div>
                          </div>



                        ))}
                      </> :
                        <>
                          {addrcard.filter((item => item.name == "Polygon")).map((e, i) => (
                            <div className="addr_card mb-3" onClick={() => setAddractive(i)}>
                              <div className="row">
                                <div className="col-10">
                                  <p className="address">{e.address}</p>
                                  <p className="bal">{e.balance}</p>


                                </div>
                                <div className="col-2">
                                  <img src={copyicon} alt="copy" className="img-fluid" width={15} />
                                </div>
                              </div>
                            </div>



                          ))}
                        </>
                      } */}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              {/* <div className="address_sec my-3 d-none ">
                {allWallets[chainId]?.map((e, i) => (
                  <div className={addrcard.length > 1 ? addractive == i ? "addr_card mb-3 active" : "addr_card mb-3" : "addr_card mb-3 active"} onClick={() => setAddractive(i)}>
                    <div className="row">
                      <div className="col-10">
                        <p className="address">{e.walletaddress}</p>
                        <p className="bal">{e.balance}</p>


                      </div>
                      <div className="col-2">
                        <img src={copyicon} alt="copy" className="img-fluid" width={15} />
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}
              <div className="btnsec_add">
                <img
                  src={addicon}
                  alt="add"
                  className="img-fluid"
                  onClick={() => onCreateWallet()}
                />
                <img
                  src={importicon}
                  alt="import"
                  onClick={() => setImpwallet(true)}
                  className="img-fluid"
                />
                {console.log("adsadasdasdas", Routeee)}
                {Routeee.TokenScanner == "true" && (
                  <>
                    {ShowScanner == "Hide" ? (
                      <img
                        src={scanicon}
                        alt="scan"
                        className="img-fluid"
                        onClick={() => SetShowScanner("Show")}
                      />
                    ) : (
                      <img
                        src={scan1icon}
                        alt="scan"
                        className="img-fluid"
                        onClick={() => SetShowScanner("Hide")}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            {ShowScanner !== "Show" ? (
              <div className="col-lg-8 col-xl-9 mt-3">
                <div className="tabsec">
                  <div className="tabtopsec">
                    <ul>
                      <li
                        className={tabinner == "crypto" ? "active" : ""}
                        onClick={() => setTabinner("crypto")}
                      >
                        Crypto
                      </li>
                      <li
                        className={tabinner == "nft" ? "active" : ""}
                        onClick={() => setTabinner("nft")}
                      >
                        NFT's
                      </li>
                      <li
                        className={tabinner == "buy" ? "active" : ""}
                        onClick={() => setTabinner("buy")}
                      >
                        History
                      </li>
                    </ul>
                  </div>
                  <div className="tabinnersec">
                    {tabinner == "crypto" && (
                      <>
                        <ul className="ulstyle">
                          <li>
                            <div className="cy_list">
                              <div className="leftsec">
                                <div>
                                  <img
                                    src={netwrokArr[chainId]?.Icon}
                                    alt="img"
                                    className="img-fluid coinimg"
                                  />
                                </div>
                                <div className="mt-2 mt-sm-0 ml-sm-2 ml-0">
                                  <p className="name mb-2">
                                    {selectedWallet?.WalletName}
                                  </p>
                                  <p className="value mb-2">
                                    {selectedWallet?.WalletName}
                                  </p>
                                </div>

                                <div className="crypt">
                                  <button
                                    className="btn create_btn"
                                    onClick={() => openTransak()}
                                  >
                                    Buy
                                  </button>
                                  <button
                                    className="btn create_btn ml-sm-2 ml-2 mr-sm-2 mr-2"
                                    onClick={() => setSendCrypto(true)}
                                  >
                                    Send
                                    {/* <MdArrowOutward className="arrowsvg"/> */}
                                  </button>
                                  <button
                                    className="btn create_btn"
                                    onClick={() => setTokenModel(true)}
                                  >
                                    Tokens
                                  </button>
                                </div>
                              </div>
                              <div>
                                <p className="balance">
                                  {selectedWallet?.balance?.toFixed(6)}{" "}
                                  {netwrokArr[chainId]?.currencySymbol}
                                </p>
                              </div>
                            </div>
                          </li>
                          {TokenList &&
                            TokenList?.length > 0 &&
                            TokenList.map((item, index) => {
                              return (
                                <li>
                                  <div className="cy_list">
                                    <div className="leftsec">
                                      <div>
                                        <img
                                          src={netwrokArr[chainId]?.Icon}
                                          alt="img"
                                          className="img-fluid coinimg"
                                        />
                                      </div>
                                      <div>
                                        {console.log("TokenList", item)}
                                        <p className="name mb-2">
                                          {item?.TokenSymbol}
                                        </p>
                                        <p className="value mb-2">
                                          {item?.marketprice}{" "}
                                          {item?.TokenSymbol}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="crypt">
                                      {/* <p className="balance">{item?.marketprice}</p> */}
                                      <button
                                        className="btn create_btn"
                                        onClick={() => {
                                          setSendTokenModel(true),
                                            setSelectedToken(item);
                                        }}
                                      >
                                        Send Token
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </>
                    )}
                    {tabinner == "nft" && (
                      <>
                        {step == 1 && (
                          <div className="nftinner">
                            <div
                              className="px-4 pt-3 text-right refreshicon"
                              onClick={() => {
                                ReFreashFun();
                              }}
                            >
                              <IoMdRefresh />
                            </div>
                            <div className="topsec">
                              <div className="name_row">
                                {!reName ? (
                                  <p onClick={() => setRename(true)}>
                                    <i
                                      class="fa fa-star"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {selectedWallet?.WalletName}{" "}
                                    <img
                                      src={pencil}
                                      alt="pencil"
                                      className="mx-2 img-fluid"
                                    />
                                  </p>
                                ) : (
                                  <div className="editsec">
                                    <input
                                      type="text"
                                      class="form-control editinput"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      placeholder="Enter private key"
                                      value={renameStr}
                                      onChange={(e) => {
                                        setRenameStr(e.target.value);
                                      }}
                                    />
                                    <img
                                      src={close}
                                      alt="pencil"
                                      className="mx-2 img-fluid changepen "
                                      onClick={() => {
                                        setRenameStr(
                                          selectedWallet?.WalletName
                                        );
                                        setRename(false);
                                      }}
                                    />
                                    <img
                                      src={tick}
                                      alt="pencil"
                                      className="mx-2 img-fluid editpen"
                                      onClick={() => onNameChange()}
                                    />
                                  </div>
                                )}
                              </div>
                              {console.log(
                                "ssssssssssssssssssssssss",
                                selectedWallet
                              )}
                              <div className="row bal_row mt-3">
                                <div className="col-xl-6 mb-3">
                                  <div className="leftsec">
                                    <p className="lab_bal">Balance</p>
                                    <p className="bal_val">
                                      {selectedWallet?.balance?.toFixed(6)}{" "}
                                      {netwrokArr[chainId]?.currencySymbol}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-xl-6 mb-3">
                                  <div className="rightsec">
                                    <p className="lab_bal">Address</p>
                                    <p className="grey_val">
                                      {selectedWallet?.walletaddress}
                                    </p>

                                    <CopyToClipboard
                                      text={selectedWallet?.walletaddress}
                                      onCopy={() =>
                                        toast.success("Address Copied", {
                                          closeButton: true,
                                          autoClose: 1500,
                                          transition: Flip,
                                          theme: "dark",
                                        })
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        src={copyicon}
                                        alt="copy"
                                        className="img-fluid"
                                        width={15}
                                      />
                                    </CopyToClipboard>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="cardsec">
                              <div className="row scrollrow collist">
                                {/* nftssssss */}

                                {NFTS?.length != 0 &&
                                  NFTS?.map((e, i) => (
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4 pxside">
                                      <div
                                        className="detail_cardstyle"
                                        onClick={() => {
                                          setSelectedNft(e);
                                          setStep(2);
                                        }}
                                      >
                                        {console.log("asdasdasdasdasd", e)}
                                        {e?.image ? (
                                          <div className="det_img">
                                            {/* {e.image == "https://ipfs.moralis.io:2053/ipfs/QmfGqLBMdnoNih5YXq2nzgn8MzBaMesvBu5aKy6y4qBSN5/1701320258863.png" && e.name =="eTicket_Day1" ?

                                            <img src={"https://ipfs.moralis.io:2053/ipfs/QmfGqLBMdnoNih5YXq2nzgn8MzBaMesvBu5aKy6y4qBSN5/RiskBeyond23_1.png"} alt="cardimg" className="img-fluid" />
                                            :
                                            e.image == "https://ipfs.moralis.io:2053/ipfs/QmfGqLBMdnoNih5YXq2nzgn8MzBaMesvBu5aKy6y4qBSN5/1701320258863.png" && e.name =="eTicket_Day2" ?

                                              <img src={"https://ipfs.moralis.io:2053/ipfs/QmfGqLBMdnoNih5YXq2nzgn8MzBaMesvBu5aKy6y4qBSN5/RiskBeyond23_2.png"} alt="cardimg" className="img-fluid" />
                                              : */}
                                            <img
                                              src={e.image}
                                              alt="cardimg"
                                              className="img-fluid"
                                            />
                                            {/* } */}
                                          </div>
                                        ) : (
                                          <div className="det_img noimgc">
                                            <img
                                              src={noImg}
                                              alt="cardimg"
                                              className="img-fluid demoimage"
                                            />
                                          </div>
                                        )}

                                        <div className="labelname">
                                          <p>
                                            {e.name ? e.name : "No Meta data"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              {cursor && (
                                <div
                                  className="my-3"
                                  onClick={() => getNftFunction()}
                                >
                                  <Link
                                    to="#"
                                    className="btn btn_purple_sm create_button create_pg_create_btn create_pg_create_btn_single"
                                  >
                                    Load More
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {console.log("selelelelelelle0", selectedNft)}
                        {step == 2 && (
                          <div className="nftinner detailsec">
                            <div className="topsec">
                              <div className="name_row">
                                <i
                                  class="fa fa-arrow-left"
                                  onClick={() => setStep(1)}
                                  aria-hidden="true"
                                ></i>
                                <p>
                                  <i class="fa fa-star" aria-hidden="true"></i>{" "}
                                  {selectedNft?.title}{" "}
                                </p>
                              </div>
                            </div>
                            <div className="card_detailsec">
                              <div className="row">
                                <div className="col-xl-4">
                                  <div
                                    data-toggle="modal"
                                    data-target="#nftinfoimg_modal"
                                  >
                                    <div
                                      className="detail_imgsec"
                                      style={{ cursor: "pointer" }}
                                    >
                                      {selectedNft?.image ? (
                                        <img
                                          src={selectedNft?.image}
                                          alt="detail"
                                          className="img-fluid"
                                          // onError={}
                                        />
                                      ) : (
                                        <img
                                          src={noImg}
                                          alt="detail"
                                          className="img-fluid"
                                          style={{ objectFit: "unset" }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-8 rightside">
                                  <p className="lab_head">NFT Details page</p>
                                  <div className="label_card">
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <p className="label">NFT Name</p>
                                      </div>
                                      <div className="col-8">
                                        <p className="value">
                                          {selectedNft?.name}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <p className="label">Network</p>
                                      </div>
                                      <div className="col-8">
                                        <p className="value">
                                          {netwrokArr[chainId]?.currencySymbol}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <p className="label">
                                          Collection Address
                                        </p>
                                      </div>
                                      <div className="col-8">
                                        <p className="value">
                                          {selectedNft?.colladdress}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <p className="label">Collection Type</p>
                                      </div>
                                      <div className="col-8">
                                        <p className="value">
                                          {selectedNft?.type}
                                        </p>
                                      </div>
                                    </div>
                                    {/* <div className="row mb-2">
                                      <div className="col-4">
                                        <p className="label">Blockscan</p>
                                      </div>
                                      <div className="col-8">
                                        <p className="value">
                                          <a
                                            href={`${netwrokArr[chainId].Bscscan}nft/${selectedNft?.colladdress}/${selectedNft?.tokenCounts}`}
                                            target="_blank"
                                            style={{ color: "inherit" }}
                                          >
                                            {`${netwrokArr[chainId].Bscscan}nft/${selectedNft?.colladdress}/${selectedNft?.tokenCounts}`}
                                          </a>
                                        </p>
                                      </div>
                                    </div> */}
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <p className="label">Qty</p>
                                      </div>
                                      <div className="col-8">
                                        <p className="value">
                                          {selectedNft?.amount}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="btnsec mt-3">
                                    <button
                                      className="btn sendbtn"
                                      onClick={() => setCarddetail(true)}
                                    >
                                      Send
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {tabinner == "buy" && (
                      <>
                        <ul className="ulstyle">
                          {History &&
                            History?.length > 0 &&
                            History?.map((item, index) => {
                              return (
                            
                                <a
                                  href={
                                    Wallet_Details?.networkConfiguration
                                      ?.Bscscan +
                                    "/" +
                                    "tx/" +
                                    item.txid
                                  }
                                  target="_blank"
                                >
                                  {console.log(
                                    "</Link></Link></Link>",
                                    Wallet_Details,item
                                  )}
                                  <li>
                                    {console.log("History Item", item)}
                                    <div className="cy_list mb-2">
                                      <div className="leftsec">
                                        <div>
                                          <img
                                            src={netwrokArr[chainId]?.Icon}
                                            alt="img"
                                            className="img-fluid coinimg"
                                          />
                                        </div>
                                        <div>
                                          <p className="name mb-2"></p>
                                          <p className="value mb-2">
                                            To: {item?.toaddress?.slice(0, 10)}
                                          </p>
                                        </div>
                                      </div>
                                      <div>
                                        <p className="balance">
                                          {item?.amount || 0}{" "}
                                          {netwrokArr[chainId]?.currencySymbol}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                </a>
                              );
                            })}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-lg-8 col-xl-9 mt-3">
                <QrScanner />
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />

      {/* {impwallet && <Importwallet onDismiss={()=> setImpwallet(false)}/>} */}

      <Modal
        show={impwallet}
        size="sm"
        centered
        backdrop="static"
        className="wallet_modal_style"
      // keyboard={false}
      >
        <img
          src={modalclosed}
          alt="close"
          onClick={() => {
            setPrivateKey("");
            setImpwallet(false);
          }}
          className="img-fluid modal_close_icon"
        />
        <Modal.Header>
          <Modal.Title>Import Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div class="form-group">
              <label for="exampleInputEmail1">Private Key:</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter private key"
                value={privateKey}
                onChange={(e) => {
                  setPrivatekeyErr(false);
                  setPrivateKey(e.target.value);
                }}
              />
              {privatekeyErr && (
                <p style={{ color: "red", fontSize: "13px" }}>
                  PrivateKey cannot be empty
                </p>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="modal_btn"
            onClick={() => onPrivKeyImport()}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={carddetail}
        size="sm"
        centered
        backdrop="static"
        className="wallet_modal_style"
      // keyboard={false}
      >
        <img
          src={modalclosed}
          alt="close"
          onClick={() => setCarddetail(false)}
          className="img-fluid modal_close_icon"
        />

        <Modal.Body>
          <div className="detail_img_sec">
            <img
              src={selectedNft?.image ? selectedNft?.image : noImg}
              className="img-fluid"
              alt="details"
            />
          </div>
          <div className="row">
            <div
              className={
                Number(selectedNft?.amount) > 1 ? "col-md-9" : "col-md-12"
              }
            >
              <form>
                <div class="form-group">
                  <label for="exampleInputEmail1">Send Address:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter recipient address"
                    value={toAddress}
                    onChange={(e) => setToAddress(e.target.value)}
                  />
                </div>
              </form>
            </div>
            {Number(selectedNft?.amount) > 1 && (
              <div className="col-md-3">
                <form>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Qty:</label>
                    <input
                      type="number"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Qty"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            disabled={disabled}
            className="modal_btn"
            onClick={() => {
              setDisabled(true);
              onSendNft();
            }}
          >
            Send
          </button>
        </Modal.Footer>
      </Modal>
      {console.log(impwallet, "sdefsdf")}

      <Modal
        show={SendCrypto}
        size="sm"
        centered
        backdrop="static"
        className="wallet_modal_style"
      // keyboard={false}
      >
        <img
          src={modalclosed}
          alt="close"
          onClick={() => {
            setSendCryptoAddrr("");
            setSendCrypto(false);
          }}
          className="img-fluid modal_close_icon"
        />
        <Modal.Header>
          <Modal.Title>Send Crypto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div class="form-group">
              <label for="exampleInputEmail1">Wallet Address:</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Wallet Address"
                value={SendCryptoAddrr}
                onChange={(e) => {
                  setAddrErr(false);
                  setSendCryptoAddrr(e.target.value);
                }}
              />
              {AddrErr && (
                <p style={{ color: "red", fontSize: "13px" }}>
                  Address cannot be empty
                </p>
              )}
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Amount:</label>
              <input
                type="number"
                id="TokenPrice"
                className="form-control"
                onChange={(e) => SetAmountSend(e.target.value)}
                // value={(Number(TokenPrice))}
                placeholder="Enter Amount"
                aria-label="TokenPrice"
                aria-describedby="basic-addon2"
                onKeyDown={handleKeyDown}
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="modal_btn"
            onClick={() => onSendCrypto("Crypto")}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={TokenModel}
        size="sm"
        centered
        backdrop="static"
        className="wallet_modal_style"
      // keyboard={false}
      >
        <img
          src={modalclosed}
          alt="close"
          onClick={() => {
            setTokenAddrr("");
            setTokenModel(false);
          }}
          className="img-fluid modal_close_icon"
        />
        <Modal.Header>
          <Modal.Title>Import Token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div class="form-group">
              <label for="exampleInputEmail1">Token Address:</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Token Address"
                value={TokenAddrr}
                onChange={(e) => {
                  setTokenErr(false);
                  setTokenAddrr(e.target.value);
                }}
              />
              {TokenErr && (
                <p style={{ color: "red", fontSize: "13px" }}>
                  Address cannot be empty
                </p>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="modal_btn"
            onClick={() => onImportToken()}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={SendTokenModel}
        size="sm"
        centered
        backdrop="static"
        className="wallet_modal_style"
      // keyboard={false}
      >
        <img
          src={modalclosed}
          alt="close"
          onClick={() => {
            setSendCryptoAddrr("");
            setSendTokenModel(false);
          }}
          className="img-fluid modal_close_icon"
        />
        <Modal.Header>
          <Modal.Title>Send Token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div class="form-group">
              <label for="exampleInputEmail1">Wallet Address:</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Wallet Address"
                value={SendCryptoAddrr}
                onChange={(e) => {
                  setAddrErr(false);
                  setSendCryptoAddrr(e.target.value);
                }}
              />
              {AddrErr && (
                <p style={{ color: "red", fontSize: "13px" }}>
                  Address cannot be empty
                </p>
              )}
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Amount:</label>
              <input
                type="number"
                id="TokenPrice"
                className="form-control"
                onChange={(e) => SetAmountSend(e.target.value)}
                // value={(Number(TokenPrice))}
                placeholder="Enter Amount"
                aria-label="TokenPrice"
                aria-describedby="basic-addon2"
                onKeyDown={handleKeyDown}
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="modal_btn"
            onClick={() => onSendCrypto("Token")}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
      {/* nft info modal */}

      <div
        class="modal fade primary_modal infoimg_modal"
        id="nftinfoimg_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="infoimg_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aborder-radiusria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body px-0">
              <img src={selectedNft?.image} alt="Collections" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

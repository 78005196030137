import React, { useState, useRef, useEffect } from "react";
import Button from "components/CustomButtons/Button.js";
import { WbSunny, Brightness3 } from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import 'react-phone-number-input/style.css'
// import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import QRCode from 'qrcode.react';
import Cookies from 'universal-cookie';
import petimage from "../assets/images/lazymintingnew/petimage.png";
import SubDomainFooter from 'components/Footer/SubDomainFooter';
import { FaEye } from 'react-icons/fa';
import blutick from "../assets/images/lazymintingnew/blutick.png";
import logoimage from "../assets/images/lazymintingnew/logo.png";
import { AiOutlineHeart } from 'react-icons/ai';
import { GrFormClose } from 'react-icons/gr';
import { FaDownload } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import { Dropdown } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { ThreeDots } from 'react-loader-spinner'
import { Pagination, Autoplay, Navigation } from "swiper";
import html2canvas from 'html2canvas';
import { GetDomainsAndDetails, TopWinners, NftsUnloacked, CampaignUsers, AutoClaimSave, UserPackCheck, NFTnameValidation } from "../actions/v1/token";
import { ipfsImageHashGet, NFTDetails2DB } from '../actions/controller/tokenControl'
import ReactHTMLParser from "react-html-parser";
import config from 'lib/config'
import { useSelector } from 'react-redux';
import { toast, Slide } from "react-toastify";
import { AddressUserDetails_GetOrSave_Action } from "../actions/v1/user"
import "react-toastify/dist/ReactToastify.css"
import moment from 'moment';
import isEmpty from '../lib/isEmpty'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";

export default function Tempalatenew() {

    useEffect(() => {
        
        themechange(localStorage.getItem("theme1"))
    }, []);

    const InitialForm = {
        Breed: "",
        name: "",
        weight: "",
        description: "",
        chipno: "",
        height: "",
        genders: "",
        vaccine: "",
        mail: "",
        ContactNumber: "",
        Image: "",
        ID: Date.now(),
        DateChoose: "",
        Terms: false,
        Privacy: false,
        User: "",
        Ipfs: "",
        IpfsImage: "",
        metajson: "",
        ImageName: "",
        additionalImage: "",
        TokenID: Date.now(),
        NFTCategory: "Web3 IDs",
        quantity: 1,
        Balance: 1,
        contractaddress: (config.PolygonbulkCon).toLowerCase(),
        Status: "true",
        type: 721,
        HashValue: "not yet minted",
        NetworkName: config.MATIC_ID,
        SaleType: "OnlyTransfer",
        royalties: 1,
    }
    const [Formdata, setFormdata] = useState(InitialForm);
    const [NFT, setNFT] = useState({})
    const cookies = new Cookies();
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const [winner, Setwinner] = useState([])
    const [unlocaked, SetUnlocked] = useState()
    const [Cookeieeeee, SetCookeieeeee] = useState(null)
    const [UserDetails, SetUserDetails] = useState(null)
    const [ValidationError, setValidationError] = useState(null)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
    const OnChangehandle = (e) => {
        e.preventDefault();
        const { id, value } = e.target;
        let Form = Formdata;
        Form = { ...Form, ...{ [id]: value } };
        setFormdata(Form);
    };

    const handleSelect = (eventKey, event) => {
        const id = event.target.id;
        var Form = Formdata
        if (id == "Breed") {
            Form = { ...Form, ...{ [id]: eventKey } };
            setFormdata(Form)
        }
        else if (id == "genders") {
            Form = { ...Form, ...{ [id]: eventKey } };
            setFormdata(Form)
        }
        else if (id == "vaccine") {
            Form = { ...Form, ...{ [id]: eventKey } };
            setFormdata(Form)
        }

    }
    const [selectedImage, setSelectedImage] = useState(petimage);
    const [imagename, setImagename] = useState("Select File");
    const componentRef = useRef(null);
    const fileInputRef = useRef(null);
    function dataURLtoFile(dataurl, filename) {
        // console.log("dataurldataurl", dataurl)
        // if (!dataurl.includes("image") ) { // !hot fix 
        //     return null 
        //     }
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1] ,
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        console.log(mime)
        
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });

        

     
       
    }
    const handleCapture = async () => {
        if (componentRef.current) {
            console.log("jsadlksadkas;d",componentRef.current);
            if(windowWidth > 767){
            html2canvas(componentRef.current).then((canvas) => {
                console.log("asdasdasdasd",canvas);
                const dataURL = canvas.toDataURL('image/png');
                // Create a download link for the captured image
                const a = document.createElement('a');
                a.href = dataURL;
                a.download = `${Date.now()}` + '.png';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
            }else{
            html2canvas(document.querySelector("#capture")).then(canvas => {
                console.log("asbdkjashdkjas",canvas);
                document.body.appendChild(canvas)
                             const dataURL = canvas.toDataURL('image/png');
                const a = document.createElement('a');
                a.href = dataURL;
                a.download = `${Date.now()}` + '.png';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
        }
        }
    };
    const handleFileUpload = () => {
        fileInputRef.current.click();
    };

    const handleFilesSelected = async (event) => {
        const files = event.target.files[0];
        var Valid = await ImageValidation(files)
        if (Valid.Success) {
            const filename = files.name;
            if (files) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    setSelectedImage(reader.result);
                };

                reader.readAsDataURL(files);
                toast.success("Image Uploaded Successfully")
                setImagename(filename);
            }
        } else {
            toast.error(Valid.Message)
        }
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [Sateeee, SetStatewww] = useState("")
    const [theme1, settheme1] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [value, setValue] = useState("")
    const [disabledState,setdisabledState] = useState(false)
    const [state, setState] = useState({
        //name: "",
        name: {},
        fileData: null
    });

    const themechange = (data) => {

        if (data == "DT" || data == undefined) {

            localStorage.setItem("theme1", "DT")
            document.getElementById("root").classList.remove("light_theme")
            document.getElementById("root").classList.add("dark_theme")
            settheme1(false)

        } else {
            localStorage.setItem("theme1", "LT")
            document.getElementById("root").classList.add("light_theme")
            document.getElementById("root").classList.remove("dark_theme");
            settheme1(true)
        }
    }

    const PreviewComp = () => {
        return (
            <div 
            ref={componentRef} 
            id="capture" className="card previewcard">
                <div className="mobilefullimage">
                    <img src={selectedImage} class="card-img-top" alt="..." />
             
                </div>
                <div class="card-body">
                    <div className="row align-items-center mb-1">
                        <div className="col-6 col-sm-6 col-md-6">
                            <h4 className="previewname myname mb-0">{Formdata.name} <img src={blutick} class="blutick" alt="" /></h4>
                            {/* <p className="lighttext smalltext">{Formdata.description}</p> */}
                        </div>
                        <div className="col-6 col-sm-6 col-md-6">
                            <h4 className="previewname myname1 text-nowrap text-right mb-0">{Formdata.ID}</h4>
                            {/* <p className="lighttext text-right">Passport Number<br></br>Valid for 1 Year</p> */}
                        </div>
                    </div>
                    <p className="lighttext smalltext">{Formdata.description}</p>



                    <div class="wrapper mt-2 mb-2">
                        {!Sateeee.DobFieldStatus &&
                            <div class="item1 mt-2"><div className="det">
                                <h6 className="det-tit">Age</h6>
                                <p className="previewname">{_calculateAge(startDate)}</p>
                            </div></div>}
                        {!Sateeee.GenderStatus &&
                            <div className="item2 mt-2">
                                <div className="det">
                                    {Sateeee?.GenderDropDown == "" || Sateeee?.GenderDropDown == null || Sateeee?.GenderDropDown == undefined ?
                                        <h6 className="det-tit">Gender</h6>
                                        :
                                        <h6 className="det-tit">{Sateeee?.GenderDropDown}</h6>

                                    }

                                    <p className="previewname">{Formdata.genders ? Formdata.genders : "Male"}</p>
                                </div>
                            </div>
                        }
                        {!Sateeee.WeightStatus &&
                            <div className="item3 mt-2">
                                <div className="det">
                                    {Sateeee?.Weight == "" || Sateeee?.Weight == null || Sateeee?.Weight == undefined ?
                                        <h6 className="det-tit">Weight</h6>
                                        :
                                        <h6 className="det-tit">{Sateeee?.Weight}</h6>

                                    }
                                    <p className="previewname">{Formdata.weight}</p>
                                </div>
                            </div>
                        }
                        {!Sateeee.VaccineStatus &&
                            <div className="item4 mt-2">
                                {console.log("Sateeee?.VaccineField",Sateeee?.VaccineField)}
                                <div className="det">
                                    {Sateeee?.VaccineField == "" || Sateeee?.VaccineField == null || Sateeee?.VaccineField == undefined ?
                                        <h6 className="det-tit">Vaccine</h6>
                                        :
                                        <h6 className="det-tit">{Sateeee?.VaccineField}</h6>

                                    }

                                    <p className="previewname">{Formdata.vaccine ? Formdata.vaccine : "1"}</p>
                                </div>
                            </div>
                        }
                    </div>

                    <div class="wrapper mb-3">
                        {!Sateeee.BreadStatus &&
                            <div className="item1 mt-2">
                                <div className="det">
                                    {Sateeee?.BreadDropDown == "" || Sateeee?.BreadDropDown == null || Sateeee?.BreadDropDown == undefined ?
                                        <h6 className="det-tit">Breed</h6>
                                        :
                                        <h6 className="det-tit">{Sateeee.BreadDropDown}</h6>

                                    }


                                    <p className="previewname">{Formdata.Breed ? Formdata.Breed : "Toy Poodle"}</p>
                                </div>
                            </div>
                        }
                        {!Sateeee.DobFieldStatus &&
                            <div className="item2 mt-2">
                                <div className="det">
                                    {Sateeee?.DobField == "" || Sateeee?.DobField == null || Sateeee?.DobField == undefined ?
                                        <h6 className="det-tit">Date of Birth</h6>
                                        :
                                        <h6 className="det-tit">{Sateeee.DobField}</h6>
                                    }

                                    <p className="previewname">{moment(startDate).format('D MMMM YYYY')}</p>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="row">
                        <div className="col-7 mt-2">
                            <div className="d-flex">
                                <img src={logoimage} class="logoimage" alt="..." />
                                <div className="ml-2">
                                    <p className="previewname issue mb-1">ISSUED BY</p>
                                    <p className="previewname prevaddr">Clairvoyant Lab (TH)</p>
                                    <p className="previewname prevaddr">Date Of Issue :</p>
                                    <p className="previewname prevaddr">{moment(new Date()).format('D MMMM YYYY')}</p>
                                </div>
                            </div>
                            <div className="d-flex mt-3">
                                {Sateeee?.Chip == "" || Sateeee?.Chip == null || Sateeee?.Chip == undefined ?
                                    <p className="lighttext smalltext">CHIP #</p>
                                    :

                                    <p className="lighttext smalltext">{Sateeee.Chip}</p>
                                }

                                <p className="lighttext smalltext ml-2">{Formdata.chipno}</p>
                            </div>
                        </div>
                        <div className="col-5 text-center mt-2">
                            <QRCode size={70} fgColor="black" bgColor="white"
                                value={Formdata.mail}
                            />
                            <p className="lighttext smalltext mt-2">Scan To Verify and
                                Contact Owner</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function _calculateAge(birthday) { // birthday is a date
        var ageDifMs = Date.now() - birthday?.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate?.getUTCFullYear() - 1970);
    }

    useEffect(() => {
        Backendcalll();
        
        document.getElementsByTagName("body")[0].classList.remove("modal-open");
    }, [cookies.get('TokenIDSCookie')])

    useEffect(() => {
        TokenTopWinner()
    }, [cookies.get('TokenIDSCookie')])

    const Backendcalll = async () => {

    
        const SubDomain = getSubDomain(window.location.hostname)
       
        if (SubDomain !== "") {
            var sendData = {
                domainname: SubDomain
            }
            var calllssss = await GetDomainsAndDetails(sendData)
            
            if (calllssss?.data?.Data == null) {
                // return Apps[0].app
                SetStatewww([])
            } else {
                //return Apps[1].app
                localStorage.setItem("Event", calllssss?.data?.Data.domainname)
                localStorage.setItem("Creator", calllssss?.data?.Data.curraddress)
                SetStatewww(calllssss?.data?.Data)
                var data = {
                    addr: String(calllssss?.data?.Data.curraddress).toLowerCase(),
                    from: "register",
                    email: calllssss?.data?.Data.signemail
                }
                var GetJWT = await AddressUserDetails_GetOrSave_Action(data)
        
                localStorage.setItem('user_token', GetJWT.data.data.token);

            }
        } else {
            SetStatewww([])
        }
    }

    const getSubDomain = (location) => {
        const locationParts = location.split(".")
        let slicefill = -2
        const isLocalhost = locationParts.slice(-1)[0] === "localhost";
        if (isLocalhost) {
            slicefill = -1
        }
        return locationParts.slice(0, slicefill).join("")
    }



    useEffect(() => {
        // if (CookieData) {
        localStorage.setItem("LoginAddress", cookies.get('LoginAddress'))
        localStorage.setItem("LoginAddress1", cookies.get('LoginAddress1'))
        localStorage.setItem("LoginMail", cookies.get('LoginMail'))
        localStorage.setItem("LoginMail1", cookies.get('LoginMail1'))
        localStorage.setItem("Ids", cookies.get('TokenIDSCookie'))

        localStorage.setItem("ADDR", cookies.get('ClaimerCookie'))
        // localStorage.setItem("ADDR", "0x81088cf2289435ce3cb70fa0a6317b92787dc7d9".toLowerCase())
        ClaimedNfts(cookies.get('TokenIDSCookie'), cookies.get('ClaimerCookie'), Sateeee)
        GetConnectedUser(cookies.get('ClaimerCookie'))
    }, [cookies.get('TokenIDSCookie')])

    const TokenTopWinner = async (Addr) => {
        var SendData = {
            EventCreator: localStorage.getItem("Creator"),
            DomainName: localStorage.getItem("Event")
        }
        var Winner = await TopWinners(SendData)
        Setwinner(Winner?.data?.TopWinners)

    }

    const ClaimedNfts = async (IDS, Addr, Sate) => {
        if (Addr) {
            var SendData = {
                claimer: localStorage.getItem("ADDR"),
                QR_tokenID: localStorage.getItem("Ids"),
                EventCreator: localStorage.getItem("Creator"),
                DomainName: localStorage.getItem("Event")
            }
            var UnloackedNfts = await NftsUnloacked(SendData)
            SetUnlocked(UnloackedNfts?.data?.Data)
            SetCookeieeeee(IDS)
        }
    }

    const MatchFun = async (add, unlock) => {
        var filterData = await Sateeee?.NoOfNfts?.map((item, index) => {
            var matchData = add?.filter(it => it.QR_tokenID == item.QR_tokenID)
        })
    }


    const GetConnectedUser = async (Addr) => {
        if (Addr) {
            var SendData = {
                claimer: Addr
                // localStorage.getItem("ADDR"),
            }
            var UserDetail = await CampaignUsers(SendData)
            SetUserDetails(UserDetail?.data?.Data)
        }
    }

    const GetMint = async () => {
        setdisabledState(true)
        Formdata.DateChoose = startDate
        Formdata.ContactNumber = value
        var GetImageData = await DownloadImage()
 
        Formdata.Image = GetImageData
        Formdata.User = localStorage.getItem("Creator")
        // if(localStorage.getItem("Creator")){
        if (Formdata.Terms && Formdata.Privacy) {
            var SendDatass = {
                Address: localStorage.getItem("Creator"),
            }
            var createLimit = await UserPackCheck(SendDatass)
            
            if (createLimit.data.data.Message == "success") {
           
                var SingleLimit = createLimit?.data?.data?.UserBalance?.SingleCount
                var checkverify = SingleLimit
        
                if (Number(checkverify) !== 0) {
                    if (Number(checkverify) < 1) {
                        setdisabledState(false)
                        return toast.error("Minting Limit is Low.Please purchase pack")
                    }
                } else {
                    var mintbal = createLimit?.data?.data?.UserBalance
                    if (mintbal.PurchaseDetails.length > 0) {

                        var EmptyArr = []

                        var mapdata = mintbal.PurchaseDetails.map(async (item, index) => {
                          

                            EmptyArr.push(Number(item.CommonCount))


                        })
                        if (EmptyArr.length > 0) {
                            var sum = Object.values(EmptyArr).reduce((a, b) => a + b, 0)
                          
                            if (sum == 0) {
                                setdisabledState(false)
                                return toast.error("Minting Limit is Low.Please purchase pack")
                            }
                        }
                    } else {
                        setdisabledState(false)
                        return toast.error("Purchase pack to Mint")
                    }
            
                }
            } else {
                setdisabledState(false)
                return toast.error("purchase plan to mint")
            }
            const id2 = toast.loading("Validating Details", { transition: Slide, theme: "dark" });
            var Validations = await ValidCheck(Formdata)

            if (Validations.Success) {
                toast.update(id2, { render: "Validation Success", type: "success", isLoading: false, autoClose: 2500 });
                setValidationError(Validations)
                var IPFSCall = await IPFS(Formdata)
                
                setTimeout(async () => {
                    if (IPFSCall) {
                     
                        const id1 = toast.loading("Creating Your NFT", { transition: Slide, theme: "dark" });
                        var NFTDetailsDB = await NFTDetails2DB(Formdata, Formdata.File, Formdata.User)
                       
                        if (NFTDetailsDB.Success) {

                            toast.update(id1, { render: "NFT Created Successfully", type: "success", isLoading: false, autoClose: 2500 });
                            setNFT(NFTDetailsDB.Details)
                            setFormdata({
                                Breed: "",
                                name: "",
                                weight: "",
                                description: "",
                                chipno: "",
                                height: "",
                                genders: "",
                                vaccine: "",
                                mail: "",
                                ContactNumber: "",
                                Image: "",
                                ID: Date.now(),
                                DateChoose: "",
                                Terms: false,
                                Privacy: false,
                                User: "",
                                Ipfs: "",
                                IpfsImage: "",
                                metajson: "",
                                ImageName: "",
                                additionalImage: "",
                                TokenID: Date.now(),
                                NFTCategory: "Web3 IDs",
                                quantity: 1,
                                Balance: 1,
                                contractaddress: (config.PolygonbulkCon).toLowerCase(),
                                Status: "true",
                                type: 721,
                                HashValue: "not yet minted",
                                NetworkName: config.MATIC_ID,
                                SaleType: "OnlyTransfer",
                                royalties: 1,
                            })
                            setValue("")
                            setImagename("Select File")
                            setSelectedImage(petimage)
                            setdisabledState(false)
                            // window.location.reload()
                        } else {
                            setdisabledState(false)
                            toast.update(id1, { render: "Failed to Create Your NFT. Please TryIt Again Later And Check Your Pack", type: "error", isLoading: false, autoClose: 2500 });
                        }
                    }
                }, 1000);
            } else {
                setdisabledState(false)
                toast.update(id2, { render: "Validation Failed", type: "error", isLoading: false, autoClose: 2500 });
                setValidationError(Validations)
            }

        } else {
            setdisabledState(false)
            toast.error("Check the Terms and Privacy")
        }
    // }else{
    //     toast.error("Please LogOut and Login Again In Lazyminter.com")
    // }
    }

    const IPFS = async (Data) => {
        const id = toast.loading("Generating IPFS Data");
        var result = await ipfsImageHashGet(Data, Data.Image, Data.User);
       
        setTimeout(() => {
            if (result.Success) {
                toast.update(id, { render: result.Message, type: "success", isLoading: false, autoClose: 2500 });
                
                Formdata.Ipfs = `${"https://lazyminter.infura-ipfs.io/ipfs"}/${result.data.ipfsmetadata}`
                Formdata.IpfsImage = result.data.ipfsimage,
                    Formdata.metajson = result.data.MetFileName,
                    Formdata.ImageName = result.data.ImageName,
                    Formdata.additionalImage = result.data.additionalImage
                return {
                    Ipfs: `${"https://lazyminter.infura-ipfs.io/ipfs"}/${result.data.ipfsmetadata}`, IpfsImage: result.data.ipfsimage, metajson: result.data.MetFileName, ImageName: result.data.ImageName, additionalImage: result.data.additionalImage, Success: result.Success
                }
            } else {
                setdisabledState(false)
                toast.update(id, { render: result.Message, type: "error", isLoading: false, autoClose: 2500 });
            }
        }, 1000);
        return result.Success
    };

    const ValidCheck = async (data, Onsale, Saletype) => {
    
        var validationerr = {}
        var DBnamecheck = null
        let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;

        var arr = ['name', 'description', 'mail', "ContactNumber"]

        for (const property in data) {
            if (arr.includes(property) && isEmpty(data[property])) {
                validationerr[property] = `${property} validation failed`
            }
            if (property === 'Image' && data[property] === null)
                validationerr[property] = `${property} is Required`
        }

        if (data.mail !== "") {
            if (!emailRegex.test(data.mail)) { validationerr["mail"] = "Enter valid email" }
        }

       
        if (isEmpty(validationerr)) {
            DBnamecheck = await NFTnameValidation(data.name)
     
            if (!(DBnamecheck.Success)) {
                validationerr.name = DBnamecheck.Message
                validationerr.Success = DBnamecheck.Success
                return validationerr
            }
            else {
                validationerr.Success = DBnamecheck.Success
                return validationerr
            }
        }
        else {
            validationerr.Success = false
            return validationerr
        }
    }


    const DownloadImage = async () => {
      
      
        if (componentRef.current) {
            console.log("componentRef.current" , componentRef.current)
           // var canvas = await html2canvas(componentRef.current)
            // var canvas = await html2canvas(componentRef.current, {
            //     scale: window.devicePixelRatio || 1,
            //     useCORS: true,
            //     backgroundColor: null,
            // });
            let canvas = null
    if(windowWidth > 767){
            canvas = await html2canvas(componentRef.current)
        }else{
            canvas = await html2canvas(document.querySelector("#capture")).then(canvas => {
                console.log("asbdkjashdkjas",canvas);
                document.body.appendChild(canvas)
return canvas
            });
        }
            // var canvas = await html2canvas(componentRef.current, {
            //     scale: 2, // Increase the scale to improve mobile rendering
            //     useCORS: true, // Enable cross-origin resource sharing
            //     backgroundColor: null, // Handle transparent backgrounds
            //     logging: true // Enable logging to see errors in mobile console
            // });
            console.log("dhashdkashdasda",canvas);
            
            if (canvas) {
                const dataURL = await canvas.toDataURL('image/png');
                console.log("sdfsfdsfsdf",dataURL);
                var fileDataCon = await dataURLtoFile(dataURL, 'name.png');
         
                return fileDataCon
            }
        }
    };


    const ImageValidation = (file) => {

        var validExtensions = ["png", "webp", "jpeg", "PNG", "jpg", "JPEG", "JPG"];
        var fileName = file.name;
        var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
        if ($.inArray(fileNameExt, validExtensions) == -1)
            return { Success: false, Message: "Only these file types are accepted : " + validExtensions.join(", ") };
        if (30000000 < file.size)
            return { Success: false, Message: "File size exceeds 30 MB" };
        else
            return { Success: true }
    }

    const CheckBoxSelect = (e) => {
        const { id, value } = e.target;
        let formData = { ...Formdata, ...{ [id]: e.target.checked } }

        setFormdata(formData)
    }

    const handleKeyDown = (e) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault();
        }
    };
    const [error, setError] = useState('');

    const handlePhoneNumberChange = (value) => {
        // Check if the input is a possible phone number
        if (!value || isPossiblePhoneNumber(value)) {
            setValue(value);
            setError('');
        } else {
            setError('Invalid phone number');
        }
    };
    return (
        <>
            <div className='home_header home header_bar_align subdomain_des prev_sub margins tempflow' 
            // style={{ backgroundColor: Sateeee.backgroundcolor }}
            >


                <div className="container template_contain pb-5">

                <div className="text-right pt-3 mb-3">
                {localStorage.getItem("ADDR") ?

<a> <button className="btn btn-success" >Connected</button></a>
  : 
<a href="https://lazyminter.com/" target="_blank"> <button className="create_btn">Connect</button></a>
}
              </div>   

                    <div className="tempnew d-flex justify-content-between mb-4">
                       
                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                            <img className='subd_logo' src={require('../assets/images/faces/logo.png')} />
                            :
                            <img className='subd_logo' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                        }
                        <div className="temptheme">
                            {localStorage.getItem("theme1") == "LT" || localStorage.getItem("theme1") == undefined ?
                                <Button onClick={() => { themechange("DT") }}>
                                    <p className="light svg_lft_align"><Brightness3 /></p>
                                </Button> :
                                <Button onClick={() => { themechange("LT") }}>
                                    <p className="dark svg_lft_align"><WbSunny /></p>
                                </Button>}
                        </div>
                    </div>

               

                    {/* Mobile */}
                    <div className="mobilelist">
                        <div className="mobilefullimage">
                            {/* <img src={selectedImage} class="subimage w-100" alt="..." />
                            {<AiOutlineHeart className="heartcolor" />
                                //  <AiFillHeart className="heartcolor"/>
                            } */}
                            {/* <PreviewComp/> */}
                            <div className="col-md-12 col-lg-5 col-xl-4 mt-3 padsizes">
                           <div className="width_sets">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 class="  text-center mb-0">Dynamic Preview <FaEye className="eyess" /></h4>
                                <FaDownload className="fadownlod" onClick={handleCapture} />

                            </div>

                            <PreviewComp />
                            </div>
                        </div>

                            {Sateeee?.bannerTittle == "" || Sateeee?.bannerTittle == null || Sateeee?.bannerTittle == undefined ?
                                <h4 class="subd_jointr_ttl mobiledesign  text-center">Design Your Pet’s Passport Here and Collect As NFT</h4>
                                :

                                <h3 className='subd_jointr_ttl mobiledesign  text-center'>{Sateeee?.bannerTittle}</h3>
                            }

                        </div>
                    </div>



                    <div className="row pt-3 flexlist align-items-center justify-content-between">
                        <div className="col-md-12 col-lg-7 col-xl-7  mt-3">
                            {Sateeee?.bannerTittle == "" || Sateeee?.bannerTittle == null || Sateeee?.bannerTittle == undefined ?
                                <h4 class="subd_jointr_ttl ftitle mb-4  text-center">Design Your Pet’s Passport Here and Collect As NFT</h4>
                                :

                                <h3 className='subd_jointr_ttl ftitle  text-center'>{Sateeee?.bannerTittle}</h3>
                            }

                       
                            <div className="row pt-3">
                              
                                <div className="col-sm-6 col-md-6 mt-3">
                                    <div class="form-group">
                                       
                                        {Sateeee?.NameField == "" || Sateeee?.NameField == null || Sateeee?.NameField == undefined ?
                                            <label for="exampleInputEmail1">Name of Pet *</label>
                                            :
                                            <label for="exampleInputEmail1">{Sateeee?.NameField}</label>
                                        }

                                        <input type="text" class="form-control" placeholder='Patrick' id="name" onChange={OnChangehandle} />
                                        <p style={{ color:"red", fontSize:"14px" }} className="mb-0">Maximum 16 chracters</p>

                                    </div>
                                    {ValidationError && ValidationError.name &&
                                        <p className="Warning_label">{ValidationError && ValidationError.name}</p>
                                    }
                                </div>
                              
                                {!Sateeee?.DiscriptionStatus &&
                                    <div className="col-sm-6 col-md-6 mt-3">
                                        <div class="form-group">
                                            {Sateeee?.Discription == "" || Sateeee?.Discription == null || Sateeee?.Discription == undefined ?
                                                <label for="exampleInputEmail1">Likes* (7 words to describe)</label>
                                                :
                                                <label for="exampleInputEmail1">{Sateeee?.Discription}</label>
                                            }

                                            <input type="text" class="form-control" id="description" value={Formdata.description} onChange={OnChangehandle} />
                                            <p style={{ color:"red", fontSize:"14px" }} className="mb-0">Maximum 30 words</p>
                                        </div>
                                        {ValidationError && ValidationError.description &&
                                            <p className="Warning_label">{ValidationError && ValidationError.description}</p>
                                        }
                                    </div>
                                }
                                {!Sateeee?.DobFieldStatus &&
                                    <div className="col-sm-6 col-md-6 mt-2">
                                        {Sateeee?.DobField == "" || Sateeee?.DobField == null || Sateeee?.DobField == undefined ?
                                            <label for="exampleInputEmail1">Date of Birth*</label>
                                            :
                                            <label for="exampleInputEmail1">{Sateeee?.DobField}</label>
                                        }

                                        <div>  <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="dd/MM/yyyy" /></div>

                                    </div>
                                }
                                {!Sateeee?.GenderStatus &&
                                    <div className="col-sm-6 col-md-6 mt-2">
                                        {Sateeee?.GenderDropDown == "" || Sateeee?.GenderDropDown == null || Sateeee?.GenderDropDown == undefined ?
                                            <label for="exampleInputEmail1">Gender*</label>
                                            :
                                            <label for="exampleInputEmail1">{Sateeee?.GenderDropDown}</label>
                                        }

                                        <div>
                                   
                                            <Dropdown className="allselectmenu" onSelect={handleSelect}>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    {Formdata.genders ? Formdata.genders : ""}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>

                                                    {Sateeee && Sateeee?.Dropdown1Category?.length > 0 &&
                                                        Sateeee?.Dropdown1Category?.map((item, index) => {
                                                            return (
                                                                <Dropdown.Item id="genders" eventKey={item.value} >{item.value}</Dropdown.Item>
                                                            )
                                                        })

                                                    }

                                                </Dropdown.Menu>

                                            </Dropdown>
                                        </div>
                                    </div>
                                }
                               
                                {((Sateeee?.WeightStatus !== true || Sateeee?.WeightStatus !== "true") && (Sateeee?.HeightStatus !== true || Sateeee?.HeightStatus !== "true")) &&
                                    <div className="col-sm-6 col-md-6 mt-3">
                                        <div className="row pt-0">
                                            {!Sateeee?.WeightStatus &&
                                                <div className="col-6 col-sm-6 col-md-6 mt-0">
                                                    {Sateeee?.Weight == "" || Sateeee?.Weight == null || Sateeee?.Weight == undefined ?
                                                        <label for="exampleInputEmail1">Weight *</label>
                                                        :
                                                        <label for="exampleInputEmail1">{Sateeee?.Weight}</label>
                                                    }

                                                    <div class="input-group mb-0">

                                                        <input type="number" id="weight" value={Formdata.weight} onChange={OnChangehandle} class="form-control" onKeyDown={handleKeyDown}
                                                            onWheel={(e) => e.target.blur()} />
                                                   
                                                    </div>
                                                </div>
                                            }
                                            {!Sateeee?.HeightStatus &&
                                                <div className="col-6 col-sm-6 col-md-6 mt-0">
                                                    {Sateeee?.Height == "" || Sateeee?.Height == null || Sateeee?.Height == undefined ?
                                                        <label for="exampleInputEmail1">Height *</label>
                                                        :
                                                        <label for="exampleInputEmail1">{Sateeee?.Height}</label>
                                                    }

                                                    <div class="input-group mb-0">

                                                        <input type="number" value={Formdata.height} name="height" id="height" onChange={OnChangehandle} class="form-control" onKeyDown={handleKeyDown}
                                                            onWheel={(e) => e.target.blur()} />
                                                    
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {!Sateeee?.BreadStatus &&
                                    <div className="col-md-6 col-sm-6 mt-3">
                                        {Sateeee?.BreadDropDown == "" || Sateeee?.BreadDropDown == null || Sateeee?.BreadDropDown == undefined ?
                                            <label for="exampleInputEmail1">Breed *</label>
                                            :
                                            <label for="exampleInputEmail1">{Sateeee?.BreadDropDown}</label>
                                        }


                            

                                        <Dropdown className="allselectmenu" onSelect={handleSelect}>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                {Formdata?.Breed ? Formdata?.Breed : ""}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {Sateeee && Sateeee?.Dropdown2Category?.length > 0 &&
                                                    Sateeee?.Dropdown2Category?.map((item, index) => {
                                                        return (
                                                            <Dropdown.Item id="Breed" eventKey={item?.value} >{item?.value}</Dropdown.Item>
                                                        )
                                                    })

                                                }

                                     
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div>
                                }
                                {!Sateeee?.VaccineStatus &&
                                    <div className="col-sm-6 col-md-6 mt-3">
                                        {Sateeee?.VaccineField == "" || Sateeee?.VaccineField == null || Sateeee?.VaccineField == undefined ?
                                            <label for="exampleInputEmail1">Vaccine *</label>
                                            :
                                            <label for="exampleInputEmail1">{Sateeee?.VaccineField}</label>
                                        }

                                        <div>

                                            <Dropdown className="allselectmenu" onSelect={handleSelect}>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    {Formdata.vaccine ? Formdata.vaccine : ""}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {Sateeee && Sateeee?.Dropdown3Category?.length > 0 &&
                                                        Sateeee?.Dropdown3Category?.map((item, index) => {
                                                            return (
                                                                <Dropdown.Item id="vaccine" eventKey={item?.value} >{item?.value}</Dropdown.Item>
                                                            )
                                                        })

                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                }
                                {!Sateeee?.ChipStatus &&
                                    <div className="col-sm-6 col-md-6 mt-3">
                                        <div class="form-group">
                                            {Sateeee?.Chip == "" || Sateeee?.Chip == null || Sateeee?.Chip == undefined ?
                                                <label for="exampleInputEmail1">Chip # (Optional)</label>
                                                :
                                                <label for="exampleInputEmail1">{Sateeee?.Chip}</label>
                                            }

                                            <input type="text" class="form-control" placeholder="Ex: 977200000000000" value={Formdata.chipno} id="chipno" onChange={OnChangehandle} onKeyDown={handleKeyDown}
                                                onWheel={(e) => e.target.blur()} />
                                        </div>
                                    </div>
                                }

                            </div>

                            <div className="row pt-0">
                                {!Sateeee?.MailStatus &&
                                    <div className="col-sm-6 col-md-6 mt-2">
                                        <div class="form-group">
                                            {Sateeee?.Mail == "" || Sateeee?.Mail == null || Sateeee?.Mail == undefined ?
                                                <label for="exampleInputEmail1">Owner Contact Email *</label>
                                                :
                                                <label for="exampleInputEmail1">{Sateeee?.Mail}</label>
                                            }

                                            <input type="email" class="form-control"
                                                id="mail" value={Formdata.mail} placeholder="drchinp@gmail.com" onChange={OnChangehandle} />
                                        </div>
                                        {ValidationError && ValidationError.mail &&
                                            <p className="Warning_label">{ValidationError && ValidationError.mail}</p>
                                        }
                                    </div>
                                }
                                {!Sateeee?.ContactNumStatus &&
                                    <div className="col-sm-6 col-md-6 mt-2">
                                        {Sateeee?.ContactNum == "" || Sateeee?.ContactNum == null || Sateeee?.ContactNum == undefined ?
                                            <label for="exampleInputEmail1">Mobile Number *</label>
                                            :
                                            <label for="exampleInputEmail1">{Sateeee?.ContactNum}</label>
                                        }

                                        <div>
                                            {/* <PhoneInput
                                                international
                                                defaultCountry="TH"
                                                value={Formdata.ContactNumber}
                                                onChange={handlePhoneNumberChange} 
                                                /> */}
                                                <PhoneInput
                                        countryCodeEditable={false}
                                        placeholder="Enter phone number"
                                        country={"th"}
                                        onChange={(value, event) => {
                                          const { dialCode } = event;
                                          let newPhoneNo = value;
                                          console.log(
                                            "PHONE INPUTS",
                                            value,
                                            event
                                          );
                                          setValue("+"+ dialCode +" "+value.slice(dialCode.length))
                                        }}
                                        // disabled={isVerified}
                                        autoFormat={true}
                                      />
                                                
                                        </div>
                                        {ValidationError && ValidationError.ContactNumber &&
                                            <p className="Warning_label">{ValidationError && ValidationError.ContactNumber}</p>
                                        }
                                    </div>
                                }
                                {!Sateeee?.FileStatus &&
                                    <div className="col-sm-6 col-md-6 mt-3">
                                        {Sateeee?.FileField == "" || Sateeee?.FileField == null || Sateeee?.FileField == undefined ?
                                            <label for="exampleInputEmail1" className="mb-0">Your Pet’s Photo *</label>
                                            :
                                            <label for="exampleInputEmail1">{Sateeee?.FileField}</label>
                                        }

                                        <div>
                                            {Sateeee?.FileDiscriptionField == "" || Sateeee?.FileDiscriptionField == null || Sateeee?.FileDiscriptionField == undefined ?
                                        
                                              <label>Please Ensure a Front Facing Photo<br></br><span style={{ color: "red" }}> Image Dimension (jpg, jpeg, png (320 X  320)) </span>  </label>
                                                                                
                                                :
                                                <label for="exampleInputEmail1">{Sateeee?.FileDiscriptionField}</label>
                                            }

                                        </div>
                                    </div>
                                }
                                <div className="col-sm-6 col-md-6 mt-3">
                                    <div className="row">
                                        <div className="col-md-6 mt-2"> <p className="uploadname overflow-hidden">
                                        
                                            {imagename}
                                        </p> </div>
                                        <div className="col-md-6 mt-2 browsebtn">
                                      
                                            <input type="file" name="file" className="uploadss " ref={fileInputRef} onChange={handleFilesSelected} />
                                            <button className='subd_clctnowbtn' onClick={handleFileUpload}>Upload</button>
                                         
                                        </div>
                                        {ValidationError && ValidationError.Image &&
                                            <p className="Warning_label">{ValidationError && ValidationError.Image}</p>
                                        }
                                    </div>
                                    <span  style={{ color: "red", fontSize:"14px" }}>Image size (320 * 320) </span>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="allcheck">
                                 
                                        <input class="form-check-input" type="checkbox" id="Terms" onClick={(e) => CheckBoxSelect(e)} />
                                  
                                        {Sateeee?.CheckBox1 == "" || Sateeee?.CheckBox1 == null || Sateeee?.CheckBox1 == undefined ?
                                            <label class="form-check-label" for="defaultCheck1">
                                                I agree to all statements included in
                                                Terms of Conditions and Use
                                            </label>
                                            :
                                            <label class="form-check-label dataleft" for="defaultCheck1">{Sateeee?.CheckBox1}</label>
                                        }

                                    </div>
                                    <div className="allcheck">
                                
                                        <input class="form-check-input" id="Privacy" type="checkbox" onClick={(e) => CheckBoxSelect(e)} />
                                   

                                        {Sateeee?.CheckBox2 == "" || Sateeee?.CheckBox2 == null || Sateeee?.CheckBox2 == undefined ?
                                            <label class="form-check-label" for="defaultCheck1">
                                                I want free 30 days coverage for my pet
                                                Insurance Coverage Details and Conditions
                                            </label>
                                            :
                                            <label className="dataleft" for="exampleInputEmail1">{Sateeee?.CheckBox2}</label>
                                        }

                                    </div>

                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="row">
                                        {/* <div className="col-6 prevv mb-2"><button className='subd_clctnowbtn' onClick={handleShow}>Preview</button></div> */}
                                        <div className="col-6 col-sm-12 col-md-12 mb-2"><button className='subd_clctnowbtn' 
                                        // onClick={async() => await GetMint()}
                                        onClick={() => GetMint()}
                                        disabled={disabledState}
                                        >Submit</button></div>
                                    </div>
                                    {/* <button className='subd_clctnowbtn'>Submit</button> */}
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-sm-10 col-md-8 col-lg-5 col-xl-4 ftitle mx-auto mt-3 padsizes">
                         <div className="width_sets">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 class="subd_jointr_ttl previewtext  text-center mb-0">Dynamic Preview <FaEye className="eyess" /></h4>
                                <FaDownload className="fadownlod" onClick={handleCapture} />

                            </div>

                            <PreviewComp />
</div>
                        </div>

                        <div className="">
                            <Modal show={show} onHide={handleClose} className="mobilemodal">
                                <Modal.Header>
                                    <Modal.Title><GrFormClose onClick={handleClose} /></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="d-flex justify-content-between mt-3 px-2">
                                        <h4 class="subd_jointr_ttl previewtext  text-center">Dynamic Preview <FaEye className="eyess" /></h4>
                                        <FaDownload className="fadownlod" onClick={handleCapture} />

                                    </div>

                                    <PreviewComp />
                                </Modal.Body>

                            </Modal>
                        </div>

                    </div>

                </div>

                <div className="container pt-5 pb-4">
                    {Sateeee?.middlebannertittle == "" || Sateeee?.middlebannertittle == null || Sateeee?.middlebannertittle == undefined ?
                        <h4 class="subd_jointr_ttl  text-center">Congratulations!!<br></br>
                        </h4>
                        :
                        <h4 className='subd_jointr_ttl  text-center'>{Sateeee?.middlebannertittle}<br></br></h4>
                    }
               
                    {Sateeee?.middlebannerdescription == "" || Sateeee?.middlebannerdescription == null || Sateeee?.middlebannerdescription == undefined ?
                        <h4 class="subd_jointr_ttl  text-center">
                            Click or Scan the QR Code to Drop Your Pet Passport token Into Your Wallet Now...</h4>
                        :
                        <h4 className='subd_jointr_ttl  text-center'>{ReactHTMLParser(Sateeee.middlebannerdescription)}</h4>
                    }


                    <div className="subd_nointrcontbg" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                        <div className="row align-items-center">
                       
                            <div className="col-md-5 text-center mt-2">
                            <a href={NFT ? `${config.Front_URL}/info/${NFT.contractAddress}/${NFT.tokenOwner}/${NFT.tokenID}` : "https://lazyminter.com"} target="_blank" >
                                <QRCode size={200} fgColor="black" bgColor="white" value={NFT ? `${config.Front_URL}/info/${NFT.contractAddress}/${NFT.tokenOwner}/${NFT.tokenID}` : "https://lazyminter.com"} />
                                </a>


                                <h4 class="subd_jointr_ttl mt-3">Click or Scan</h4>
                                <h4 class="subd_jointr_ttl mt-3r">Now</h4>
                            </div>
                         
                            <div className="col-md-7 text-center mt-2">

                                {Sateeee?.middlebannerdescriptionTittle == "" || Sateeee?.middlebannerdescriptionTittle == null || Sateeee?.middlebannerdescriptionTittle == undefined ?
                                    <h5 class="subd_jointr_cntttl ">Collect Your Pet Passport Guide</h5>
                                    :
                                    <h5 className='subd_jointr_cntttl'>{Sateeee.middlebannerdescriptionTittle}</h5>

                                }

                                {Sateeee?.middlebannerdetails == "" || Sateeee?.middlebannerdetails == null || Sateeee?.middlebannerdetails == undefined ?
                                    <>


                                        <p className="subtit mt-3">1. Register an Account at LazyMinter if You Don’t Have One
                                        </p>
                                        <p className="white-sub mt-3">Register an account at LazyMinter with the same email you had registered for your pet passport.
                                        </p>
                                        <p className="subtit mt-3">2. Create Your Wallet (Recommend LM (Lazyminter) Wallet) or Use Your Existing
                                        </p>
                                        <p className="white-sub mt-3">You have an option to use a custodial wallet (Recommend LM (Lazyminter) Wallet) or your own metamask wallet.
                                        </p>
                                        <p className="subtit mt-3">3. Check your Pet Passport Token and Click ‘Claim’

                                        </p>
                                        <p className="white-sub mt-3">Please be patient as we are writing the digital passport on blockchain and will airdrop into your wallet.  Check under my items and VOILA, now you can use the pet passport for other benefits
                                        </p>
                                    </>
                                    :
                                    <p className='subd_jointr_cnthint'>{ReactHTMLParser(Sateeee?.middlebannerdetails)}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container pt-5">

                    {Sateeee?.categoryTittle == "" || Sateeee?.categoryTittle == null || Sateeee?.categoryTittle == undefined ?
                        <h4 class="subd_jointr_ttl  text-center pb-4">Check out Other Rewards Awaiting
                            For your Pets</h4>
                        :
                        <h5 className='subd_jointr_ttl  text-center pb-4'>{Sateeee.categoryTittle}</h5>

                    }
                    {/* WEB */}
                    <div className="ftitle">
                        {Sateeee?.NoOfNfts?.length > 0 ?
                            <div className='row'>
                                {Sateeee.NoOfNfts.length > 0 &&
                                    Sateeee.NoOfNfts.map((item, index) => {
                                        return (

                                            <div id='flowing_tocard' className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12'>
                                                <div class="card sub_domaincard" style={{ backgroundColor: Sateeee.backgroundcolor }} id={unlocaked?.some(it => it.QR_tokenID == item.QR_tokenID) == true ? "" : "blur_card"} >
                                                    <div className='blur_effect'></div>
                                                    <div className='blur_card_style'></div>
                                                    <div className='sub_domaincard_rel'>

                                                    </div>
                                          
                                                    {item.image != "" ?
                                                        <>
                                                            {item.image != "" &&
                                                                (item.image.split(".").pop() == "mp4" ? (
                                                                    <video
                                                                        id="my-video"
                                                                        class="card-img-top subdomain_cardimg"
                                                                        autoPlay
                                                                        playsInline
                                                                        loop
                                                                        muted
                                                                        preload="auto"
                                                                        controls disablePictureInPicture controlsList="nodownload"
                                                                    >
                                                                        <source src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`} type="video/mp4" id={unlocaked?.some(it => it.QR_tokenID == item.QR_tokenID) == true ? "" : "blur_card"} />
                                                                    </video>
                                                                ) : (
                                                                    <img src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`} alt="Collections" className="card-img-top subdomain_cardimg" id={unlocaked?.some(it => it.QR_tokenID == item.QR_tokenID) == true ? "" : "blur_card"} />
                                                                ))}

                                                        </>
                                                        :
                                                        <>
                                                            <ThreeDots
                                                                height="70"
                                                                width="70"
                                                                radius="9"
                                                                color="#FFC700"
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClassName=""
                                                                visible={true}
                                                            />
                                                        </>
                                                    }

                                                    <div class="card-body">
                                                        <div className='subd_cardwhole'>
                                                            <div>

                                                                <h6 className='wekit_aligned'>{item?.tokenName}</h6>

                                                            </div>
                                                            <div>
                                                                {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                                    <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} />

                                                                    :
                                                                    <img className='subd_cardsublogo' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                                }
                                                            </div>
                                                        </div>

                                                        <button className='subd_clctnowbtn'>{unlocaked?.some(it => it.QR_tokenID == item.QR_tokenID) == true ? "unlocked" : "locked"}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    })
                                }
                            </div>
                            :
                            <>

                                <ThreeDots
                                    height="70"
                                    width="70"
                                    radius="9"
                                    color="#FFC700"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />

                            </>
                        }
                    </div>
                    {/* Mobile */}
                    <div className="prevv">
                        <Swiper style={{ overflow: "hidden" }} pagination={true} modules={[Autoplay, Pagination, Navigation]} autoplay={{
                            delay: 1500,
                            disableOnInteraction: false,
                        }}
                            slidesPerView={1}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1.2,
                                },
                                576: {
                                    slidesPerView: 1.2,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                992: {
                                    slidesPerView: 3,
                                },
                                1200: {
                                    slidesPerView: 4,
                                },
                            }}
                            spaceBetween={20} className="mySwiper subd_cardswipe mt-5">
                            {Sateeee?.NoOfNfts?.length > 0 ?
                                <>
                                    {Sateeee?.NoOfNfts?.length > 0 &&
                                        Sateeee?.NoOfNfts.map((item, index) => {
                                            return (
                                                <SwiperSlide>
                                                    <div class="card sub_domaincard height_subdomaincard" style={{ backgroundColor: Sateeee.backgroundcolor }} id={unlocaked?.some(it => it.QR_tokenID == item.QR_tokenID) == true ? "" : "blur_card"}>
                                                        <div className='blur_effect'></div>
                                                        {item.image != "" ?
                                                            <>
                                                                {item.image != "" &&
                                                                    (item.image.split(".").pop() == "mp4" ? (
                                                                        <video
                                                                            id="my-video"
                                                                            class="card-img-top subdomain_cardimg"
                                                                            autoPlay
                                                                            playsInline
                                                                            loop
                                                                            muted
                                                                            preload="auto"
                                                                            controls disablePictureInPicture controlsList="nodownload"
                                                                        >
                                                                            <source src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`} type="video/mp4" />
                                                                        </video>
                                                                    ) : (
                                                                        <img src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`} alt="Collections" className="card-img-top subdomain_cardimg" />
                                                                    ))}

                                                            </>
                                                            :
                                                            <>
                                                                <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />

                                                            </>
                                                        }
                                                        <div class="card-body">
                                                            <div className='subd_cardwhole'>
                                                                <div>
                                                                    <h6>{item?.tokenName}</h6>
                                                                
                                                                </div>
                                                                {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                                    <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} />

                                                                    :
                                                                    <img className='subd_cardsublogo' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                                }
                                                                {/* <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} /> */}
                                                            </div>

                                                            <button className='subd_clctnowbtn'>{unlocaked?.some(it => it.QR_tokenID == item.QR_tokenID) == true ? "unlocked" : "locked"}</button>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>

                                            )
                                        })
                                    }
                                </>
                                :
                                <>
                                    <ThreeDots
                                        height="70"
                                        width="70"
                                        radius="9"
                                        color="#FFC700"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
      
                                </>
                            }


                        </Swiper>
                    </div>

                </div>

                <div className="container pt-5 pb-4">
                    {Sateeee?.TopWinnerTittle == "" || Sateeee?.TopWinnerTittle == null || Sateeee?.TopWinnerTittle == undefined ?
                        <h4 class="subd_jointr_ttl  text-center pb-3">Top Pets Showcase this week</h4>
                        :

                        <h3 className='subd_jointr_ttl  text-center pb-3'>{Sateeee?.TopWinnerTittle}</h3>
                    }

                    {/* WEB */}
                    <div className="ftitle">
                        {winner && winner?.length > 0 ?


                            <div className='row subd_winnercardrow'>
                                {winner && winner?.length > 0 &&
                                    winner.map((Data, index) => {
                                        return (


                                            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                                                <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                                    <div class="card-body">
                                                        <div className='subd_winnercard_whole'>
                                                            <div className='d-flex justify-content-start align-items-center'>
                                                                {Data?.image == "" || Data?.image == null || Data?.image == undefined ?
                                                                    <>
                                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />

                                                                            :
                                                                            <img className='subd_logo' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                                        }
                                                                    </>
                                                                    :
                                                                    <img className='subd_logo' src={`${config.Back_URL}/Users/${Data?.PrimaryAddr}/profileImg/${Data?.image}`} />
                                                                }

                                                                <div className='ml-3'>

                                                                    <p className='subd_universal'>{Data?.name == "" ? Data.PrimaryAddr?.slice(0, 20) : Data?.name?.slice(0, 20)}</p>
                                         
                                                                </div>
                                                            </div>
                                                          

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :

                            <div className='row subd_winnercardrow'>
                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                                                                    <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                        <div class="card-body">
                                            <div className='subd_winnercard_whole'>
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                        <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                        :
                                                        <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                    }
                                                    <div className='ml-3'>
                                                        {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                            <p className='subd_universal'>Tea Factory</p>
                                                            :
                                                            <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                        }
                             
                                                    </div>
                                                </div>
                                             

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                                                                    <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                        <div class="card-body">
                                            <div className='subd_winnercard_whole'>
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                        <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                        :
                                                        <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                    }
                                                    <div className='ml-3'>
                                                        {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                            <p className='subd_universal'>Tea Factory</p>
                                                            :
                                                            <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                        }
                                
                                                    </div>
                                                </div>
                                              

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                                                                    <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                        <div class="card-body">
                                            <div className='subd_winnercard_whole'>
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                        <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                        :
                                                        <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                    }
                                                    <div className='ml-3'>
                                                        {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                            <p className='subd_universal'>Tea Factory</p>
                                                            :
                                                            <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                        }
                                   
                                                    </div>
                                                </div>
                                           

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                                                                    <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                        <div class="card-body">
                                            <div className='subd_winnercard_whole'>
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                        <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                        :
                                                        <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                    }
                                                    <div className='ml-3'>
                                                        {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                            <p className='subd_universal'>Tea Factory</p>
                                                            :
                                                            <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                        }
                             
                                                    </div>
                                                </div>
                                             

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                                                                    <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                        <div class="card-body">
                                            <div className='subd_winnercard_whole'>
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                        <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                        :
                                                        <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                    }
                                                    <div className='ml-3'>
                                                        {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                            <p className='subd_universal'>Tea Factory</p>
                                                            :
                                                            <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                        }
                              
                                                    </div>
                                                </div>
                                        
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                                                                    <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                        <div class="card-body">
                                            <div className='subd_winnercard_whole'>
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                        <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                        :
                                                        <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                    }
                                                    <div className='ml-3'>
                                                        {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                            <p className='subd_universal'>Tea Factory</p>
                                                            :
                                                            <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                        }
                             
                                                    </div>
                                                </div>
                                      

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                                                                    <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                        <div class="card-body">
                                            <div className='subd_winnercard_whole'>
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                        <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                        :
                                                        <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                    }
                                                    <div className='ml-3'>
                                                        {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                            <p className='subd_universal'>Tea Factory</p>
                                                            :
                                                            <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                        }
                              
                                                    </div>
                                                </div>
                                              

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                                                                    <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                        <div class="card-body">
                                            <div className='subd_winnercard_whole'>
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                        <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                        :
                                                        <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                    }
                                                    <div className='ml-3'>
                                                        {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                            <p className='subd_universal'>Tea Factory</p>
                                                            :
                                                            <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                        }

                                                    </div>
                                                </div>
                                             
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                                                                    <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                        <div class="card-body">
                                            <div className='subd_winnercard_whole'>
                                                <div className='d-flex justify-content-start align-items-center'>
                                                    {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                        <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                        :
                                                        <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                    }
                                                    <div className='ml-3'>
                                                        {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                            <p className='subd_universal'>Tea Factory</p>
                                                            :
                                                            <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                        }

                                                    </div>
                                                </div>
                                    

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>
                    {/* Mobile */}
                    <div className="prevv">
                        <Swiper style={{ overflow: "hidden" }} loop={true} pagination={true} modules={[Autoplay, Pagination, Navigation]} autoplay={{
                            delay: 1500,
                            disableOnInteraction: false,

                        }}
                            slidesPerView={1}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },
                                576: {
                                    slidesPerView: 1,
                                }
                            }}
                            spaceBetween={20} className="mySwiper subd_lengthswipe my-5">
                      
                            {winner && winner?.length > 0 ?
                                <>
                                    {winner && winner?.length > 0 &&
                                        <SwiperSlide>
                                            {winner && winner?.length > 0 &&
                                                                                <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                                    <div class="card-body">
                                                        <div className='subd_winnercard_whole'>
                                                            <div className='d-flex justify-content-start align-items-center'>
                                                                {winner[0]?.image == "" || winner[0]?.image == null || winner[0]?.image == undefined ?
                                                                    <>
                                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />

                                                                            :
                                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                                        }
                                                                    </>
                                                                    :
                                                                    <img className='subd_cardsublogoal' src={`${config.Back_URL}/Users/${winner[0]?.PrimaryAddr}/profileImg/${winner[0]?.image}`} />
                                                                }


                                                                <div className='ml-3'>
                                                                    <p className='subd_universal'>{winner[0]?.name && winner[0]?.name?.slice(0, 20)}</p>
                                                                    <p className='subd_universal'>{winner[0]?.PrimaryAddr && winner[0]?.PrimaryAddr?.slice(0, 20)}</p>

                                                                </div>
                                                            </div>
                                                    

                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                            {winner && winner?.length > 1 &&
                                                                                <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                                    <div class="card-body">
                                                        <div className='subd_winnercard_whole'>
                                                            <div className='d-flex justify-content-start align-items-center'>
                                                                {winner[1]?.image == "" || winner[1]?.image == null || winner[1]?.image == undefined ?
                                                                    <>
                                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />

                                                                            :
                                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                                        }
                                                                    </>
                                                                    :
                                                                    <img className='subd_cardsublogoal' src={`${config.Back_URL}/Users/${winner[1]?.PrimaryAddr}/profileImg/${winner[1]?.image}`} />
                                                                }


                                                                <div className='ml-3'>
                                                                    <p className='subd_universal'>{winner[1]?.name && winner[1]?.name?.slice(0, 20)}</p>
                                                                    <p className='subd_universal'>{winner[1]?.PrimaryAddr && winner[1]?.PrimaryAddr?.slice(0, 20)}</p>

                                                                </div>
                                                            </div>
                                                   

                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                            {winner && winner?.length > 2 &&
                                                                                <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                                    <div class="card-body">
                                                        <div className='subd_winnercard_whole'>
                                                            <div className='d-flex justify-content-start align-items-center'>
                                                                {winner[2]?.image == "" || winner[2]?.image == null || winner[2]?.image == undefined ?
                                                                    <>
                                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />

                                                                            :
                                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                                        }
                                                                    </>
                                                                    :
                                                                    <img className='subd_cardsublogoal' src={`${config.Back_URL}/Users/${winner[2]?.PrimaryAddr}/profileImg/${winner[2]?.image}`} />
                                                                }


                                                                <div className='ml-3'>
                                                                    <p className='subd_universal'>{winner[2]?.name && winner[2]?.name?.slice(0, 20)}</p>
                                                                    <p className='subd_universal'>{winner[2]?.PrimaryAddr && winner[2]?.PrimaryAddr?.slice(0, 20)}</p>

                                                                </div>
                                                            </div>
                                                        

                                                        </div>

                                                    </div>
                                                </div>
                                            }

                                        </SwiperSlide>}
                                    {winner && winner?.length > 3 &&
                                        <SwiperSlide>
                                            {winner && winner?.length > 3 &&
                                                                                <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                                    <div class="card-body">
                                                        <div className='subd_winnercard_whole'>
                                                            <div className='d-flex justify-content-start align-items-center'>
                                                                {winner[3]?.image == "" || winner[3]?.image == null || winner[3]?.image == undefined ?
                                                                    <>
                                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />

                                                                            :
                                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                                        }
                                                                    </>
                                                                    :
                                                                    <img className='subd_cardsublogoal' src={`${config.Back_URL}/Users/${winner[3]?.PrimaryAddr}/profileImg/${winner[3]?.image}`} />
                                                                }
                                                                <div className='ml-3'>
                                                                    <p className='subd_universal'>{winner[3]?.name?.slice(0, 20)}</p>
                                                                    <p className='subd_universal'>{winner[3]?.PrimaryAddr?.slice(0, 20)}</p>

                                                                </div>

                                                            </div>
                                                    
                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                            {winner && winner?.length > 4 &&
                                                                                <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                                    <div class="card-body">
                                                        <div className='subd_winnercard_whole'>
                                                            <div className='d-flex justify-content-start align-items-center'>
                                                                {winner[4]?.image == "" || winner[4]?.image == null || winner[4]?.image == undefined ?
                                                                    <>
                                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />

                                                                            :
                                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                                        }
                                                                    </>
                                                                    :
                                                                    <img className='subd_cardsublogoal' src={`${config.Back_URL}/Users/${winner[4]?.PrimaryAddr}/profileImg/${winner[4]?.image}`} />
                                                                }


                                                                <div className='ml-3'>
                                                                    <p className='subd_universal'>{winner[4]?.name && winner[4]?.name?.slice(0, 20)}</p>
                                                                    <p className='subd_universal'>{winner[4]?.PrimaryAddr && winner[4]?.PrimaryAddr?.slice(0, 20)}</p>

                                                                </div>
                                                            </div>
                                                     
                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                            {winner && winner?.length > 5 &&
                                                                                <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                                    <div class="card-body">
                                                        <div className='subd_winnercard_whole'>
                                                            <div className='d-flex justify-content-start align-items-center'>
                                                                {winner[5]?.image == "" || winner[5]?.image == null || winner[5]?.image == undefined ?
                                                                    <>
                                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />

                                                                            :
                                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                                        }
                                                                    </>
                                                                    :
                                                                    <img className='subd_cardsublogoal' src={`${config.Back_URL}/Users/${winner[5]?.PrimaryAddr}/profileImg/${winner[5]?.image}`} />
                                                                }


                                                                <div className='ml-3'>
                                                                    <p className='subd_universal'>{winner[5]?.name && winner[5]?.name?.slice(0, 20)}</p>
                                                                    <p className='subd_universal'>{winner[5]?.PrimaryAddr && winner[5]?.PrimaryAddr?.slice(0, 20)}</p>

                                                                </div>
                                                            </div>
                                                        

                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                        </SwiperSlide>
                                    }
                                    {winner && winner?.length > 6 &&
                                        <SwiperSlide>
                                            {winner && winner?.length > 6 &&
                                                                                <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                                    <div class="card-body">
                                                        <div className='subd_winnercard_whole'>
                                                            <div className='d-flex justify-content-start align-items-center'>
                                                                {winner[6]?.image == "" || winner[6]?.image == null || winner[6]?.image == undefined ?
                                                                    <>
                                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />

                                                                            :
                                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                                        }
                                                                    </>
                                                                    :
                                                                    <img className='subd_cardsublogoal' src={`${config.Back_URL}/Users/${winner[6]?.PrimaryAddr}/profileImg/${winner[6]?.image}`} />
                                                                }


                                                                <div className='ml-3'>
                                                                    <p className='subd_universal'>{winner[6]?.name && winner[6]?.name?.slice(0, 20)}</p>
                                                                    <p className='subd_universal'>{winner[6]?.PrimaryAddr && winner[6]?.PrimaryAddr?.slice(0, 20)}</p>

                                                                </div>
                                                            </div>
                                                         

                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                            {winner && winner?.length > 7 &&
                                                                                <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                                    <div class="card-body">
                                                        <div className='subd_winnercard_whole'>
                                                            <div className='d-flex justify-content-start align-items-center'>
                                                                {winner[7]?.image == "" || winner[7]?.image == null || winner[7]?.image == undefined ?
                                                                    <>
                                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />

                                                                            :
                                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                                        }
                                                                    </>
                                                                    :
                                                                    <img className='subd_cardsublogoal' src={`${config.Back_URL}/Users/${winner[7]?.PrimaryAddr}/profileImg/${winner[7]?.image}`} />
                                                                }


                                                                <div className='ml-3'>
                                                                    <p className='subd_universal'>{winner[7]?.name && winner[7]?.name?.slice(0, 20)}</p>
                                                                    <p className='subd_universal'>{winner[7]?.PrimaryAddr && winner[7]?.PrimaryAddr?.slice(0, 20)}</p>


                                                                </div>
                                                            </div>
                                                         

                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                            {winner && winner?.length > 8 &&
                                                                                <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                                    <div class="card-body">
                                                        <div className='subd_winnercard_whole'>
                                                            <div className='d-flex justify-content-start align-items-center'>
                                                                {winner[8]?.image == "" || winner[8]?.image == null || winner[8]?.image == undefined ?
                                                                    <>
                                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />

                                                                            :
                                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                                        }
                                                                    </>
                                                                    :
                                                                    <img className='subd_cardsublogoal' src={`${config.Back_URL}/Users/${winner[8]?.PrimaryAddr}/profileImg/${winner[8]?.image}`} />
                                                                }


                                                                <div className='ml-3'>
                                                                    <p className='subd_universal'>{winner[8]?.name !== "" && winner[8]?.name.slice(0, 20)}</p>
                                                                    <p className='subd_universal'>{winner[8]?.PrimaryAddr && winner[8]?.PrimaryAddr?.slice(0, 20)}</p>

                                                                </div>
                                                            </div>
                                                            {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                                                        </div>

                                                    </div>
                                                </div>
                                            }

                                        </SwiperSlide>
                                    }
                                </>
                                :
                                <>
                                    <SwiperSlide>

                                                                        <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                            <div class="card-body">
                                                <div className='subd_winnercard_whole'>
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                            :
                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                        }

                                                        <div className='ml-3'>
                                                            {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                                <p className='subd_universal'>Tea Factory</p>
                                                                :
                                                                <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                            }

                                                        </div>
                                                    </div>
                                         
                                                </div>

                                            </div>
                                        </div>
                                                                        <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                            <div class="card-body">
                                                <div className='subd_winnercard_whole'>
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                            :
                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                        }

                                                        <div className='ml-3'>
                                                            {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                                <p className='subd_universal'>Tea Factory</p>
                                                                :
                                                                <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                            }

                                                        </div>
                                                    </div>
                                             
                                                </div>

                                            </div>
                                        </div>
                                                                        <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                            <div class="card-body">
                                                <div className='subd_winnercard_whole'>
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                            :
                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                        }

                                                        <div className='ml-3'>
                                                            {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                                <p className='subd_universal'>Tea Factory</p>
                                                                :
                                                                <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                            }

                                                        </div>
                                                    </div>
                                              
                                                </div>

                                            </div>
                                        </div>

                                    </SwiperSlide>

                                    <SwiperSlide>
                                                                        <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                            <div class="card-body">
                                                <div className='subd_winnercard_whole'>
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                            :
                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                        }

                                                        <div className='ml-3'>
                                                            {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                                <p className='subd_universal'>Tea Factory</p>
                                                                :
                                                                <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                            }

                                                        </div>
                                                    </div>
                                              
                                                </div>

                                            </div>
                                        </div>
                                                                        <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                            <div class="card-body">
                                                <div className='subd_winnercard_whole'>
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                            :
                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                        }

                                                        <div className='ml-3'>
                                                            {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                                <p className='subd_universal'>Tea Factory</p>
                                                                :
                                                                <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                            }

                                                        </div>
                                                    </div>
                                        
                                                </div>

                                            </div>
                                        </div>
                                                                        <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                            <div class="card-body">
                                                <div className='subd_winnercard_whole'>
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                            :
                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                        }

                                                        <div className='ml-3'>
                                                            {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                                <p className='subd_universal'>Tea Factory</p>
                                                                :
                                                                <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                            }

                                                        </div>
                                                    </div>
                                             
                                                </div>

                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                                                        <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                            <div class="card-body">
                                                <div className='subd_winnercard_whole'>
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                            :
                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                        }

                                                        <div className='ml-3'>
                                                            {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                                <p className='subd_universal'>Tea Factory</p>
                                                                :
                                                                <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                            }

                                                        </div>
                                                    </div>
                                         
                                                </div>

                                            </div>
                                        </div>
                                                                        <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                            <div class="card-body">
                                                <div className='subd_winnercard_whole'>
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                            :
                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                        }

                                                        <div className='ml-3'>
                                                            {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                                <p className='subd_universal'>Tea Factory</p>
                                                                :
                                                                <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    <div class="card subd_winnercard" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                                            <div class="card-body">
                                                <div className='subd_winnercard_whole'>
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                        {Sateeee?.companylogo == "" || Sateeee?.companylogo == null || Sateeee?.companylogo == undefined ?
                                                            <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} />
                                                            :
                                                            <img className='subd_cardsublogoal' src={`${config.Back_URL}/${Sateeee?.companylogo}`} />
                                                        }

                                                        <div className='ml-3'>
                                                            {Sateeee?.campaignname == "" || Sateeee?.campaignname == null || Sateeee?.campaignname == undefined ?
                                                                <p className='subd_universal'>Tea Factory</p>
                                                                :
                                                                <p className='subd_universal'>{Sateeee?.campaignname}</p>
                                                            }

                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>

                                    </SwiperSlide>
                                </>
                            }
                        </Swiper>
                    </div>

                </div>

                <div>
                    <SubDomainFooter
                        Item={Sateeee}
                    />
                </div>


            </div>
        </>

    )
}


